import {
  Command,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, Tooltip } from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useAudiences } from "core/hooks/useAudiences";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IAudience } from "core/types/Audience";

export const AddAudienceMenu: React.FC<{
  isDisabled: boolean;
  onAddAudience: (audience: IAudience) => void;
}> = ({ isDisabled, onAddAudience }) => {
  const currentApp = useCurrentApp();
  const { audiences } = useAudiences({ appId: currentApp.id });
  const savedAudiences = audiences?.audiences || [];
  return (
    <Popover className="relative">
      <Tooltip
        placement="top"
        label={
          isDisabled
            ? "You cannot add an audience when you have custom filters"
            : "Add saved audience"
        }
        hasArrow
        shouldWrapChildren
      >
        <PopoverButton data-testid="audience-add-filter-button">
          <Button
            isDisabled={isDisabled}
            colorScheme="purple"
            variant="ghost"
            leftIcon={<PlusIcon className="h-4" />}
          >
            Add audience
          </Button>
        </PopoverButton>
      </Tooltip>
      <PopoverPanel>
        {({ close }) => (
          <>
            <Command className="w-full min-w-[200px]">
              <CommandList>
                {savedAudiences.map((audience) => (
                  <CommandOption
                    value={audience.name || ""}
                    className="w-full"
                    onClick={() => {
                      onAddAudience(audience);
                      close();
                    }}
                  >
                    <p>{audience.name}</p>
                  </CommandOption>
                ))}
              </CommandList>
            </Command>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};
