import { ComparisonType } from "core/types/Report";
import { Command, CommandList } from "core/design-system/components/Command";
import {
  COMPARISON_TO_NAME,
  TRAIT_COMPARISON_TYPES,
  TraitComparisonType,
} from "core/constants/traitFilterComponents";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  Combobox,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";

export const Comparison: React.FC<{
  comparisonType: ComparisonType;
  onSelectComparisonType: (comparisonType: ComparisonType) => void;
}> = ({ comparisonType, onSelectComparisonType }) => {
  return (
    <Popover className="relative">
      <PopoverButton
        data-testid="audience-filter-trait-comparison-editor"
        className="flex w-full min-w-full items-center truncate rounded-md px-1 py-0.5 text-sm text-black hover:bg-gray-200"
      >
        <p data-testid={`audience-trait-selected-comparison`}>
          {COMPARISON_TO_NAME[comparisonType]}
        </p>
      </PopoverButton>
      <PopoverPanel className="flex w-full flex-col">
        {({ close }) => (
          <div data-testid={`audience-trait-comparison-list`}>
            <Command className="min-h-[380px] w-full min-w-[150px] max-w-[170px]">
              <CommandList>
                <div className="w-full text-left">
                  {TRAIT_COMPARISON_TYPES.map((c: TraitComparisonType) => (
                    <Combobox.Option
                      className="w-full"
                      value={c.value}
                      onClick={() => {
                        onSelectComparisonType(c.value);
                        close();
                      }}
                    >
                      <div
                        data-testid={`audience-trait-comparison-${c.value}`}
                        className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-2 py-1.5 text-gray-900 hover:bg-gray-100"
                      >
                        <p className="text-xs font-medium" key={c.name}>
                          {c.name}
                        </p>
                        {c.value === comparisonType && (
                          <CheckIcon className="h-4" />
                        )}
                      </div>
                    </Combobox.Option>
                  ))}
                </div>
              </CommandList>
            </Command>
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
};
