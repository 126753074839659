import React, { useState } from "react";
import { cx } from "helpers/cx";
import { ITraitTimerange } from "core/types/Trait";
import { Plan } from "core/types/App";
import { NumberInputFilter } from "core/modules/reports/setup/Audience/NumberInputFilter";
import { INTERVAL_OPTIONS } from "core/modules/reports/setup/Audience/EventFilter";
import { DAY, INTERVAL_TYPE_NAMES } from "core/constants/timerange";
import {
  TIMERANGE_TYPE_LABELS,
  TimerangeType,
} from "core/constants/report-setup";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Radio, RadioGroup } from "@headlessui/react";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { PaywallPopover } from "../Paywall/Popover";

export const TimerangePicker: React.FC<{
  onChange: (value: ITraitTimerange) => void;
  timerangeType: TimerangeType;
  setTimerangeType: (type: TimerangeType) => void;
  value: ITraitTimerange;
  isAllTimeDisabled?: boolean;
  isRelativeFirstDisabled?: boolean;
  isPaywalled?: boolean;
}> = ({
  onChange,
  timerangeType,
  setTimerangeType,
  value,
  isAllTimeDisabled = false,
  isRelativeFirstDisabled = false,
  isPaywalled = false,
}) => {
  const [interval, setInterval] = useState<number>(value.interval);
  const [intervalType, setIntervalType] = useState<number>(value.intervalType);

  const onAllTimerangeTypeChange = () => {
    setTimerangeType(TimerangeType.ALL_TIME);
    setInterval(0);
    setIntervalType(DAY);
  };
  const onRelativeTimerangeTypeChange = () => {
    setTimerangeType(TimerangeType.RELATIVE);
    setInterval(7);
    setIntervalType(DAY);
  };
  const onRelativeFirstTimerangeTypeChange = () => {
    setTimerangeType(TimerangeType.RELATIVE_FIRST);
    setInterval(7);
    setIntervalType(DAY);
  };
  const onIntervalChange = (val: string) => {
    setInterval(Number(val));
  };
  const onIntervalTypeChange = (val: number) => {
    setIntervalType(val);
  };

  const TIMERANGE_OPTIONS = [
    {
      type: TimerangeType.ALL_TIME,
      label: TIMERANGE_TYPE_LABELS[TimerangeType.ALL_TIME],
      onChange: onAllTimerangeTypeChange,
    },
    {
      type: TimerangeType.RELATIVE,
      label: TIMERANGE_TYPE_LABELS[TimerangeType.RELATIVE],
      onChange: onRelativeTimerangeTypeChange,
    },
    {
      type: TimerangeType.RELATIVE_FIRST,
      label: TIMERANGE_TYPE_LABELS[TimerangeType.RELATIVE_FIRST],
      onChange: onRelativeFirstTimerangeTypeChange,
    },
  ];

  return (
    <Menu
      matchWidth
      closeOnSelect={false}
      onClose={() => {
        onChange({
          type: timerangeType,
          interval: interval,
          intervalType: intervalType,
        });
      }}
    >
      {({ onClose }) => (
        <>
          <PaywallPopover
            feature="computed traits"
            redirect="settings/computed-traits"
            plan={Plan.Pro}
            overridePaywall={!isPaywalled}
          >
            <MenuButton
              data-testid="audience-event-filter-matcher"
              fontSize="sm"
              fontWeight="medium"
              borderRadius={"md"}
              border="none"
              bg="gray.50"
              _hover={{ bg: "gray.100" }}
              as={Button}
            >
              {TIMERANGE_TYPE_LABELS[timerangeType]}{" "}
              {[TimerangeType.RELATIVE, TimerangeType.RELATIVE_FIRST].includes(
                timerangeType,
              ) && (
                <>
                  {interval} {INTERVAL_TYPE_NAMES[intervalType]}
                  {interval > 1 ? "s" : ""}
                </>
              )}
            </MenuButton>
          </PaywallPopover>
          <MenuList minW="180px" pb={0}>
            <RadioGroup value={String(timerangeType)}>
              <div className="flex flex-col gap-2 px-4 py-2">
                {TIMERANGE_OPTIONS.map((option) => (
                  <>
                    <Radio
                      key={option.type}
                      className={cx(
                        (option.type === TimerangeType.ALL_TIME &&
                          isAllTimeDisabled) ||
                          (option.type === TimerangeType.RELATIVE_FIRST &&
                            isRelativeFirstDisabled)
                          ? "cursor-default opacity-50"
                          : "cursor-pointer",
                      )}
                      value={String(option.type)}
                      disabled={
                        (option.type === TimerangeType.ALL_TIME &&
                          isAllTimeDisabled) ||
                        (option.type === TimerangeType.RELATIVE_FIRST &&
                          isRelativeFirstDisabled)
                      }
                      onClick={() => {
                        if (
                          (option.type === TimerangeType.ALL_TIME &&
                            isAllTimeDisabled) ||
                          (option.type === TimerangeType.RELATIVE_FIRST &&
                            isRelativeFirstDisabled)
                        )
                          return;
                        option.onChange();
                      }}
                    >
                      {({ checked }) => (
                        <div className={cx("flex items-center")}>
                          <div
                            className={cx(
                              "relative mr-2 h-4 w-4 rounded-full border",
                              checked
                                ? "border-purple-500 bg-purple-500"
                                : "border-gray-300",
                              (option.type === TimerangeType.ALL_TIME &&
                                isAllTimeDisabled) ||
                                (option.type === TimerangeType.RELATIVE_FIRST &&
                                  isRelativeFirstDisabled)
                                ? "opacity-50"
                                : "",
                            )}
                          >
                            {checked && (
                              <div
                                className={cx(
                                  "absolute left-1/2 top-1/2 h-1.5 w-1.5 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white",
                                )}
                              />
                            )}
                          </div>
                          <span
                            className={cx(
                              "text-sm",
                              (option.type === TimerangeType.ALL_TIME &&
                                isAllTimeDisabled) ||
                                (option.type === TimerangeType.RELATIVE_FIRST &&
                                  isRelativeFirstDisabled)
                                ? "text-gray-500"
                                : "",
                            )}
                          >
                            {option.label}
                          </span>
                        </div>
                      )}
                    </Radio>
                    {[
                      TimerangeType.RELATIVE,
                      TimerangeType.RELATIVE_FIRST,
                    ].includes(timerangeType) &&
                      timerangeType === option.type && (
                        <>
                          <NumberInputFilter
                            hasInterval={[
                              TimerangeType.RELATIVE,
                              TimerangeType.RELATIVE_FIRST,
                            ].includes(timerangeType)}
                            onChange={onIntervalChange}
                            defaultValue={interval}
                            bg="white"
                            border="0.5px solid"
                            borderColor="gray.200"
                            borderRadius="md"
                            size="sm"
                            w="full"
                          />
                          <Menu closeOnSelect closeOnBlur matchWidth>
                            <MenuButton
                              borderRadius="md"
                              fontSize="sm"
                              as={Button}
                              _hover={{ bg: "white" }}
                              fontWeight="normal"
                              data-testid="user-event-filter-relative-interval-button"
                            >
                              <Flex
                                align="center"
                                gridGap={2}
                                justifyContent="space-between"
                              >
                                <Flex>
                                  {INTERVAL_TYPE_NAMES[intervalType]}
                                  {interval > 1 ? "s" : ""}
                                </Flex>
                                <Flex>
                                  <ChevronDownIcon style={{ height: "15px" }} />
                                </Flex>
                              </Flex>
                            </MenuButton>
                            <MenuList minW="100px">
                              <MenuOptionGroup
                                defaultValue={String(intervalType)}
                                type="radio"
                              >
                                {INTERVAL_OPTIONS.map((i) => (
                                  <MenuItem
                                    key={i}
                                    data-testid={`user-event-filter-interval-item-${i}`}
                                    fontSize="sm"
                                    onClick={() => onIntervalTypeChange(i)}
                                    value={i}
                                  >
                                    {INTERVAL_TYPE_NAMES[i]}
                                    {interval > 1 ? "s" : ""}
                                  </MenuItem>
                                ))}
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>
                        </>
                      )}
                  </>
                ))}
              </div>
            </RadioGroup>
            <Button
              onClick={() => {
                onChange({ type: timerangeType, interval, intervalType });
                onClose();
              }}
              borderRadius="md"
              colorScheme="purple"
              variant="ghost"
              w="full"
              borderTopRadius="none"
              borderTop="1px solid"
              borderColor="gray.200"
              pt={0.5}
            >
              Done
            </Button>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
