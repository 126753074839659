import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { ChooseInsightsContainer } from "modules/ViewInsight/Modal/ChooseInsightsContainer";
import { AddInsightBuilder } from "modules/ViewInsight/Modal/AddInsightBuilder";
import { IView } from "core/models/views";
import { IViewInsight } from "core/models/viewInsights";
import { ViewLocation } from "core/hooks/useViews";
import { useViewInsights } from "core/hooks/useViewInsights";
import { usePaywall } from "core/hooks/usePaywall";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
import { Center, Flex, Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import { ViewInsight } from "./ViewInsight";
import { SQLEditorContainer } from "./Modal/SQLEditorContainer";
import { InsightBuilderContainer } from "./Modal/InsightBuilderContainer";
import { AddSQLContainer } from "./Modal/AddSQLContainer";
import { useIsEditingView } from "core/contexts/EditingViewContext";
import { GRID_TO_PX } from "./Builder/LayoutContainer";
import { useReorderViewInsights } from "core/hooks/useReorderViewInsights";

export const EmptyState = ({
  viewInsights,
  setShowAddModal,
  width,
}: {
  viewInsights?: IViewInsight[];
  setShowAddModal: (show: boolean) => void;
  width: string;
}) => {
  const isEditing = useIsEditingView();
  const { toggleEditDashboard } = useEditDashboard();
  return (
    <>
      {(isEditing || viewInsights?.length === 0) && (
        <GridItem w={width} h="315px">
          <Flex
            w="full"
            h="full"
            border="2px dashed"
            borderRadius="lg"
            borderColor="purple.300"
            transition="all .1s ease-in-out"
            cursor="pointer"
            _hover={{
              borderColor: "purple.500",
              shadow: "sm",
              bg: "purple.50",
            }}
            onClick={() => {
              if (viewInsights?.length === 0) toggleEditDashboard();
              setShowAddModal(true);
            }}
          >
            <Center w="full">
              <div className="flex flex-col items-center gap-2">
                <SquaresPlusIcon className="h-5 w-5 text-purple-500" />
                <Text
                  textAlign="center"
                  px={20}
                  fontSize="sm"
                  fontWeight="medium"
                  color="purple.500"
                >
                  {viewInsights?.length === 0
                    ? "Add your first insight"
                    : "Add insight"}
                </Text>
              </div>
            </Center>
          </Flex>
        </GridItem>
      )}
    </>
  );
};

const SortableInsight: React.FC<{
  viewInsight: IViewInsight;
  view: IView;
  location: ViewLocation;
  shouldBePaywalled?: boolean;
  paywallInsights?: boolean;
  onRefetch: () => Promise<unknown>;
}> = ({
  viewInsight,
  view,
  location,
  shouldBePaywalled,
  paywallInsights,
  onRefetch,
}) => {
  const isEditing = useIsEditingView();
  const { openedInsight, clearOpenedViewInsight } = useEditDashboard();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: viewInsight.id,
    disabled: !isEditing,
  });

  const displayWidth = viewInsight.gridWidth;

  const style = {
    transform: transform
      ? CSS.Transform.toString({
          x: transform.x,
          y: transform.y,
          scaleX: 1,
          scaleY: 1,
        } as Transform)
      : "",
    transition,
    width: GRID_TO_PX[displayWidth],
    height: "100%",
    gridColumn: `span ${displayWidth}`,
    maxWidth: GRID_TO_PX[displayWidth],
    zIndex: isDragging ? 999 : "auto",
  };

  return (
    <GridItem
      ref={setNodeRef}
      style={style}
      w="full"
      h="315px"
      colSpan={displayWidth}
      rowSpan={viewInsight.gridHeight}
      {...attributes}
      {...listeners}
    >
      <ViewInsight
        view={view}
        location={location}
        viewInsight={viewInsight}
        isBlurred={
          shouldBePaywalled && paywallInsights && !viewInsight.isAutoGenerated
        }
        query={viewInsight.query}
        isAI={Boolean(viewInsight.queryId)}
      />
      <React.Suspense fallback={<div />}>
        {viewInsight.query ? (
          <SQLEditorContainer
            isOpen={openedInsight?.viewInsight?.id === viewInsight.id}
            onClose={clearOpenedViewInsight}
            viewInsight={viewInsight}
            onRefetch={onRefetch}
          />
        ) : (
          openedInsight?.viewInsight?.id === viewInsight.id && (
            <InsightBuilderContainer
              isOpen={openedInsight.viewInsight?.id === viewInsight.id}
              onClose={clearOpenedViewInsight}
              viewId={viewInsight.viewId}
              viewInsight={viewInsight}
              onRefetch={onRefetch}
            />
          )
        )}
      </React.Suspense>
    </GridItem>
  );
};

export const ViewContainer: React.FC<{
  location: ViewLocation;
  view: IView;
  viewId: number;
  columns?: number;
  width?: string;
  paywallInsights?: boolean;
  showAddModal: boolean;
  setShowAddModal: (show: boolean) => void;
  showSQLModal: boolean;
  setShowSQLModal: (show: boolean) => void;
}> = ({
  location,
  view,
  viewId,
  columns = 3,
  width = "315px",
  paywallInsights,
  showAddModal,
  setShowAddModal,
  showSQLModal,
  setShowSQLModal,
}) => {
  const { viewInsights, isLoadingViewInsights, onRefetch } = useViewInsights({
    viewId,
  });
  const { reorderableInsights, handleDragEnd, sensors } =
    useReorderViewInsights({ viewInsights });

  const { shouldBePaywalled } = usePaywall();
  const [
    showChooseExistingReportInsights,
    setShowChooseExistingReportInsights,
  ] = useState(false);

  useEffect(() => {
    if (showAddModal) {
      setShowChooseExistingReportInsights(false);
    }
  }, [showAddModal]);

  if (isLoadingViewInsights) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
        <Spinner emptyColor="gray.200" color="gray.500" thickness="3px" />
      </div>
    );
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Grid
          templateColumns={`repeat(${columns}, 1fr)`}
          autoRows="315px"
          gridGap={5}
          position="relative"
        >
          <SortableContext
            items={reorderableInsights.map((i) => i.id)}
            strategy={rectSortingStrategy}
          >
            {reorderableInsights.map((viewInsight) => (
              <SortableInsight
                key={viewInsight.id}
                viewInsight={viewInsight}
                view={view}
                location={location}
                shouldBePaywalled={shouldBePaywalled}
                paywallInsights={paywallInsights}
                onRefetch={onRefetch}
              />
            ))}
          </SortableContext>
          <EmptyState
            viewInsights={viewInsights}
            setShowAddModal={setShowAddModal}
            width={width}
          />
        </Grid>
      </DndContext>

      <React.Suspense fallback={<div />}>
        {!showChooseExistingReportInsights
          ? showAddModal && (
              <AddInsightBuilder
                isOpen={showAddModal}
                onClose={async () => {
                  await onRefetch();
                  setShowAddModal(false);
                }}
                setShowChooseExistingReportInsights={() =>
                  setShowChooseExistingReportInsights(true)
                }
                viewId={viewId}
              />
            )
          : showAddModal && (
              <ChooseInsightsContainer
                location={location}
                isOpen={showAddModal}
                onClose={() => {
                  setShowAddModal(false);
                  onRefetch();
                }}
                viewInsights={viewInsights as IViewInsight[]}
                viewId={view.id}
              />
            )}
        {showSQLModal && (
          <AddSQLContainer
            isOpen={showSQLModal}
            onClose={() => {
              setShowSQLModal(false);
              onRefetch();
            }}
            viewId={viewId}
          />
        )}
      </React.Suspense>
    </>
  );
};
