import React, { useState } from "react";
import { AddFilterType } from "helpers/filters";
import { TraitOptionsList } from "core/modules/audience/Filters/TraitOptionsList";
import { ITraitKeyResponse } from "core/models/traits";
import { useTraits } from "core/hooks/useTraits";
import { useReportSetup } from "core/hooks/useReportSetup";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
} from "core/design-system/components/Command";
import {
  COMPANY_TRAIT_FILTER,
  FilterType,
  GROUP_TRAIT_FILTER,
  TRACK_EVENT_FILTER,
  USER_TRAIT_FILTER,
} from "core/constants/report-setup";
import { DIVIDER } from "core/constants/filters";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button, Text, Tooltip } from "@chakra-ui/react";

interface IAddAudienceFilterButtonProps {
  isCurrentlyAddingFilter: boolean;
  onAddTraitFilter: (
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    filterType: typeof USER_TRAIT_FILTER | typeof GROUP_TRAIT_FILTER,
    trait: string,
    filterIndex: number,
  ) => void;
  onAddEventFilter: (
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    filterType: typeof TRACK_EVENT_FILTER,
  ) => void;
  isDisabled: boolean;
  isEventFilterDisabled?: boolean;
  trait?: string;
  traits?: ITraitKeyResponse[];
  searchTrait?: string;
  isLoading?: boolean;
  addFilterType: AddFilterType;
  filterGroupIndex?: number;
  filterIndex?: number;
  objectName?: string;
  objectSlug?: string;
  filterTypeNames?: { [x: number]: string | undefined };
  disabledTooltip?: string;
}

const AddAudienceFilterButton: React.FC<IAddAudienceFilterButtonProps> = ({
  isCurrentlyAddingFilter,
  onAddEventFilter,
  onAddTraitFilter,
  isDisabled = false,
  isEventFilterDisabled = false,
  addFilterType,
  filterGroupIndex = 0,
  filterIndex = 0,
  disabledTooltip,
}) => {
  const { currentReport } = useReportSetup();
  const [searchTrait, setSearchTrait] = useState("");

  const { userAppObject, groupAppObject, companyAppObject } = useAppObjects();
  const { userTraits, groupTraits, companyTraits, isUserTraitsLoading } =
    useTraits();

  const filterTypeNames: { [x: number]: string | undefined } = {
    [FilterType.UserTrait]: userAppObject?.singularName || "User",
    [FilterType.GroupFilter]: groupAppObject?.singularName || "Group",
    [FilterType.CompanyFilter]: companyAppObject?.singularName || "Company",
  };

  const userTraitDivider = { trait: "User traits", filterType: DIVIDER };
  const companyTraitDivider = { trait: "Company traits", filterType: DIVIDER };
  const groupTraitDivider = {
    trait: `${filterTypeNames[FilterType.GroupFilter] || "Company"} traits`,
    filterType: DIVIDER,
  };

  const handleOnAddTraitFilter = (filterType: FilterType, trait: string) => {
    if (filterType === FilterType.UserTrait) {
      onAddTraitFilter(
        addFilterType,
        filterGroupIndex,
        USER_TRAIT_FILTER,
        trait,
        filterIndex,
      );
    } else if (filterType === FilterType.GroupFilter) {
      onAddTraitFilter(
        addFilterType,
        filterGroupIndex,
        GROUP_TRAIT_FILTER,
        trait,
        filterIndex,
      );
    } else {
      onAddTraitFilter(
        addFilterType,
        filterGroupIndex,
        COMPANY_TRAIT_FILTER,
        trait,
        filterIndex,
      );
    }
  };

  const handleOnAddEventFilter = () => {
    onAddEventFilter(addFilterType, filterGroupIndex, TRACK_EVENT_FILTER);
  };

  const traits = [
    userTraitDivider,
    ...userTraits,
    ...(companyAppObject?.isEnabled
      ? [companyTraitDivider, ...companyTraits]
      : []),
    ...(groupAppObject?.isEnabled ? [groupTraitDivider, ...groupTraits] : []),
  ];

  return (
    <Popover className={"relative"}>
      <Tooltip
        placement="top"
        label={
          disabledTooltip
            ? disabledTooltip
            : isDisabled
              ? "You can only use a Saved Audience or custom filters, not both"
              : addFilterType !== AddFilterType.FilterGroup &&
                "Add filter to this group"
        }
        hasArrow
        shouldWrapChildren
      >
        <PopoverButton>
          <Button
            isDisabled={
              isDisabled ||
              isCurrentlyAddingFilter ||
              currentReport?.audience?.isSavedAudience
            }
            fontSize="sm"
            variant="ghost"
            bg={addFilterType === AddFilterType.FilterGroup ? "" : "gray.50"}
            colorScheme={
              addFilterType === AddFilterType.FilterGroup ? "purple" : "gray"
            }
            borderLeftRadius={
              addFilterType === AddFilterType.FilterGroup ? "lg" : 0
            }
            borderRightRadius="lg"
            _hover={
              addFilterType === AddFilterType.FilterGroup
                ? { bg: "purple.50", color: "purple.500" }
                : { bg: "gray.100" }
            }
          >
            <div className="flex items-center">
              {addFilterType === AddFilterType.FilterGroup ? (
                <div className="flex items-center">
                  <PlusIcon className="h-4" />
                  <Text ml={2}>Add filter</Text>
                </div>
              ) : (
                <PlusIcon className="h-4 text-gray-500  hover:text-black" />
              )}
            </div>
          </Button>
        </PopoverButton>
      </Tooltip>
      <PopoverPanel>
        <>
          <Lifecycle onUnmount={() => setSearchTrait("")} />
          <Command>
            <CommandInput
              placeholder="Search filter..."
              value={searchTrait}
              onChange={(e) => {
                setSearchTrait(e.target.value);
              }}
            />
            <CommandList data-testid={`audience-trait-options`}>
              <TraitOptionsList
                traits={traits}
                searchTrait={searchTrait}
                onAddTraitFilter={handleOnAddTraitFilter}
                onAddEventFilter={handleOnAddEventFilter}
                isLoading={isUserTraitsLoading}
                filterTypeNames={filterTypeNames}
                isEventFilterDisabled={isEventFilterDisabled}
              />
            </CommandList>
          </Command>
        </>
      </PopoverPanel>
    </Popover>
  );
};

export default AddAudienceFilterButton;
