import React from "react";
import moment from "moment";
import {
  Bars3BottomLeftIcon,
  BoltIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";

interface ITraitProps {
  name: string;
  lastComputedAt?: string;
  description?: string;
  isLive?: boolean;
}

export const ComputedTraitTooltip: React.FC<ITraitProps> = ({
  name,
  lastComputedAt,
  description,
  isLive = false,
}) => {
  return (
    <div className="flex flex-col gap-2 py-2">
      <div className="flex items-center gap-1">
        <BoltIcon className="h-4 w-4 text-gray-600" />
        <p className="text-sm">{name}</p>
        {isLive ? (
          <p className="rounded-md bg-green-100 px-1 text-xs font-semibold text-green-700">
            Live
          </p>
        ) : (
          <p className="rounded-md bg-gray-100 px-1 text-xs font-semibold text-gray-700">
            Paused
          </p>
        )}
      </div>
      <div className="flex gap-1">
        <Bars3BottomLeftIcon className="h-4 min-w-4 text-gray-600" />
        {description && <p className="text-xs">{description}</p>}
      </div>
      {lastComputedAt && (
        <div className="flex items-center gap-1">
          <ClockIcon className="h-4 w-4 text-gray-600" />
          <p className="text-xs">
            Last computed {moment.utc(lastComputedAt).fromNow()}
          </p>
        </div>
      )}
    </div>
  );
};
