import { useParams } from "react-router-dom";
import {
  IViewInsight,
  useGetViewInsightsQuery,
} from "core/models/viewInsights";
import { useQueryParam } from "./useQueryParam";

export enum ViewLocation {
  Home = "home",
  Company = "company_profile",
}

export const useViewInsights = ({
  viewId,
  sharingSecretToken,
}: {
  viewId: number;
  sharingSecretToken?: string | null;
}): {
  viewInsights: IViewInsight[];
  isLoadingViewInsights: boolean;
  onRefetch: () => Promise<unknown>;
} => {
  const { appId } = useParams();
  const groupId = useQueryParam("groupId");
  const {
    data,
    isLoading: isLoadingViewInsights,
    isFetching: isFetchingViewInsights,
    refetch,
  } = useGetViewInsightsQuery({
    appId: Number(appId),
    viewId,
    sharingSecretToken,
    groupId,
  });

  async function onRefetch() {
    return new Promise((resolve) => refetch().then(resolve));
  }

  return {
    viewInsights: data || [],
    isLoadingViewInsights: isLoadingViewInsights || isFetchingViewInsights,
    onRefetch,
  };
};
