import { cx } from "helpers/cx";
import { motion } from "framer-motion";
import { useNavigation } from "core/hooks/useNavigation";
import { ComponentDefaultProps, Container } from "@chakra-ui/react";

export const ReportContainer: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  const { navigation } = useNavigation();
  const navigationIsOpen = navigation.isOpen;

  return (
    <div className={cx("h-full w-full bg-gray-50")}>
      <div className="h-[99.9vh] p-2 pr-2">
        <motion.div
          initial={{ marginLeft: navigationIsOpen ? "290px" : "40px" }}
          animate={{ marginLeft: navigationIsOpen ? "290px" : "40px" }}
          transition={{ duration: 0.2 }}
          style={{ paddingLeft: "60px", paddingRight: "60px" }}
          className="max-h-full min-h-full overflow-auto rounded-lg bg-white py-8 shadow "
        >
          <Container maxW="container.lg" className="overflow-auto">
            {children}
          </Container>
        </motion.div>
      </div>
    </div>
  );
};
