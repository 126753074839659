import { isNumber } from "lodash";
import {
  Box,
  Button,
  Center,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import SingleContact from "core/components/Contact/SingleContact";
import SingleGroup from "../../Group/SingleGroup";
import { MoonIcon } from "@chakra-ui/icons";

interface IDataTable {
  data: any[];
  columns: any[];
  page?: number;
  setPage?: (page: number) => void;
  hasMore?: boolean;
}

export const DataTable: React.FC<IDataTable> = ({
  data,
  columns,
  page,
  setPage,
  hasMore,
}) => {
  if (!data || !columns) return <></>;

  const columnNames = columns.map((column) => column.name);
  const isCount =
    (columns.length === 1 && columns[0].name.includes(`count`)) ||
    columns[0].name.toLowerCase().includes(`uniqexact`);

  if (isCount)
    return (
      <Text color="black" px={6} py={4} fontSize="4xl" fontWeight="normal">
        {data[0][columns[0].name]}
      </Text>
    );

  if (data.length === 0)
    return (
      <Center h="400px" minW="full">
        <Box textAlign="center">
          <Box
            borderRadius="lg"
            px={4}
            py={3}
            display="inline-block"
            boxShadow="0px 1px 8px #6868F7"
            mb={4}
          >
            <MoonIcon color="purple.500" />
          </Box>
          <Text fontSize="normal" fontWeight="semibold">
            No data found
          </Text>
          <Text fontSize="sm" color="gray.600">
            We found no data for your prompt
          </Text>
        </Box>
      </Center>
    );

  const listData = data;

  return (
    <TableContainer borderRadius="lg" bg="white" maxW="full" overflowX="scroll">
      <Table>
        <Thead>
          {columns.map((column) => (
            <Th>{column.name}</Th>
          ))}
        </Thead>

        <Tbody w="full">
          {listData.map((row) => {
            return (
              <Tr>
                {Object.keys(row)
                  .sort((a, b) =>
                    columnNames.indexOf(a) > columnNames.indexOf(b) ? 1 : -1,
                  )
                  .map((column) => {
                    const value = row[column];
                    const isObject =
                      typeof value === "object" || Array.isArray(value);

                    if (column === "user_id") {
                      return (
                        <Td>
                          <SingleContact
                            id={row[column]}
                            p={0}
                            _hover={{ borderColor: "transparent" }}
                            hideExternalLinkIcon
                          />
                        </Td>
                      );
                    }
                    if (column === "group_id") {
                      return (
                        <Td>
                          <SingleGroup
                            id={row[column]}
                            p={0}
                            _hover={{ borderColor: "transparent" }}
                            hideExternalLinkIcon
                          />
                        </Td>
                      );
                    }
                    if (
                      column.includes(`timestamp`) ||
                      column.includes(`date`)
                    ) {
                      return (
                        <Td>
                          <Text fontSize="sm" color="gray.600">
                            {row[column]}
                          </Text>
                        </Td>
                      );
                    }
                    if (isNumber(row[column])) {
                      return (
                        <Td>
                          <Text color="black" fontSize="sm">
                            {Number(row[column]).toLocaleString()}
                          </Text>
                        </Td>
                      );
                    }
                    return (
                      <Td>
                        <Text color="black" fontSize="sm">
                          {isObject ? JSON.stringify(row[column]) : row[column]}
                        </Text>
                      </Td>
                    );
                  })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {hasMore && (
        <Center my={2}>
          <Button
            colorScheme="purple"
            variant="ghost"
            onClick={() => setPage && setPage((page || 1) + 1)}
          >
            Load more
          </Button>
        </Center>
      )}
    </TableContainer>
  );
};
