import { IQuery } from "core/types/Query";
import { Result } from "./AI/Graph/Result";
import { IViewInsight } from "../models/viewInsights";

export const ReportInsight: React.FC<{
  query: IQuery;
  viewInsight?: IViewInsight;
}> = ({ query, viewInsight }) => {
  return <Result query={query} isEditing={false} viewInsight={viewInsight} />;
};
