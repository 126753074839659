import { useParams } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { IViewInsightContainer } from "modules/ViewInsight/ViewInsight";
import { ViewInsightCard } from "modules/ViewInsight/CardContainer";
import { Interval } from "core/types/ViewInsight";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import {
  useAddViewInsightMutation,
  useDeleteViewInsightMutation,
} from "core/models/viewInsights";
import { AppObjectType } from "core/models/appObjects";
import { useToast } from "core/hooks/useToast";
import { usePaywall } from "core/hooks/usePaywall";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import useFlag from "core/hooks/useFlag";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { FUNNEL, REPORT_CONFIGS } from "core/constants/report-configurations";
import { NEW_ONBOARDING } from "core/constants/features";
import { ViewInsightComponent } from "./ViewInsightComponent";
import { GettingStartedOverlay } from "./GettingStartedOverlay";
import { INTERVAL_TYPES } from "./Builder/Preview/Container";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

function findInsightByTypeId(
  reportConfig: ITemplateConfig,
  insightTypeId: number,
) {
  return reportConfig.insights.find(
    (insight: IInsightSection) => insight.typeId === insightTypeId,
  );
}

export function getInsightConfig(reportType: number, insightTypeId: number) {
  const reportConfig = getReportConfigByType(reportType);
  return reportConfig ? findInsightByTypeId(reportConfig, insightTypeId) : null;
}

export const ViewInsightContainer: React.FC<IViewInsightContainer> = ({
  viewInsight,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  onSetViewInsight,
  sharingSecretToken,
  isSlackInsight,
}) => {
  const toast = useToast();
  const { audience: internalUsersAudience } = useCurrentApp();
  const { shouldBePaywalled } = usePaywall();
  const { appId, viewId: paramViewId, groupId } = useParams();
  const _viewId = viewId ? viewId : paramViewId;
  const [showHighlightDescription, setShowHighlightDescription] =
    useState(false);
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [addViewInsight] = useAddViewInsightMutation();

  function getAudience() {
    if (Boolean(groupId) && viewInsight?.view?.useInternalUsers) {
      return internalUsersAudience;
    }
    return viewInsight.useViewAudience ? viewInsight.view.audience : undefined;
  }

  const { response, isLoading, isFetching } = useInsightDataFetching({
    report: viewInsight.report,
    insightType: viewInsight.insightId,
    insightParams: {
      ...viewInsight.insight?.defaultParams,
      interval_type:
        INTERVAL_TYPES[viewInsight.interval as unknown as Interval],
      size: GRID_WIDTH_TO_SIZE[viewInsight.gridWidth],
      breakdown_enabled:
        viewInsight.insight.reportType === FUNNEL &&
        Boolean(viewInsight.report.config.breakdown)
          ? false
          : undefined,
    },
    sharingSecretToken,
    previewMode: false,
    groupType: groupId ? undefined : viewInsight?.appObject?.slug,
    timerangeType: viewInsight?.timerangeType,
    timerangeValue: viewInsight?.timerangeValue,
    timerangeStartTimestamp: viewInsight?.timerangeStartTimestamp,
    timerangeEndTimestamp: viewInsight?.timerangeEndTimestamp,
    audience: getAudience(),
  });
  const { isSetupComplete } = useOnboardingChecklist();
  const hasNewOnboardingEnabled = useFlag(NEW_ONBOARDING);

  if (viewInsight.insightId === undefined) return null;

  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId,
  );
  const data = insightConfig?.view
    ? insightConfig?.view?.transform(response?.data, viewInsight.interval)
    : [];
  const highlight = insightConfig?.view?.highlight
    ? insightConfig?.view?.highlight(response?.data)
    : {};
  const timerange = insightConfig?.view?.timerange
    ? insightConfig?.view?.timerange(viewInsight)
    : "";

  const isPaywalled =
    (shouldBePaywalled &&
      viewInsight?.appObject?.objectType &&
      [AppObjectType.Company, AppObjectType.Group].includes(
        viewInsight?.appObject?.objectType,
      )) ||
    false;
  const filter =
    isPaywalled || isBlurred
      ? "blur(10px)"
      : !isSetupComplete
        ? "grayscale(0.8)"
        : "none";
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  return (
    <ViewInsightCard
      viewInsight={viewInsight}
      isAdding={isAdding}
      isAdded={isAdded}
      isBlurred={isBlurred}
      isPaywalled={isPaywalled}
      isEmpty={data?.length === 0}
      groupId={groupId}
      highlight={highlight}
      title={viewInsight.title || response?.reportName}
      showHighlightDescription={showHighlightDescription}
      description={viewInsight.insight.name}
      isLoading={isLoading || isFetching}
      timerange={!isAdding && !isBlurred && timerange ? timerange : ""}
      hasTimerangePicker={insightConfig?.view?.hasTimerangePicker}
      dateRangeOptions={insightConfig?.view?.dateRangeOptions}
      reachedLimit={false}
      onMouseEnter={() => setShowHighlightDescription(true)}
      onMouseLeave={() => setShowHighlightDescription(false)}
      isSlackInsight={isSlackInsight}
      onDelete={(e: SyntheticEvent) => {
        e.preventDefault();
        deleteViewInsight({
          id: viewInsight.id,
          appId: viewInsight.appId,
        }).then(() => {
          toast({
            title: "Insight removed",
            description: "The insight has been removed from the view",
            status: "success",
          });
        });
      }}
      onAdd={(e: SyntheticEvent) => {
        e.preventDefault();
        addViewInsight({
          appId: Number(appId),
          viewId: Number(_viewId),
          reportId: Number(viewInsight.reportId),
          insightId: Number(viewInsight.insightId),
          objectType: viewInsight?.appObject?.objectType,
        }).then(() => {
          toast({
            title: "Insight added",
            description: "The insight has been added to the view",
            status: "success",
          });
        });
      }}
      position="relative"
      color={color}
      onSetViewInsight={onSetViewInsight}
    >
      {hasNewOnboardingEnabled
        ? !isSetupComplete &&
          showHighlightDescription && <GettingStartedOverlay />
        : null}
      <ViewInsightComponent
        viewInsight={viewInsight}
        data={data}
        filter={filter}
        opacity={!isSetupComplete ? 0.4 : undefined}
      />
    </ViewInsightCard>
  );
};
