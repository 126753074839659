import { IQuery } from "core/types/Query";
import { ListIcon } from "core/design-system/components/Icon/Interface/ListIcon";
import { BarChartIcon } from "core/design-system/components/Icon/Interface/BarChartIcon";
import { LineChartIcon } from "core/design-system/components/Icon/Interface/LineChartIcon";
import AnimatedToggle from "core/components/AnimatedToggle";
import { IViewInsight } from "core/models/viewInsights";
import { GraphType } from "core/types/ViewInsight";

export const ChartSettings: React.FC<{
  query?: IQuery;
  viewInsight?: IViewInsight;
  onUpdateGraphType: (graphType: string) => void;
}> = ({ query, viewInsight, onUpdateGraphType }) => {
  if (!query) return <></>;
  return (
    <AnimatedToggle
      items={[
        {
          value: viewInsight ? GraphType.Table : "list",
          label: "",
          icon: (
            <ListIcon position="relative" top="6px" left="3px" h={6} w={6} />
          ),
        },
        {
          value: viewInsight ? GraphType.Bar : "bar_chart",
          label: "",
          icon: <BarChartIcon h={5} w={5} />,
        },
        {
          value: viewInsight ? GraphType.Area : "area_chart",
          label: "",
          icon: (
            <LineChartIcon
              position="relative"
              top="4px"
              left="2px"
              h={5}
              w={5}
            />
          ),
        },
      ]}
      value={viewInsight ? viewInsight.graphType : query.graphType}
      onChange={(val, e) => {
        e.preventDefault();
        onUpdateGraphType(val);
      }}
    />
  );
};
