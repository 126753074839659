import { useNavigate } from "react-router-dom";
import React from "react";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import { Flex } from "@chakra-ui/react";

export const UninstalledCard: React.FC = () => {
  const currentApp = useCurrentApp();
  const navigate = useNavigate();

  return (
    <Flex
      w="full"
      direction="column"
      gridGap={2}
      data-testid="hubspot-profile-uninstalled-card"
    >
      <div className="flex flex-row items-center space-x-2">
        <HubspotIcon color="gray.500" w={4} h={4} />
        <p className="text-sm">
          <p
            data-testid="hubspot-profile-uninstalled-install-button"
            onClick={() => navigate(`/a/${currentApp.id}/integrations/hubspot`)}
            className="mr-1 inline-block cursor-pointer font-medium text-purple-500"
          >
            Connect
          </p>
          to sync with HubSpot
        </p>
      </div>
    </Flex>
  );
};
