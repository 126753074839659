import { VIEW_INSIGHT_ALERTS } from "core/constants/features";
import useFlag from "core/hooks/useFlag";
import { MenuItem, Spinner, useDisclosure } from "@chakra-ui/react";
import { IViewInsight } from "core/models/viewInsights";
import { BellIcon as BellIconOutline } from "@heroicons/react/24/outline";
import { BellIcon as BellIconSolid } from "@heroicons/react/24/solid";
import { useGetAlertQuery } from "core/models/alerts";
import { AppObjectType } from "core/models/appObjects";
import { SlackViewInsightEditor } from "core/components/InsightCard/SlackViewInsightEditor";
import { useParams } from "react-router-dom";

export const AlertMenuItem: React.FC<{
  viewInsight: IViewInsight;
}> = ({ viewInsight }) => {
  const hasViewInsightAlerts = useFlag(VIEW_INSIGHT_ALERTS);
  const { groupId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: alert, isLoading: isAlertLoading } = useGetAlertQuery({
    appId: viewInsight.appId,
    viewInsightId: viewInsight.id,
    alertType: 0,
    objectType: viewInsight.appObject?.objectType || AppObjectType.User,
  });

  if (!hasViewInsightAlerts || groupId) return null;

  return (
    <>
      <MenuItem
        fontSize="xs"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
      >
        {isAlertLoading || !alert ? (
          <Spinner size="sm" />
        ) : alert.state === 1 ? (
          <BellIconSolid className="mr-1 h-3.5 w-3.5" />
        ) : (
          <BellIconOutline className="mr-1 h-3.5 w-3.5" />
        )}
        Alert
      </MenuItem>
      {isOpen && (
        <SlackViewInsightEditor
          isOpen={isOpen}
          onClose={onClose}
          viewInsight={viewInsight}
          alert={alert}
          isLoading={isAlertLoading}
        />
      )}
    </>
  );
};
