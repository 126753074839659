import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { usePaywall } from "core/hooks/usePaywall";
import { useDomainEnrichment } from "core/hooks/useDomainEnrichment";
import { baseUrl } from "core/constants/paywall";
import Trait from "core/components/Traits/Trait";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { Badge, Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";

interface IEnrichedCompanyProps {
  domain: string;
}

export const EnrichedCompany: React.FC<IEnrichedCompanyProps> = ({
  domain,
}) => {
  const { appId } = useParams<{ appId: string }>() as { appId: string };
  const { shouldBePaywalled } = usePaywall();
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const redirectUrl = `${baseUrl}/a/${appId}/groups`;
  const {
    enrichedDomain,
    isLoading,
    error,
    createdEnrichedDomain,
    isLoadingCreatingEnrichment,
  } = useDomainEnrichment({
    appId,
    domain,
  });

  if (isLoading) return null;

  if (error || !enrichedDomain || !enrichedDomain.enrichmentState) {
    return (
      <Box>
        <Flex mb={3} justifyContent="space-between" align="center">
          <Flex gap={2}>
            <Text fontWeight="medium">Enrichment</Text>
            <Badge colorScheme="purple">AI</Badge>
          </Flex>
        </Flex>
        <Stack gap={2}>
          <Text fontSize={"sm"} color="gray.600">
            We haven't enriched{" "}
            <Text as="span" fontWeight="bold">
              {domain}
            </Text>{" "}
            yet.{" "}
            <Text
              as="a"
              href="https://help.june.so/en/articles/7945659-manual-enrichment"
              target="_blank"
              color="primary"
            >
              Learn more
            </Text>
          </Text>
          {shouldBePaywalled ? (
            <Button
              onClick={() => {
                setShowPaywallModal(true);
              }}
              leftIcon={<LockIcon />}
            >
              Enrich
            </Button>
          ) : (
            <Button
              isLoading={isLoadingCreatingEnrichment}
              onClick={() => {
                createdEnrichedDomain({ domain, appId });
              }}
            >
              Enrich
            </Button>
          )}
        </Stack>
        {showPaywallModal && (
          <PaywallModal redirectUrl={redirectUrl}>
            {(paywallModal: IPaywallModalChildren) => (
              <paywallModal.Modal
                isOpen
                onClose={() => setShowPaywallModal(false)}
              />
            )}
          </PaywallModal>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Flex mb={4} justifyContent="space-between" align="center">
        <Flex gap={2}>
          <Text fontWeight="medium">Enrichment</Text>
          <Badge colorScheme="purple">AI</Badge>
        </Flex>
        <Flex justify="end">
          <Text color="gray.500" fontSize="xs">
            Updated on{" "}
            <Text as="span">
              {moment(enrichedDomain.updatedAt).format("Do MMM, YYYY")}
            </Text>
          </Text>
        </Flex>
      </Flex>
      {enrichedDomain.logo && (
        <div className="pb-2">
          <img
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            src={enrichedDomain.logo}
            alt={`${enrichedDomain.domain} logo`}
          />
        </div>
      )}
      {enrichedDomain.domain && (
        <Box pb={2}>
          <Trait
            name="Domain"
            value={enrichedDomain.domain}
            isTransient={false}
            withCopy
          />
        </Box>
      )}
      {enrichedDomain.linkedinUrl && (
        <Box pb={2}>
          <Trait
            name="LinkedIn URL"
            value={enrichedDomain.linkedinUrl}
            isTransient={false}
            withCopy
          />
        </Box>
      )}
      {enrichedDomain.employees && (
        <Box pb={2}>
          <Trait
            name="Employees"
            value={enrichedDomain.employees}
            isTransient={false}
            withCopy
          />
        </Box>
      )}
      {enrichedDomain.fundingAmount && (
        <Box pb={2}>
          <Trait
            name="Funding Amount"
            value={enrichedDomain.fundingAmount}
            isTransient={false}
            withCopy
          />
        </Box>
      )}
      {enrichedDomain.crunchbaseUrl && (
        <Box pb={2}>
          <Trait
            name="Crunchbase URL"
            value={enrichedDomain.crunchbaseUrl}
            isTransient={false}
            withCopy
          />
        </Box>
      )}
    </Box>
  );
};
