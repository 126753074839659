import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { cx } from "helpers/cx";
import { formatDistanceToNow } from "date-fns";
import { IEvent } from "core/types/Event";
import TrackEventDropdown from "core/modules/reports/setup/Menu/TrackEventDropdown";
import {
  useGetCanRenameEventQuery,
  useGetEventQuery,
  useRenameEventMutation,
} from "core/models/events";
import { useToast } from "core/hooks/useToast";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { PageContainer } from "core/components/PageContainer";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { Box, Button, Container, Flex, Skeleton } from "@chakra-ui/react";

export const Rename: React.FC = () => {
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();
  const toast = useToast();
  const navigate = useNavigate();

  const { data: event, isLoading } = useGetEventQuery({
    appId: Number(appId),
    eventId: Number(eventId),
  });
  const { data: renameData, isLoading: isLoadingCanRenameData } =
    useGetCanRenameEventQuery({
      appId: Number(appId),
      eventId: Number(eventId),
    });

  const [renameEvent, { isLoading: isRenamingEvent }] =
    useRenameEventMutation();

  const [newEventName, setNewEventName] = useState<IEvent | null>();

  const handleRenameEvent = async () => {
    if (canRenameEvent) {
      renameEvent({
        appId: Number(appId),
        eventId: Number(eventId),
        newEventId: newEventName.id,
      })
        .unwrap()
        .then(() => {
          toast({
            status: "success",
            title: "Event rename request submitted",
            description:
              "Your request to rename the event has been submitted. You'll receive an email once the event has been renamed.",
          });
          navigate(`/a/${appId}/events`);
        })
        .catch((err) => {
          toast({
            status: "error",
            title: "Error renaming event",
            description: err.data.error,
          });
        });
    }
  };

  if (event) {
    document.title = `June - ${event?.name}`;
  }

  if (isLoading || !event || isLoadingCanRenameData || !renameData)
    return (
      <Flex direction="column" justify="flex-start" align="flex-start">
        <Skeleton />
      </Flex>
    );

  if (!event.name) return <Box>This event does not exist :(</Box>;

  const canRenameEvent =
    newEventName && newEventName.name !== event.name && renameData?.canRename;
  const lastSentAtDate = new Date(renameData.lastSentAt);

  return (
    <PageContainer>
      <Container maxW="container.xl">
        <div className="flex flex-col gap-2">
          <Breadcrumbs
            pages={[
              { name: "Events", path: `/a/${appId}/events`, current: false },
              {
                name: event.name,
                current: true,
                path: `/a/${appId}/event/${eventId}`,
              },
              { name: "Rename", current: true, path: "" },
            ]}
          />

          <div className="flex justify-between">
            <div className="text-xl font-semibold">Rename {event.name}?</div>
            <div className="flex gap-2">
              <Button as={Link} to={`/a/${appId}/event/${eventId}`}>
                Cancel
              </Button>
              <Button
                isDisabled={!canRenameEvent}
                colorScheme="purple"
                isLoading={isRenamingEvent}
                onClick={handleRenameEvent}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-4">
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="size-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Event renaming is irreversible. Once you rename an event you
                  won't be able to undo this action.
                </h3>
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-between rounded-md border border-gray-200 p-4">
            <div className="flex gap-2">
              <div>
                <CheckCircleIcon
                  className={cx(
                    "h-6 w-6",
                    newEventName ? "text-green-500" : "text-gray-400",
                  )}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-md font-semibold">
                  Start sending "{event.name}" with a new name
                </div>
                <div className="text-sm text-gray-600">
                  Choose the new name for this event, and start sending it to
                  June.
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <TrackEventDropdown
                defaultIsOpen={false}
                onSelectEvent={(newEvent: IEvent) => setNewEventName(newEvent)}
                label={newEventName?.name}
                borderLeftRadius="md"
                borderRightRadius="md"
              />
            </div>
          </div>
          <div className="flex justify-between rounded-md border border-gray-200 p-4">
            <div className="flex gap-2">
              <div>
                <CheckCircleIcon
                  className={cx(
                    "h-6 w-6",
                    renameData.canRename ? "text-green-500" : "text-gray-400",
                  )}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-md font-semibold">
                  Stop sending "{event.name}"
                </div>
                <div className="text-sm text-gray-600">
                  Stop sending "{event.name}" for 24 hours to complete renaming.
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex flex-col">
                <div
                  className={cx(
                    "rounded-md px-3 py-1.5 text-sm font-medium",
                    renameData.canRename
                      ? "bg-gray-100 text-gray-900"
                      : "bg-red-100 text-red-600",
                  )}
                >
                  Last received {formatDistanceToNow(lastSentAtDate)} ago
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
};
