import { cx } from "helpers/cx";
import { GraphType, Size } from "core/types/ViewInsight";
import { IHightlight } from "core/types/TemplateConfig";
import { IQuery } from "core/types/Query";
import { IViewInsight } from "core/models/viewInsights";
import { useSqlEditor } from "core/hooks/useSqlEditor";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { SimpleTable } from "core/components/ViewInsight/Graphs/SimpleTable";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { HighlightCard } from "core/components/ViewInsight";
import { formatValue } from "core/components/AI/Graph/Graph";
import { CodeBracketSquareIcon, MoonIcon } from "@heroicons/react/24/solid";
import { Center, ComponentDefaultProps, Tooltip } from "@chakra-ui/react";

interface IPublicAIViewInsightCard extends ComponentDefaultProps {
  viewInsight: IViewInsight;
  highlight: IHightlight;
  sharingSecretToken?: string;
}

export const PublicAIViewInsightCard: React.FC<IPublicAIViewInsightCard> = ({
  viewInsight,
  highlight,
  sharingSecretToken,
}) => {
  const { data, isExecuteFetching, isExecuteLoading } = useSqlEditor({
    viewInsight,
    execute: true,
    sharingSecretToken,
  });
  const isLoading = isExecuteFetching || isExecuteLoading;

  const isTable = viewInsight.graphType === GraphType.Table;
  const isAreaChart = viewInsight.graphType === GraphType.Area;
  const isBarChart = viewInsight.graphType === GraphType.Bar;
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  function transformGraphData() {
    if (!data) return [];

    return data?.result?.data?.map((point: { [key: string]: string }) => {
      return {
        x: formatValue(point[(viewInsight.query as IQuery)?.graphXAxis]),
        y: formatValue(point[(viewInsight.query as IQuery)?.graphYAxis]),
      };
    });
  }

  const isEmpty = !data?.result?.data?.length;

  return (
    <HighlightCard>
      {(hc) => (
        <hc.Container
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          onClick={() => {}}
          data-testid="view-insight-card"
          hoverProps={{ cursor: "default" }}
        >
          <hc.Header>
            <div className="relative flex w-full justify-between">
              <div className="w-full">
                <div className="flex w-full min-w-[180px]">
                  {
                    <Tooltip
                      label={
                        viewInsight.useViewAudience
                          ? "Uses dashboard's audience filters"
                          : "Uses insight's audience filters"
                      }
                      placement="top"
                      hasArrow
                    >
                      <div
                        className={cx(
                          "relative right-[25px] top-[3px] h-[15px] w-1 rounded-full",
                          viewInsight.useViewAudience
                            ? "bg-purple-500"
                            : "bg-gray-400",
                        )}
                      />
                    </Tooltip>
                  }
                  <hc.Title
                    title={viewInsight.title || viewInsight.insight.name}
                    description={""}
                    highlight={highlight}
                    shouldBePaywalled={false}
                    showHighlightDescription={false}
                    isLoading={isLoading}
                    icon={
                      <Tooltip hasArrow label="SQL insight">
                        <CodeBracketSquareIcon className="h-4 text-gray-500" />
                      </Tooltip>
                    }
                    color={color}
                  />
                </div>
              </div>
            </div>
          </hc.Header>
          <hc.Body isLoading={isLoading} maxW="full" h="full">
            {!isEmpty && (
              <>
                {isTable && (
                  <SimpleTable
                    columns={data?.result?.columns}
                    data={data?.result?.data}
                    size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
                  />
                )}
                {isAreaChart && (
                  <SimpleAreaChart
                    data={transformGraphData()}
                    color={COLOR_NAME_TO_PALETTE[viewInsight.color]}
                    size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
                  />
                )}
                {isBarChart && (
                  <SimpleBarChart
                    data={transformGraphData()}
                    color={COLOR_NAME_TO_PALETTE[viewInsight.color]}
                    size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
                  />
                )}
              </>
            )}
            {isEmpty && (
              <Center ml={6} w="full" h="full">
                <div className="flex flex-col items-center gap-4">
                  <div className="rounded  bg-purple-50 p-2">
                    <MoonIcon className="h-4 text-purple-500" />
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-xs font-medium">Nothing to see here</p>
                  </div>
                </div>
              </Center>
            )}
          </hc.Body>
        </hc.Container>
      )}
    </HighlightCard>
  );
};
