import { ViewLocation } from "core/hooks/useViews";
import { Button } from "@chakra-ui/react";
import { ReportInsightsBody } from "./ReportInsightsBody";
import { BaseModal } from "./Base";
import { IViewInsight } from "core/models/viewInsights";

export const ChooseInsightsContainer: React.FC<{
  location: ViewLocation;
  isOpen: boolean;
  onClose: () => void;
  viewInsights: IViewInsight[];
  viewId: number;
}> = ({ location, isOpen, onClose, viewInsights, viewId }) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      header={<>Add insights</>}
      body={
        <ReportInsightsBody
          location={location}
          viewId={viewId}
          viewInsights={viewInsights}
        />
      }
      footer={
        <>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="purple" onClick={onClose}>
            Done
          </Button>
        </>
      }
    />
  );
};
