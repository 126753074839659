import {
  FavouriteObjectType,
  useGetFavouriteQuery,
} from "core/models/favourites";
import { useFavourites } from "core/hooks/useFavourites";
import { StarIcon as FilledStarIcon } from "@heroicons/react/24/solid";
import { StarIcon } from "@heroicons/react/24/outline";
import { MenuItem, Text } from "@chakra-ui/react";

interface FavouritesMenuItemProps {
  appId: number;
  objectId: string;
  objectType: FavouriteObjectType;
}

export const FavouritesMenuItem: React.FC<FavouritesMenuItemProps> = ({
  appId,
  objectId,
  objectType,
}) => {
  const { data: favourite } = useGetFavouriteQuery({
    appId,
    objectId,
    objectType,
  });

  const { onDeleteFavourite, onCreateFavourite } = useFavourites(appId, [
    objectType,
  ]);
  return (
    <MenuItem
      icon={
        favourite ? (
          <FilledStarIcon className="h-4" />
        ) : (
          <StarIcon className="h-4" />
        )
      }
      onClick={() => {
        if (favourite) {
          onDeleteFavourite(favourite);
        } else {
          onCreateFavourite({
            objectId,
            objectType,
          });
        }
      }}
    >
      <Text fontSize="sm">
        {favourite ? "Remove from favourites" : "Add to favourites"}
      </Text>
    </MenuItem>
  );
};
