import { useDispatch, useSelector } from "react-redux";
import {
  selectNavigation,
  setNewReportModalIsOpen,
  toggle,
} from "../models/navigation";

export const useNavigation = () => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);

  const toggleSidebar = () => {
    dispatch(toggle());
  };

  const setIsNewReportModalOpen = (isOpen: boolean) => {
    dispatch(setNewReportModalIsOpen(isOpen));
  };

  return {
    navigation,
    toggleSidebar,
    setIsNewReportModalOpen,
    width: navigation.isOpen ? "300px" : "47px",
    x: 0,
    marginLeft: navigation.isOpen ? "230px" : "0px",
    popoverWidth: "234px",
  };
};
