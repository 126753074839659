import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { CrmSettings } from "modules/Settings/Crm/SyncSettings/Settings";
import { Issues } from "modules/Settings/Crm/SyncSettings/Issues";
import { Integration, integrationNames } from "core/models/dataMappings";
import { AppObjectType } from "core/models/appObjects";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { Tabs } from "core/components/Tabs/Tabs";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { useCrmSyncs } from "core/hooks/useCrmSyncs";
import { LastSyncStatus } from "modules/Settings/Crm/SyncSettings/LastSyncStatus";
import { Button } from "@chakra-ui/react";
import { CrmSyncStatus } from "core/models/crmSyncs";
import { Plan } from "core/types/App";
import { PaywallPopover } from "core/components/Paywall/Popover";

export const CrmSyncSettingRoute: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { appId, integration, objectType } = useParams();
  const { appObject } = useAppObjects(objectType as AppObjectType);
  const { crmSyncSetting } = useCrmSyncSettings({
    integration: integration as Integration,
    objectType: objectType as AppObjectType,
  });
  const {
    lastCrmSync,
    lastSyncedAt,
    lastSyncStatus,
    isLoadingCrmSyncs,
    onTriggerSync,
    onRefresh,
  } = useCrmSyncs({
    integration: integration as Integration,
    objectType: objectType as AppObjectType,
  });

  const integrationDisplayName =
    integrationNames[integration as Integration] || "";

  const pages = [
    {
      name: integrationDisplayName,
      path: `/a/${appId}/integrations/${integration}`,
      current: false,
    },
    {
      name: `${appObject?.singularName} settings`,
      path: `/a/${appId}/integrations/${integration}/${objectType}`,
      current: true,
    },
  ];

  if (!appObject || !integration) return <></>;
  if (window.location.pathname.includes("settings/integrations")) {
    return (
      <Navigate to={`/a/${appId}/integrations/${integration}/${objectType}`} />
    );
  }

  return (
    <div>
      <div className="mb-6 max-w-4xl">
        <Breadcrumbs pages={pages} />
        <div className="flex w-full items-center justify-between">
          <div className="my-4 flex flex-col gap-1">
            <p className="text-xl font-bold">
              {integrationDisplayName}{" "}
              {appObject.singularName.toLocaleLowerCase()} sync
            </p>
            <LastSyncStatus
              appObject={appObject}
              lastCrmSync={lastCrmSync}
              status={lastSyncStatus}
              lastSyncedAt={lastSyncedAt}
              onRefresh={onRefresh}
              isLoading={isLoadingCrmSyncs}
              integration={integration as Integration}
            />
          </div>
          <PaywallPopover
            feature={`automatic sync to ${integrationDisplayName}`}
            redirect={`settings/integrations/${integration}/${objectType}`}
            plan={Plan.Pro}
          >
            <Button
              onClick={onTriggerSync}
              colorScheme="purple"
              isDisabled={
                lastSyncStatus === CrmSyncStatus.Started ||
                lastSyncStatus === CrmSyncStatus.Enqueued ||
                !(
                  crmSyncSetting?.createEnabled || crmSyncSetting?.updateEnabled
                )
              }
            >
              Sync {appObject.pluralName.toLocaleLowerCase()}
            </Button>
          </PaywallPopover>
        </div>
        <div className="mt-2 max-w-4xl">
          <Tabs
            tabs={[
              {
                title: "Settings",
                Component: () =>
                  crmSyncSetting ? (
                    <CrmSettings
                      appObject={appObject}
                      integration={integration as Integration}
                      crmSyncSetting={crmSyncSetting}
                    />
                  ) : null,
              },
              {
                title: "Issues",
                Component: () => (
                  <Issues
                    appObject={appObject}
                    integration={integration as Integration}
                  />
                ),
              },
            ]}
            value={searchParams.get("tab") ?? "Settings"}
            onChange={(tab) => {
              setSearchParams({ tab });
            }}
          />
        </div>
      </div>
    </div>
  );
};
