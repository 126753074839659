import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  IPercentageTraitConfig,
  ITraitConfig,
  ITraitWithConfig,
} from "core/types/Trait";
import { IFilter } from "core/types/Report";
import { useGetComputedTraitsQuery } from "core/models/computedTraits";
import { uuid } from "core/helpers/uuid";
import { ComputationType, TRAIT_LEVEL } from "core/constants/traits";
import { IS } from "core/constants/traitFilterComponents";
import { DAY } from "core/constants/timerange";
import {
  ANY,
  EventType,
  FIRST,
  JoinOperatorValue,
  PAGE,
  TimerangeType,
} from "core/constants/report-setup";
import { EVENT, PROPERTY } from "core/constants/filters";
import { ANY_EVENT, FIRST_EVENT } from "core/constants/events";
import { useCurrentApp } from "./useCurrentApp";

const DEFAULT_NAME = "Untitled";
const DEFAULT_FILTER = {
  id: uuid(),
  type: EVENT,
  adding: false,
  body: {},
} as IFilter;

const ANY_EVENT_FILTER = {
  id: uuid(),
  type: EVENT,
  adding: false,
  body: ANY_EVENT,
};

const FIRST_EVENT_FILTER = {
  id: uuid(),
  type: EVENT,
  adding: false,
  body: FIRST_EVENT,
};

function base64Encode(s: string) {
  return btoa(unescape(encodeURIComponent(s)));
}

export const DEFAULT_TRAIT: ITraitWithConfig = {
  id: -1,
  appId: -1,
  name: DEFAULT_NAME,
  description: "Your trait description",
  level: TRAIT_LEVEL.GROUP,
  config: {
    computationType: ComputationType.EventCount,
    joinOperator: JoinOperatorValue.AND,
    filters: [],
    timeRangeType: TimerangeType.ALL_TIME,
    intervalValue: 0,
    intervalType: DAY,
    startingEvent: DEFAULT_FILTER,
    numeratorTrait: null,
    denominatorTrait: null,
  },
  isComputed: false,
  isArchived: false,
  isLive: false,
  isPinned: false,
  isEditable: true,
  useInternalUsers: false,
  lastComputedAt: "",
  createdAt: "",
  updatedAt: "",
};

export default function useEditSmartTrait(trait?: ITraitWithConfig) {
  const { appId } = useParams();
  const [editableTrait, setEditableTrait] = useState(trait || DEFAULT_TRAIT);
  const currentApp = useCurrentApp();

  const { data: smartTraits } = useGetComputedTraitsQuery({
    appId: Number(appId),
  });

  const existingTraitNames = (smartTraits
    ?.filter((t) => !t.isArchived)
    ?.map((t) => t.name) || []) as string[];

  function onChangeTitle(val: string) {
    setEditableTrait({
      ...editableTrait,
      name: val,
    } as unknown as ITraitWithConfig);
  }

  function onChangeDescription(val: string) {
    setEditableTrait({
      ...editableTrait,
      description: val,
    } as unknown as ITraitWithConfig);
  }

  function onChangeUseInternalUsers(val: boolean) {
    setEditableTrait({
      ...editableTrait,
      useInternalUsers: val,
    } as unknown as ITraitWithConfig);
  }

  function onChangeLevel(
    level: string,
    e: React.SyntheticEvent<Element, Event>,
  ) {
    if (
      Number(level) === TRAIT_LEVEL.USER &&
      editableTrait.config.computationType === ComputationType.UserCount
    ) {
      setEditableTrait({
        ...editableTrait,
        level: Number(level),
        config: {
          ...editableTrait.config,
          computationType: ComputationType.EventCount,
        },
      } as unknown as ITraitWithConfig);
      return;
    }
    setEditableTrait({
      ...editableTrait,
      level: Number(level),
    } as unknown as ITraitWithConfig);
  }

  function onAddFilter(type: EventType) {
    const newFilter =
      type === PAGE ? _defaultPageEventFilter() : _defaultFilterFor(type);

    const updatedFilters = [
      ...editableTrait.config.filters,
      newFilter,
    ] as IFilter[];

    setEditableTrait({
      ...editableTrait,
      config: {
        ...editableTrait.config,
        filters: updatedFilters,
      },
    } as unknown as ITraitWithConfig);
  }

  function onRemoveFilter(index: number) {
    setEditableTrait((prevTrait) => {
      const updatedFilters = prevTrait.config.filters
        .filter((_, i) => i !== index)
        .map((filter) => ({ ...filter, key: uuid() }));

      const newTrait = {
        ...prevTrait,
        config: {
          ...prevTrait.config,
          filters: updatedFilters,
        },
      };

      return newTrait;
    });
  }

  function onChangeFilter(filter: IFilter, index: number) {
    const _filter = {
      ...filter,
      body: {
        ...filter.body,
      },
    };

    const updatedFilters = [
      ...editableTrait.config.filters.slice(0, index),
      _filter,
      ...editableTrait.config.filters.slice(index + 1),
    ] as IFilter[];

    setEditableTrait({
      ...editableTrait,
      config: {
        ...editableTrait.config,
        filters: updatedFilters,
      },
    } as unknown as ITraitWithConfig);
  }

  function onChangeComputationType(val: ComputationType) {
    setEditableTrait({
      ...editableTrait,
      config: {
        ...editableTrait.config,
        computationType: val,
        timeRangeType: TimerangeType.RELATIVE,
        intervalValue: 7,
        intervalType: DAY,
      },
    } as unknown as ITraitWithConfig);
  }

  function onChangeTimerange(
    timerange: Pick<
      ITraitConfig,
      "timeRangeType" | "intervalValue" | "intervalType"
    >,
  ) {
    setEditableTrait({
      ...editableTrait,
      config: {
        ...editableTrait.config,
        timeRangeType: timerange.timeRangeType,
        intervalValue: timerange.intervalValue,
        intervalType: timerange.intervalType,
      },
    } as unknown as ITraitWithConfig);
  }

  function onChangeStartingEvent(val: IFilter) {
    setEditableTrait({
      ...editableTrait,
      config: {
        ...editableTrait.config,
        startingEvent: val,
      },
    } as unknown as ITraitWithConfig);
  }

  function onChangePercentageTrait(config: IPercentageTraitConfig) {
    setEditableTrait({
      ...editableTrait,
      config: {
        ...editableTrait.config,
        numeratorTrait: config.numeratorTrait || null,
        denominatorTrait: config.denominatorTrait || null,
      },
    } as unknown as ITraitWithConfig);
  }

  function _defaultPageEventFilter() {
    return {
      id: uuid(),
      adding: false,
      type: PAGE,
      body: {
        eventId: uuid(),
        type: PAGE,
        properties: [
          {
            body: {
              property: "url",
              comparisonType: IS,
              value: _defaultDomain(),
            },
            type: PROPERTY,
          },
        ],
      },
    } as IFilter;
  }

  function _defaultFilterFor(type: EventType) {
    switch (type) {
      case PAGE:
        return _defaultPageEventFilter();
      case ANY:
        return ANY_EVENT_FILTER;
      case FIRST:
        return FIRST_EVENT_FILTER;
      default:
        return DEFAULT_FILTER;
    }
  }

  function _defaultDomain() {
    return currentApp.companyDomain
      ? `https://${currentApp.companyDomain}`
      : "https://example.com";
  }

  return {
    editableTrait,
    existingTraitNames,
    onChangeTitle,
    onChangeDescription,
    onChangeUseInternalUsers,
    onChangeLevel,
    onAddFilter,
    onRemoveFilter,
    onChangeFilter,
    onChangeComputationType,
    onChangeTimerange,
    onChangeStartingEvent,
    onChangePercentageTrait,
    isDirty:
      base64Encode(JSON.stringify(editableTrait)) !==
      base64Encode(JSON.stringify(trait)),
  };
}
