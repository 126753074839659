import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { IApp } from "core/types/App";
import { useGetAppsQuery } from "core/models/apps";
import { setCurrentApp } from "core/models/app";
import { DEMO_WORKSPACE_APP } from "core/hooks/useDemo";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { SolidChevronDown } from "core/design-system/components/Icon/Custom/SolidChevronDown";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { DEMO_APP_ID } from "core/constants/appIds";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button } from "@chakra-ui/react";

export const WorkspaceSwitcher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: apps, isLoading } = useGetAppsQuery();
  const { name } = useCurrentApp();
  const [searchValue, setSearchValue] = useState("");

  const handleAppChange = (redirectToApp: IApp) => {
    dispatch(setCurrentApp({ app: redirectToApp }));
    navigate(`/a/${redirectToApp.id}/home`);

    if (redirectToApp.id === DEMO_APP_ID) {
      localStorage.setItem("demo_app_enabled", "true");
    }
  };

  if (isLoading) return null;

  return (
    <div className="absolute left-0 top-0 m-4">
      <Popover className="relative">
        <PopoverButton>
          <Button
            rightIcon={<SolidChevronDown className="h-4 w-4 text-gray-900" />}
          >
            {name}
          </Button>
        </PopoverButton>
        <PopoverPanel className="flex flex-col">
          {({ close }) => (
            <>
              <Lifecycle onUnmount={() => setSearchValue("")} />
              <Command>
                {apps && apps?.length > 7 && (
                  <CommandInput
                    className="mb-1"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                )}
                <CommandList>
                  {apps
                    ?.filter((a) =>
                      a.name.toLowerCase().includes(searchValue.toLowerCase()),
                    )
                    ?.map((app) => (
                      <CommandOption
                        key={app.id}
                        value={() => {
                          close();
                          handleAppChange(app);
                        }}
                      >
                        {app.name}
                      </CommandOption>
                    ))}
                  <CommandOption
                    onClick={() => handleAppChange(DEMO_WORKSPACE_APP)}
                    value={String(DEMO_WORKSPACE_APP.id)}
                  >
                    <div className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <div>{DEMO_WORKSPACE_APP.name}</div>
                        <div>
                          <span className="inline-flex items-center rounded-md bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-700">
                            Demo
                          </span>
                        </div>
                      </div>
                    </div>
                  </CommandOption>
                </CommandList>
              </Command>
            </>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
