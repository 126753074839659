import { GraphType, Measure } from "core/types/ViewInsight";
import { useGetViewInsightPreviewQuery } from "core/models/viewInsights";
import { useParams } from "react-router-dom";
import { InsightBuilder } from "./InsightBuilder";
import { useAppObjects } from "core/hooks/useAppObjects";

export const AddInsightBuilder: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  viewId: number;
  setShowChooseExistingReportInsights: (show: boolean) => void;
}> = ({ isOpen, onClose, viewId, setShowChooseExistingReportInsights }) => {
  const { appId } = useParams();
  const { userAppObject } = useAppObjects();
  const { data: previewInsight, isLoading } = useGetViewInsightPreviewQuery({
    appId: Number(appId),
    viewId: viewId,
    measure: Measure.ActiveUsers,
    graphType: GraphType.Area,
    objectType: userAppObject?.objectType,
  });

  if (isLoading || !previewInsight || !isOpen) return null;

  return (
    <InsightBuilder
      isOpen={isOpen}
      onClose={onClose}
      viewId={viewId}
      viewInsight={previewInsight}
      setShowChooseExistingReportInsights={setShowChooseExistingReportInsights}
      isAddingNewInsight={true}
    />
  );
};
