import { useParams } from "react-router-dom";
import { cx } from "helpers/cx";
import { IEventProperty } from "core/types/EventProperty";
import { IEvent } from "core/types/Event";
import { PropertyValue } from "core/modules/reports/setup/Editor/PropertyValue";
import { getMaxWidth } from "core/modules/audience/Filters/TraitFilter/Value";
import {
  COMPARISON_TO_NAME,
  IS,
  IS_NOT,
  IS_UNKNOWN,
} from "core/constants/traitFilterComponents";
import { ENTER } from "core/constants/keyCodes";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ComponentDefaultProps, Tooltip } from "@chakra-ui/react";
import { WrapArrow } from "./WrapArrow";
import { ComparisonMenu } from "./Property/Comparison";

interface IPropertyEditor extends ComponentDefaultProps {
  event: IEvent;
  onRemoveProperty: ({
    event,
    property,
  }: {
    event: IEvent;
    property: IEventProperty;
  }) => void;
  onChangePropertyComparison: (
    newComparison: string,
    prop: IEventProperty,
  ) => void;
  onChangeTrackPropertyValue: (
    e: React.ChangeEvent<HTMLInputElement>,
    event: IEvent,
    prop: IEventProperty,
    selected: boolean,
  ) => void;
  onClose?: () => void;
}

export const PropertyEditor: React.FC<IPropertyEditor> = ({
  event,
  onRemoveProperty,
  onChangePropertyComparison,
  onChangeTrackPropertyValue,
  onClose,
}) => {
  const { appId } = useParams();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === ENTER) onClose && onClose();
  };

  return (
    <div className="flex flex-wrap gap-y-1">
      {event?.properties?.map((prop, index) => {
        return (
          <div className="flex min-h-[33px]">
            <div className="flex items-center">
              <WrapArrow direction="left" />
              <div className="flex h-full items-center gap-1 bg-gray-50 pl-3 text-sm">
                {index !== 0 && <p>and</p>}
                <p>where</p>
                <p className="font-semibold">{prop?.body?.property}</p>
                <ComparisonMenu
                  selectedProperty={
                    COMPARISON_TO_NAME[Number(prop?.body?.comparisonType)]
                  }
                  onSelectComparison={(c) =>
                    onChangePropertyComparison(String(c), prop)
                  }
                />
                <div
                  className={cx(
                    "flex cursor-pointer truncate",
                    `max-w-[${getMaxWidth(true)}]`,
                    ![IS, IS_NOT].includes(Number(prop?.body?.comparisonType))
                      ? "bg-gray-50 hover:bg-gray-50"
                      : "rounded bg-gray-50 px-1 py-0.5 hover:bg-gray-100",
                  )}
                  key={index}
                >
                  {Number(prop?.body?.comparisonType) !== IS_UNKNOWN &&
                    appId && (
                      <PropertyValue
                        event={event}
                        appId={Number(appId)}
                        prop={prop}
                        showPropertyValues={[IS, IS_NOT].includes(
                          Number(prop?.body?.comparisonType),
                        )}
                        onChangeTrackPropertyValue={(
                          inputEvent,
                          event,
                          property,
                          selected,
                        ) =>
                          onChangeTrackPropertyValue(
                            inputEvent,
                            event,
                            property,
                            selected,
                          )
                        }
                        onKeyDown={handleKeyDown}
                        onClose={() => onClose && onClose()}
                      />
                    )}
                </div>
                <div
                  className="my-auto flex h-full cursor-pointer items-center px-2 hover:bg-gray-100"
                  onClick={() => onRemoveProperty({ event, property: prop })}
                >
                  <Tooltip
                    label="Remove this filter"
                    hasArrow
                    placement="bottom"
                  >
                    <div className="text-gray-600 hover:text-gray-800">
                      <XMarkIcon className="h-4" />
                    </div>
                  </Tooltip>
                </div>
                <WrapArrow position="20" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
