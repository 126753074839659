import humps from "humps";
import { ITraitWithConfig } from "core/types/Trait";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

interface IGetTraitsArgs {
  appId: number;
}

interface IGetTraitArgs {
  appId: number;
  traitId: number;
}

interface ICreateOrUpdateTraitArgs {
  appId: number;
  trait: Partial<ITraitWithConfig>;
}

interface IComputeTraitArgs {
  appId: number;
  trait: Partial<ITraitWithConfig>;
  entityId: string;
}

interface IComputeTraitResult {
  result: number;
}

interface IPinTraitArgs {
  appId: number;
  name: string;
  level: number;
  isPinned: boolean;
}

export const computedTraitsApi = createApi({
  baseQuery,
  reducerPath: "computedTraitsApi",
  tagTypes: ["ComputedTrait", "TableTraitConfig"],
  endpoints: (builder) => ({
    getComputedTraits: builder.query<ITraitWithConfig[], IGetTraitsArgs>({
      query: ({ appId = false }) => ({
        method: "GET",
        url: `computed_traits`,
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["ComputedTrait"],
      transformResponse: (response: ITraitWithConfig[]) =>
        humps.camelizeKeys(response) as ITraitWithConfig[],
    }),
    getComputedTrait: builder.query<ITraitWithConfig, IGetTraitArgs>({
      query: ({ appId, traitId = false }) => ({
        method: "GET",
        url: `computed_traits/${traitId}`,
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: (result, error, arg) => [
        { type: "ComputedTrait" as const, id: result?.id },
      ],
      transformResponse: (response: ITraitWithConfig) =>
        humps.camelizeKeys(response) as ITraitWithConfig,
    }),
    createTrait: builder.mutation<ITraitWithConfig, ICreateOrUpdateTraitArgs>({
      query: ({ appId, trait }) => ({
        method: "POST",
        url: `computed_traits`,
        params: humps.decamelizeKeys({ appId }),
        body: humps.decamelizeKeys(trait),
      }),
      invalidatesTags: ["ComputedTrait"],
      transformResponse: (response: ITraitWithConfig) =>
        humps.camelizeKeys(response) as ITraitWithConfig,
    }),
    updateTrait: builder.mutation<ITraitWithConfig, ICreateOrUpdateTraitArgs>({
      query: ({ appId, trait }) => ({
        method: "PUT",
        url: `computed_traits/${trait.id}`,
        params: humps.decamelizeKeys({ appId }),
        body: humps.decamelizeKeys(trait),
      }),
      invalidatesTags: ["ComputedTrait", "TableTraitConfig"],
      transformResponse: (response: ITraitWithConfig) =>
        humps.camelizeKeys(response) as ITraitWithConfig,
    }),
    computeTrait: builder.mutation<
      ITraitWithConfig,
      {
        appId: number;
        traitId: number;
      }
    >({
      query: ({ appId, traitId }) => ({
        method: "POST",
        url: `computed_traits/${traitId}/compute`,
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: ["ComputedTrait", "TableTraitConfig"],
      transformResponse: (response: ITraitWithConfig) =>
        humps.camelizeKeys(response) as ITraitWithConfig,
    }),
    archiveTrait: builder.mutation<void, { appId: number; traitId: number }>({
      query: ({ appId, traitId }) => ({
        method: "POST",
        url: `computed_traits/${traitId}/archive`,
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: ["ComputedTrait"],
    }),
    getComputeTrait: builder.query<IComputeTraitResult, IComputeTraitArgs>({
      query: ({ appId, trait, entityId }) => {
        return {
          method: "POST",
          url: `computed_traits/preview`,
          params: humps.decamelizeKeys({ appId, entityId }),
          body: humps.decamelizeKeys(trait),
        };
      },
      transformResponse: (response: IComputeTraitResult) =>
        humps.camelizeKeys(response) as IComputeTraitResult,
    }),
    pinTrait: builder.mutation({
      query: ({ appId, name, level, isPinned }: IPinTraitArgs) => ({
        method: "PUT",
        url: `traits/pin`,
        body: humps.decamelizeKeys({
          appId,
          name,
          level,
          isPinned,
        }),
      }),
      invalidatesTags: ["ComputedTrait"],
    }),
  }),
});

export const {
  useLazyGetComputedTraitQuery,
  useGetComputedTraitsQuery,
  useGetComputedTraitQuery,
  useCreateTraitMutation,
  useUpdateTraitMutation,
  useComputeTraitMutation,
  useArchiveTraitMutation,
  useLazyGetComputeTraitQuery,
  usePinTraitMutation,
} = computedTraitsApi;
