import React from "react";
import { MatchingMappingRow } from "modules/Settings/Crm/SyncSettings/MatchingMappingRow";
import { ReverseMappingTable } from "modules/Integrations/ReverseMappingTable";
import { MappingTable } from "modules/Integrations/MappingTable";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { useTraits } from "core/hooks/useTraits";
import useFlag from "core/hooks/useFlag";
import { useDataMappings } from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useCrmProperties } from "core/hooks/useCrmProperties";
import { REVERSE_SYNC } from "core/constants/features";
import { PreviewSyncContainer } from "./PreviewSyncContainer";

export const MappingData: React.FC<{
  appObject: IAppObject;
  integration: Integration;
  isLoading?: boolean;
}> = ({ appObject, integration, isLoading }) => {
  const { id: appId } = useCurrentApp();
  const hasReverseSync = useFlag(REVERSE_SYNC);
  const { traits } = useTraits(appObject.objectType);
  const { isLoading: isLoadingProperties } = useCrmProperties({
    appId,
    integration,
    objectType: appObject.objectType,
  });
  const { dataMappings } = useDataMappings({
    appId,
    integration,
    appObject,
  });
  const { matchingIdentifierDataMapping } = useDataMapping({
    appId,
    integration,
    appObject,
  });

  return (
    <>
      <div className="mt-6">
        {matchingIdentifierDataMapping && (
          <MatchingMappingRow
            isLoading={isLoadingProperties || isLoading}
            appObject={appObject}
            integration={integration}
            matchingIdentifierDataMapping={matchingIdentifierDataMapping}
          />
        )}
      </div>

      <div className="mt-8 flex w-full gap-10">
        <div className="flex w-full flex-col">
          {traits && (
            <MappingTable
              appObject={appObject}
              integration={integration}
              isLoading={isLoadingProperties}
            />
          )}
          {hasReverseSync && integration === Integration.Hubspot && (
            <div className="mt-5">
              <ReverseMappingTable
                appObject={appObject}
                integration={integration}
                isLoading={isLoadingProperties}
              />
            </div>
          )}
        </div>
        <div className="min-w-[400px]">
          <PreviewSyncContainer
            appObject={appObject}
            integration={integration}
            dataMappings={dataMappings}
          />
        </div>
      </div>
    </>
  );
};
