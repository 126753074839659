import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import ApiKeys from "modules/Settings/ApiKeys";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { SDK } from "core/constants/apiKeys";
import { KeyIcon } from "@heroicons/react/24/outline";
import { Flex } from "@chakra-ui/react";
import * as Integration from "../../../modules/Settings/Integrations/Integration";
import CreateApiKey from "modules/Settings/CreateApiKey";

const CONTENT: Integration.IntegrationMetadata = {
  title: "June SDK",
  description: "Track your events with us",
  logoComponent: (
    <CompanyJuneLogoIcon name="companyJuneFullLogo" w="full" h="full" />
  ),
  docsUrl: "https://www.june.so/docs",
};

export const Card = ({ children }: { children: React.ReactNode }) => (
  <Flex
    direction="column"
    bg="white"
    shadow="sm"
    p={6}
    w="full"
    my={4}
    borderWidth={1}
    borderColor={"gray.100"}
    boxShadow="sm"
    borderRadius="md"
  >
    {children}
  </Flex>
);

const JuneSdk: React.FC = () => {
  const location = useLocation();
  const { appId } = useParams<{ appId?: string }>();

  if (location.pathname.includes("integrations")) {
    return <Navigate to={`/a/${appId}/settings/june-sdk`} replace />;
  }

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header
          metadata={CONTENT}
          ActionComponent={() => <CreateApiKey source={SDK} />}
        />
        <Integration.Body>
          <div className="rounded-md border border-gray-100 px-6 py-4 shadow-sm">
            <div className="flex items-center gap-2 border-b border-gray-100 pb-2">
              <KeyIcon width={20} height={20} />
              <h4 className="text-sm font-medium">Your write API keys</h4>
            </div>

            <div className="mt-8">
              <ApiKeys source={SDK} showCreateApiKey={false} />
            </div>
          </div>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};

export default JuneSdk;
