import React from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  StyleProps,
} from "@chakra-ui/react";
import { ClipboardButton } from "./ClipboardButton";

interface IClipboardInputProps extends StyleProps {
  value: string;
  disabled?: boolean;
  inputSize?: "xs" | "sm" | "md" | "lg";
  isSecret?: boolean;
}

const ClipboardInput: React.FC<IClipboardInputProps> = ({
  value,
  disabled = false,
  inputSize,
  isSecret = false,
}) => {
  const obfuscateValue = (val: string) => {
    if (!isSecret) return val;
    const prefix = val.slice(0, 3);
    const suffix = val.slice(-4);
    const middleLength = val.length - 7;
    return `${prefix}${"\u2022".repeat(middleLength)}${suffix}`;
  };

  return (
    <div className="flex">
      <InputGroup size={inputSize ? inputSize : "sm"}>
        <Input
          data-id-copy-input
          value={obfuscateValue(value)}
          isReadOnly
          focusBorderColor="purple.500"
          disabled={disabled}
          border="none"
          cursor="not-allowed"
          w="300px"
          type="text"
          textAlign="right"
        />
        <InputRightElement>
          <ClipboardButton
            value={value}
            disabled={disabled}
            showLabel={false}
          />
        </InputRightElement>
      </InputGroup>
    </div>
  );
};

export default ClipboardInput;
