import { useParams } from "react-router-dom";
import { useGroup } from "core/hooks/useGroup";
import { getDomainFromEmails } from "core/helpers/domainFromEmails";
import { Badge, Flex, Text } from "@chakra-ui/react";
import { EnrichedCompany } from "./EnrichedCompany";

export const EnrichmentCard: React.FC = () => {
  const { groupId: encodedGroupId } = useParams<{ groupId: string }>() as {
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { group } = useGroup({ id: groupId });

  const emails = group?.contacts?.reduce((emails, contact) => {
    if (contact?.email) {
      emails.push(contact.email);
    }
    return emails;
  }, [] as string[]);
  const domain = getDomainFromEmails(emails);

  if (!domain) {
    return (
      <div>
        <Flex mb={3} justifyContent="space-between" align="center">
          <Flex gap={2}>
            <Text fontWeight="medium">Enrichment</Text>
            <Badge colorScheme="purple">AI</Badge>
          </Flex>
        </Flex>

        <Text fontSize={"sm"} color="gray.600">
          We can't enrich this company because it doesn't have a domain.
        </Text>
      </div>
    );
  }

  return <EnrichedCompany domain={domain} />;
};
