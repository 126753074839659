import { useParams } from "react-router-dom";
import { IViewInsight, useGetViewInsightQuery } from "core/models/viewInsights";
import { SQLEditor } from "./SQLEditor";

export const SQLEditorContainer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  viewInsight: IViewInsight;
  onRefetch?: () => void;
}> = ({ isOpen, onClose, viewInsight, onRefetch }) => {
  const { appId } = useParams();
  const { data, isLoading } = useGetViewInsightQuery({
    id: viewInsight.id,
    viewId: viewInsight.viewId,
    appId: Number(appId),
  });

  if (isLoading || !data) return <></>;

  return (
    <SQLEditor
      viewInsight={data}
      viewId={data.viewId}
      isOpen={isOpen}
      onClose={onClose}
      onRefetch={onRefetch}
      execute
    />
  );
};
