import {
  ComponentDefaultProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";

export const BaseModal: React.FC<
  {
    header: React.ReactNode;
    body: React.ReactNode;
    footer: React.ReactNode;
    backButton?: React.ReactNode;
  } & ComponentDefaultProps
> = ({ header, body, footer, backButton, ...props }) => {
  return (
    <Modal isCentered scrollBehavior="inside" {...(props as ModalProps)}>
      <ModalOverlay />
      <ModalContent h="80%" maxH="98vh" minH="98vh" w="99vw" borderRadius="lg">
        <ModalHeader>
          <div className="flex flex-col gap-x-2">
            <div className="flex">{backButton}</div>
            {header}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};
