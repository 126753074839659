import { useNavigate, useParams } from "react-router-dom";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { usePeopleList } from "core/hooks/usePeopleList";
import { Tab } from "core/components/Tabs/Tab";
import { CreateButton as SavedAudienceCreateButton } from "core/components/SavedAudiences/CreateButton";
import { PageContainer } from "core/components/PageContainer";
import { ErrorMessage } from "core/components/List/ErrorMessage";
import { Flex, Stack, useDisclosure } from "@chakra-ui/react";
import { PeopleTabs } from "./People/Tabs";
import { ObjectList } from "./People/ObjectList";
import DefaultHeader from "../modules/Pages/DefaultHeader";

const SECTION = {
  name: "People",
  title: "People",
  description: "The customers that you have identified in your product",
  howItWorksArticle:
    "https://help.june.so/en/articles/8053235-how-audience-filters-work",
};

export const People: React.FC = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const {
    isOpen: isAudienceModalOpen,
    onClose: closeAudienceModal,
    onOpen: openAudienceModal,
  } = useDisclosure();
  const {
    activeAppObject,
    onSetAudienceFilters,
    audienceFilters,
    searchQuery,
    setPage,
    setSearchQuery,
    listConfig,
    entities,
    pagy,
    isLoading,
    error,
    skipPagination,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = usePeopleList();

  return (
    <div className="flex gap-1">
      <div className="w-full">
        <PageContainer
          className="w-full min-w-full max-w-full"
          px="60px"
          maxW="container.xl"
          minW="container.lg"
        >
          <DefaultHeader
            section={{
              ...SECTION,
              sectionRight: () => (
                <SavedAudienceCreateButton
                  onClick={openAudienceModal}
                  isDisabled={Boolean(audienceFilters.filterGroups?.length)}
                  audienceFilters={audienceFilters}
                  isOpen={isAudienceModalOpen}
                  onClose={closeAudienceModal}
                />
              ),
            }}
          />
          <Stack maxW="full" w="full" justify="flex-start" align="flex-start">
            <Flex
              data-id-contacts-list-container
              w="full"
              maxW="full"
              direction="column"
              justify="space-between"
            >
              <Flex
                w="full"
                justifyContent="space-between"
                align="center"
                mb={4}
              >
                <AudienceFilters
                  key={activeAppObject?.slug}
                  audienceFilters={audienceFilters}
                  setAudienceFilters={onSetAudienceFilters}
                  view={activeAppObject?.slug}
                />
              </Flex>
              <Flex mt={2}>
                <PeopleTabs
                  audienceFilters={audienceFilters}
                  searchQuery={searchQuery}
                  setPage={setPage}
                />
                <div className="mx-4 h-6 border-r border-gray-300" />
                <Tab
                  isSelected={false}
                  onTabChange={() => navigate(`/a/${appId}/audiences`)}
                >
                  Audiences
                </Tab>
              </Flex>
            </Flex>
          </Stack>
          <Flex w="full" pb={10}>
            {activeAppObject?.objectType && error ? (
              <ErrorMessage />
            ) : (
              <>
                {activeAppObject && (
                  <ObjectList
                    appObject={activeAppObject}
                    listConfig={listConfig}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    entities={entities}
                    isLoading={isLoading}
                    pagy={pagy}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    audienceFilters={audienceFilters}
                    skipPagination={skipPagination}
                    onSetAudienceFilters={onSetAudienceFilters}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                )}
              </>
            )}
          </Flex>
        </PageContainer>
      </div>
    </div>
  );
};
