import { SyntheticEvent, useState } from "react";
import moment from "moment";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { IReportInsight } from "core/types/Report";
import { ExplorationGraph } from "core/modules/reports/report-types/Explorer/ExplorationGraph";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import colors from "core/design-system/constants/theme/colors";
import { INTERVAL } from "core/constants/timerange";
import TimeRangePicker from "core/components/TimeRangePicker";
import InsightCard from "core/components/InsightCard/Index";
import { scale } from "chroma-js";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ComponentDefaultProps,
  Divider,
} from "@chakra-ui/react";
import { IListConfig } from "core/components/List/List.types";
import List from "core/components/List";

const mockData = {
  0: {
    data: [
      { date: "2022-02-01T00:00:00.000+00:00", count: 10 },
      { date: "2022-02-02T00:00:00.000+00:00", count: 20 },
      { date: "2022-02-03T00:00:00.000+00:00", count: 30 },
      { date: "2022-02-04T00:00:00.000+00:00", count: 15 },
      { date: "2022-02-05T00:00:00.000+00:00", count: 20 },
      { date: "2022-02-06T00:00:00.000+00:00", count: 40 },
      { date: "2022-02-07T00:00:00.000+00:00", count: 20 },
    ],
    max: 40,
    mean: 3,
    min: 0,
    name: "Page where url contains home",
    p90: 7,
    total: 370,
    uuid: "357f3355-aa9f-dd8e-fe4e-d44c2a279eba",
  },
  1: {
    data: [
      { date: "2022-02-01T00:00:00.000+00:00", count: 15 },
      { date: "2022-02-02T00:00:00.000+00:00", count: 15 },
      { date: "2022-02-03T00:00:00.000+00:00", count: 25 },
      { date: "2022-02-04T00:00:00.000+00:00", count: 20 },
      { date: "2022-02-05T00:00:00.000+00:00", count: 25 },
      { date: "2022-02-06T00:00:00.000+00:00", count: 10 },
      { date: "2022-02-07T00:00:00.000+00:00", count: 15 },
    ],
    max: 25,
    mean: 3,
    min: 0,
    name: "signup where source is landing_page",
    p90: 7,
    total: 421,
    uuid: "357f3355-aa9f-dd8e-fe4e-d44c2a279eba",
  },
};

const ColorCell: React.FC<ComponentDefaultProps> = ({ props }) => {
  return <Box h="10px" w="10px" borderRadius="full" bg={props.color} />;
};

const CONFIG = {
  resourceName: "Events",
  columns: [
    {
      title: "",
      name: "color",
      align: "left",
      fontSize: "sm",
      Component: ColorCell,
    },
    {
      title: "Event",
      name: "name",
      align: "left",
      fontSize: "sm",
    },
    {
      title: "Total",
      name: "total",
      align: "left",
      fontSize: "sm",
      sortable: true,
    },
    {
      title: "Min",
      name: "min",
      align: "left",
      fontSize: "sm",
      sortable: true,
    },
    {
      title: "Max",
      name: "max",
      align: "left",
      fontSize: "sm",
      sortable: true,
    },
    {
      title: "Mean",
      name: "mean",
      align: "left",
      fontSize: "sm",
      sortable: true,
    },
  ],
} as IListConfig;

export const getSerieColors = (data: any) => {
  const serieColors = scale([
    colors.templateColors.orange.primary,
    colors.templateColors.purple.primary,
    colors.templateColors.green.primary,
    colors.templateColors.red.primary,
    colors.templateColors.pink.primary,
  ])
    .mode("lch")
    .colors(Object.keys(data).length);
  return serieColors;
};

export function useChartData({ data }: { data: any }) {
  if (!data)
    return {
      series: null,
    };
  const serieColors = getSerieColors(data);

  const series = Object.values(data).map((serie: any, idx) => ({
    ...serie,
    color: serieColors[idx],
    data:
      serie?.data?.map((d: { date: string; count: number }) => ({
        x: moment(d.date).format("ll"),
        y: d.count,
        eventProperties: serie.eventProperties,
      })) || [],
  }));

  return {
    series,
  };
}

const options: ITimerangeOption[] = [
  { label: "Day", value: "0", count: 0, short: "Day", type: INTERVAL },
  { label: "Week", value: "1", count: 1, short: "Week", type: INTERVAL },
  { label: "Month", value: "2", count: 2, short: "Month", type: INTERVAL },
];

export const ExplorationContainer: React.FC<IReportInsight> = ({
  previewMode,
  sharingMode,
  screenshotMode,
  sharingSecretToken,
  report,
  config,
  showYAxis,
  hasBeenPersisted,
}) => {
  const insight = config.insights.find(
    ({ slug }: { slug: string }) => slug === "exploration",
  );
  const isSetupValid = config.validateSetup(report.config);
  const [intervalType, setIntervalType] = useState<ITimerangeOption>(
    options[0],
  );
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );
  const [sortedBy, setSortedBy] = useState({
    name: "total",
    direction: "desc",
  });
  const { response, isLoading, isFetching, error, onRefreshInsight } =
    useInsightDataFetching({
      insight,
      insightParams: {
        intervalType: intervalType.count,
      },
      report,
      sharingSecretToken,
      previewMode,
      skip: !isSetupValid,
    });

  const data = previewMode ? mockData : response?.data;
  const { series } = useChartData({ data });

  if (!insight) return null;

  const noData = series?.length === 0;

  const rowData = series
    ?.map((v) => ({ ...v }))
    ?.sort((a, b) => {
      if (sortedBy.direction === "asc")
        return a[sortedBy.name] > b[sortedBy.name] ? 1 : -1;

      return a[sortedBy.name] < b[sortedBy.name] ? 1 : -1;
    });

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container insight={insight} id={insight.slug}>
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={sharingMode}
            insight={insight}
            showActions={!sharingMode && !previewMode}
            sharingMode={sharingMode}
            config={config}
            report={report}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
          >
            <TimeRangePicker
              previewMode={previewMode}
              options={options}
              timerange={intervalType}
              setTimeRange={setIntervalType}
            />
          </Card.Header>
          <Card.Body
            isLoading={isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
          >
            {noData ? (
              <Card.EmptyState />
            ) : (
              <ExplorationGraph
                series={series || []}
                showYAxis={Boolean(showYAxis)}
                hoveringIndex={hoveringIndex}
              />
            )}
          </Card.Body>
          {insight.description && !screenshotMode && (
            <Card.Footer w="100%" p={0}>
              <div className="flex w-full flex-col">
                {isSetupValid && (
                  <Accordion w="100%" borderColor="gray.100" allowToggle>
                    <AccordionItem border="none" shadow="none">
                      <AccordionButton
                        boxShadow="none"
                        _hover={{ bg: "white", borderBottomRadius: "lg" }}
                        px={7}
                        py={4}
                      >
                        <div className="flex w-full justify-between">
                          <span className="text-sm font-medium">
                            View breakdown
                          </span>
                        </div>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel px={7} paddingTop={0}>
                        <div className="max-h-510px flex w-full flex-col justify-start overflow-auto">
                          <List
                            sortedBy={sortedBy}
                            setSortedBy={setSortedBy}
                            isLoading={isLoading || isFetching}
                            rows={rowData || []}
                            config={CONFIG}
                            flex="1"
                            onListRowMouseEnter={(e: SyntheticEvent) =>
                              setHoveringIndex(e.currentTarget.id)
                            }
                            onListRowMouseLeave={(e: SyntheticEvent) =>
                              setHoveringIndex(undefined)
                            }
                          />
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
                <Divider />
                <div className="flex w-full flex-col">
                  <div className="w-full p-0">
                    <Card.DescriptionAccordion insight={insight} />
                  </div>
                </div>
              </div>
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};
