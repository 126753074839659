import { Integration } from "core/models/dataMappings";
import {
  ATTIO_SUPPORTED_PROPERTY_TYPES,
  HUBSPOT_SUPPORTED_PROPERTY_TYPES,
  SALESFORCE_SUPPORTED_PROPERTY_TYPES,
} from "../SelectDestination";

export const NotSupportedTypeTooltip: React.FC<{
  type: string;
  integration: Integration;
}> = ({ type, integration }) => {
  function getSupportedTypes(integration: Integration) {
    switch (integration) {
      case Integration.Hubspot:
        return HUBSPOT_SUPPORTED_PROPERTY_TYPES;
      case Integration.Attio:
        return ATTIO_SUPPORTED_PROPERTY_TYPES;
      case Integration.Salesforce:
        return SALESFORCE_SUPPORTED_PROPERTY_TYPES;
      default:
        return [];
    }
  }

  const supportedTypes = getSupportedTypes(integration);
  return (
    <div className="flex flex-col space-y-2 p-2">
      <div className="flex">
        <p className="text-sm">
          Unsupported {integration} property type "{type}"
        </p>
      </div>
      <div className="flex">
        <p className="text-xs text-gray-300">
          Please use {integration} properties with one of the following property
          types: {supportedTypes.join(", ")}.
        </p>
      </div>
    </div>
  );
};
