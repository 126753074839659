import { Navigate, Outlet } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { DEMO_APP_ID } from "core/constants/appIds";
import { SettingsNavBar } from "./SettingsNavBar";

function Settings() {
  const { id: appId } = useCurrentApp();

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      <SettingsNavBar />
      <div className="border-1 m-2 w-full rounded-lg bg-white">
        <div className="mx-auto max-w-7xl px-12 py-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Settings;
