import { ComponentDefaultProps, Flex } from "@chakra-ui/react";

export interface IContainterProps extends ComponentDefaultProps {
  children: React.ReactNode;
  hoverProps?: { [key: string]: string | undefined };
}

export const Container: React.FC<IContainterProps> = ({
  children,
  hoverProps = {},
  ...props
}) => {
  return (
    <Flex
      _hover={{
        boxShadow: "lg",
        borderColor: "gray.100",
        cursor: "pointer",
        ...hoverProps,
      }}
      transition="all .2s ease-in-out"
      h="full"
      w="full"
      direction="column"
      border="1px solid"
      borderColor="gray.50"
      borderRadius="lg"
      boxShadow="sm"
      bg="white"
      data-testid="highlight-card-container"
      {...props}
    >
      {children}
    </Flex>
  );
};
