import React from "react";
import { IApiKey } from "core/models/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import ClipboardInput from "core/components/ClipboardInput";
import ApiKeyActionsMenu from "./ApiKeys/ActionsMenu";

interface ApiKeyProps {
  apiKey: IApiKey;
  isSecret?: boolean;
}

const ApiKey: React.FC<ApiKeyProps> = ({ apiKey, isSecret = false }) => {
  const { name, key, source } = apiKey;
  const { id: appId } = useCurrentApp();
  return (
    <div className="flex w-full items-center justify-between">
      <p className="truncate text-sm">{name ? name : "Unnamed key"}</p>
      <div className="flex items-center font-mono text-sm">
        <ClipboardInput
          isSecret={isSecret}
          value={key}
          key={key}
          inputSize="xs"
        />
        <ApiKeyActionsMenu source={source} appId={appId} apiKey={apiKey} />
      </div>
    </div>
  );
};

export default ApiKey;
