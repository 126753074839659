import { useParams } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment";
import { CrmHeader } from "modules/Contacts/Contact/CrmHeader";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";
import { FavouriteObjectType } from "core/models/favourites";
import useFlag from "core/hooks/useFlag";
import { useCompany } from "core/hooks/useCompany";
import { useAppObjects } from "core/hooks/useAppObjects";
import { FAVOURITES } from "core/constants/features";
import {
  ArrowsRightLeftIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  GlobeAltIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { MergeCompanyModal } from "./MergeCompanyModal";
import { Logo as CompanyLogo } from "./Logo";
import { DeleteCompanyModal } from "./DeleteCompanyModal";

export const Header: React.FC<StyleProps> = ({ ...styleProps }) => {
  const { companyAppObject } = useAppObjects();
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const hasFavouritesEnabled = useFlag(FAVOURITES);
  const groupId = decodeURIComponent(encodedGroupId);
  const { company } = useCompany({
    appId: Number(appId),
    id: groupId,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  if (!company || !companyAppObject) {
    return (
      <div className="flex w-full flex-col p-8">
        <div className="flex items-center">
          <CompanyLogo />
          <Box ml={4}>
            <Text fontSize="lg" fontWeight={"normal"}>
              Unknown
            </Text>
          </Box>
        </div>
      </div>
    );
  }

  const companyContactsCount = company.contacts?.length;

  if (company) {
    document.title = `June - ${company.name}`;
  }

  return (
    <div className="flex">
      <div className="z-10 flex w-full flex-col">
        <div className="flex items-start justify-between">
          <div className="mb-6 flex items-center">
            <div className="flex items-center gap-4">
              <div>
                <CompanyLogo
                  border="4px solid"
                  borderColor="white"
                  boxShadow="sm"
                />
              </div>
              <div className="flex flex-col">
                <div>
                  <Text noOfLines={1} fontSize="md" fontWeight={"medium"}>
                    {company.name}
                  </Text>
                </div>
                {company.firstSeenAt && (
                  <div className="flex">
                    <Text color="gray.600" fontSize="sm">
                      Joined{" "}
                      {moment.utc(company.firstSeenAt).format("DD MMM, YYYY")}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Menu>
            <MenuButton
              as={Button}
              float="right"
              aria-label="Edit user menu"
              mr={-2}
              variant="ghost"
              shadow="none"
              boxShadow="none"
            >
              <EllipsisVerticalIcon className="h-4 w-4 text-gray-700" />
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<TrashIcon className="h-4" />}
                onClick={() => setShowDeleteModal(!showDeleteModal)}
              >
                <Text fontSize="sm">Delete company</Text>
              </MenuItem>
              <MenuItem
                icon={<ArrowsRightLeftIcon className="h-4" />}
                onClick={() => setShowMergeModal(!showMergeModal)}
              >
                <Text fontSize="sm">Merge company</Text>
              </MenuItem>

              {hasFavouritesEnabled && (
                <FavouritesMenuItem
                  objectType={FavouriteObjectType.Company}
                  appId={Number(appId)}
                  objectId={groupId}
                />
              )}
            </MenuList>
          </Menu>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div>
              <GlobeAltIcon className="size-5 text-gray-600" />
            </div>
            <div className="flex flex-col gap-1">
              {company?.domains?.map((domain) => (
                <div className="truncate text-ellipsis text-sm">{domain}</div>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <UsersIcon className="h-4 text-gray-600" />
            </div>
            <div className="flex flex-col gap-1">
              <Text fontSize="sm" color="gray.700">
                {companyContactsCount} user
                {companyContactsCount === 1 ? "" : "s"}
              </Text>
            </div>
          </div>
          {company.lastSeenAt && (
            <div className="flex items-center gap-2">
              <div>
                <ClockIcon className="h-4 text-gray-600" />
              </div>
              <div className="flex flex-col gap-1">
                <Text fontSize="sm" color="gray.700">
                  Last seen{" "}
                  {moment
                    .utc(company.lastSeenAt)
                    .format("DD MMM, YYYY [at] hh:mm a")}
                </Text>
              </div>
            </div>
          )}
          {company && companyAppObject && (
            <CrmHeader entity={company} appObject={companyAppObject} />
          )}
        </div>
        <DeleteCompanyModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
        <MergeCompanyModal
          isOpen={showMergeModal}
          onClose={() => setShowMergeModal(false)}
        />
      </div>
    </div>
  );
};
