import { ViewInsightContainer } from "modules/ViewInsight/ViewInsightContainer";
import { AIViewInsightContainer } from "modules/ViewInsight/AIViewInsightContainer";
import { IQuery } from "core/types/Query";
import { IView } from "core/models/views";
import {
  IViewInsight,
  useLazyGetViewInsightQuery,
} from "core/models/viewInsights";
import { ViewLocation } from "core/hooks/useViews";
import { REPORT_CONFIGS } from "core/constants/report-configurations";
import { GridItem, Skeleton } from "@chakra-ui/react";
import { useEffect } from "react";
import { GRID_TO_PX } from "./Builder/LayoutContainer";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

export interface IViewInsightContainer {
  location: ViewLocation;
  viewInsight: IViewInsight;
  view?: IView;
  query?: IQuery;
  isAdding?: boolean;
  isAdded?: boolean;
  viewId?: number;
  isBlurred?: boolean;
  isAI?: boolean;
  onSetViewInsight?: (viewInsight: IViewInsight) => void;
  sharingSecretToken?: string;
  skipFetch?: boolean;
  isSlackInsight?: boolean;
  execute?: boolean;
  skip?: boolean;
}

export const ViewInsight: React.FC<IViewInsightContainer> = ({
  location,
  viewInsight,
  view,
  query,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  isAI,
  onSetViewInsight,
  sharingSecretToken,
  skipFetch,
  isSlackInsight,
}) => {
  const [fetchViewInsight, { data, isLoading, isFetching }] =
    useLazyGetViewInsightQuery();

  const _viewInsight = skipFetch ? viewInsight : (data as IViewInsight);

  useEffect(() => {
    if (skipFetch) return;

    fetchViewInsight({
      id: viewInsight.id,
      viewId: viewInsight.viewId,
      appId: viewInsight.appId,
      sharingSecretToken,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsight.id]);

  if (isAI)
    return (
      <GridItem
        key={_viewInsight?.gridWidth}
        rowSpan={_viewInsight?.gridHeight}
        colSpan={_viewInsight?.gridWidth}
        h="315px"
        minW={GRID_TO_PX[_viewInsight?.gridWidth]}
        minH="315px"
      >
        {isLoading || isFetching || !_viewInsight ? (
          <Skeleton borderRadius="lg" minH="full" minW="full" />
        ) : (
          <AIViewInsightContainer
            location={location}
            viewInsight={_viewInsight}
            isAdding={isAdding}
            isAdded={isAdded}
            viewId={viewId}
            isBlurred={isBlurred}
            isAI={isAI}
            isSlackInsight={isSlackInsight}
            query={query}
          />
        )}
      </GridItem>
    );

  return (
    <GridItem
      key={_viewInsight?.gridWidth}
      rowSpan={_viewInsight?.gridHeight}
      colSpan={_viewInsight?.gridWidth}
      h="315px"
      minW={GRID_TO_PX[_viewInsight?.gridWidth]}
      minH="315px"
    >
      {isLoading || isFetching || !_viewInsight ? (
        <Skeleton borderRadius="lg" minH="full" minW="full" />
      ) : (
        <ViewInsightContainer
          location={location}
          viewInsight={_viewInsight}
          isAdding={isAdding}
          isAdded={isAdded}
          viewId={viewId}
          isBlurred={isBlurred}
          onSetViewInsight={onSetViewInsight}
          view={view}
          isSlackInsight={isSlackInsight}
        />
      )}
    </GridItem>
  );
};
