import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppObjectType } from "./appObjects";
import type { SnakeCasedPropertiesDeep } from "type-fest";
import { IPagy } from "core/types/Pagy";
import { CrmEntitiesOperator } from "modules/Settings/Crm/SyncSettings/RecordsDrilldown";

export interface ICrmEntity {
  id: number;
  appId: number;
  entityId: string;
  crmId: string;
  action: string;
  traits: object;
  updatedAt: string;
  state: CrmEntityState;
}
export enum CrmEntityState {
  NotSynced = 0,
  Synced = 1,
  All = 2,
}

export interface ICrmEntitiesTotalCounts {
  synced: number;
  notSynced: number;
  all: number;
}

interface IndexResponse {
  crmEntities: ICrmEntity[];
  pagy: IPagy;
  totalCounts: ICrmEntitiesTotalCounts;
}

export const crmEntitiesApi = createApi({
  baseQuery,
  reducerPath: "crmEntitiesApi",
  tagTypes: ["CrmSyncs"],
  endpoints: (builder) => ({
    getCrmEntities: builder.query<
      IndexResponse,
      {
        appId: number;
        integration: string;
        objectType: AppObjectType;
        page: number;
        state: CrmEntityState;
        crmSyncId?: number;
        time?: number;
        operator?: CrmEntitiesOperator;
      }
    >({
      query: ({
        appId,
        integration,
        objectType,
        page,
        state,
        crmSyncId,
        time,
        operator,
      }) => ({
        url: `/crm_entities`,
        params: humps.decamelizeKeys({
          appId,
          integration,
          objectType,
          page,
          state,
          crmSyncId,
          time,
          operator,
        }),
      }),
      providesTags: ["CrmSyncs"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IndexResponse>,
      ): IndexResponse => humps.camelizeKeys(response) as IndexResponse,
    }),
  }),
});

export const { useGetCrmEntitiesQuery, useLazyGetCrmEntitiesQuery } =
  crmEntitiesApi;
