import { IViewInsight, useUpdateOrderMutation } from "../models/viewInsights";
import { useEffect, useState } from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  DragEndEvent,
} from "@dnd-kit/core";
import { useParams } from "react-router-dom";

const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  const newArray = array.slice();
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0],
  );
  return newArray;
};

export const useReorderViewInsights = ({
  viewInsights,
}: {
  viewInsights: IViewInsight[];
}) => {
  const { appId } = useParams();
  const [reorderableInsights, setReorderableInsights] = useState<
    IViewInsight[]
  >([]);
  const [updateOrder] = useUpdateOrderMutation();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  useEffect(() => {
    const sortedList = [...viewInsights].sort((a, b) => a.order - b.order);
    setReorderableInsights(sortedList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsights]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = reorderableInsights.findIndex(
      (item) => item.id === active.id,
    );
    const newIndex = reorderableInsights.findIndex(
      (item) => item.id === over.id,
    );

    const newOrder = arrayMove(reorderableInsights, oldIndex, newIndex);
    setReorderableInsights(newOrder);

    updateOrder({
      appId: Number(appId),
      viewInsights: newOrder.map((v, i) => ({
        id: v.id,
        order: i,
      })),
    });
  };

  return {
    reorderableInsights,
    handleDragEnd,
    sensors,
  };
};
