import {
  Integration,
  useCreateDataMappingMutation,
  useDeleteDataMappingMutation,
  useGetDataMappingsQuery,
  useUpdateDataMappingMutation,
} from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { IDataMapping } from "./useDataMappings";

export interface IUpdateMappingParams {
  source: string;
  destination: string;
  id: number;
  isEditable?: boolean;
}

export interface IDeleteMappingParams {
  id: number;
  appId: number;
  integration: Integration;
}

export const useDataMapping = ({
  appId,
  integration,
  appObject,
}: {
  appId: number;
  integration: Integration;
  appObject: IAppObject;
}) => {
  const { data } = useGetDataMappingsQuery({
    appId,
    integration,
    objectType: appObject.objectType,
  });

  const [createDataMapping] = useCreateDataMappingMutation();
  const [updateDataMapping] = useUpdateDataMappingMutation();
  const [deleteDataMapping] = useDeleteDataMappingMutation();

  function updateMapping({
    id,
    source,
    destination,
    isEditable,
  }: IUpdateMappingParams) {
    updateDataMapping({
      id,
      appId,
      source,
      destination,
      integration,
      isEditable,
    });
  }

  function deleteMapping({ id, appId, integration }: IDeleteMappingParams) {
    deleteDataMapping({ id, appId, integration });
  }

  return {
    createMapping: createDataMapping,
    updateMapping,
    deleteMapping,
    matchingIdentifierDataMapping: data?.find(
      (dm: IDataMapping) =>
        dm.isMatchingIdentifier &&
        dm.appObject.objectType === appObject.objectType,
    ),
  };
};
