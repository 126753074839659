import { IViewInsight } from "@src/core/models/viewInsights";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { PublicLink } from "./PublicLink";
import { APIContent } from "./ApiContent";
import { CodeBracketIcon, GlobeAltIcon } from "@heroicons/react/24/outline";

export const ShareModal: React.FC<{
  viewInsight: IViewInsight;
  isOpen: boolean;
  onClose: () => void;
}> = ({ viewInsight, isOpen, onClose }) => {
  const isSQL = viewInsight.query !== null;

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center gap-1">
            {isSQL ? (
              <CodeBracketIcon className="inline-block h-5 w-5" />
            ) : (
              <GlobeAltIcon className="inline-block h-5 w-5" />
            )}
            <p className="text-lg font-medium">{isSQL ? "API" : "Share"}</p>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isSQL && <PublicLink viewInsight={viewInsight} />}
          {isSQL && <APIContent viewInsight={viewInsight} />}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button colorScheme="purple" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
