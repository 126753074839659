import moment from "moment";
import { ViewAlertEditorDrawer } from "modules/Groups/Group/ViewAlertEditorDrawer";
import { IAlertWithAlertable } from "core/models/alerts";
import { FREQUENCIES, IAlertStateEnum } from "core/constants/alert";
import SingleGroup from "core/components/Group/SingleGroup";
import { Button, Tooltip, useDisclosure } from "@chakra-ui/react";

export const DigestRow: React.FC<{
  alert: IAlertWithAlertable;
}> = ({ alert }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isEnabled = alert.state === IAlertStateEnum.Enabled;
  const frequency = FREQUENCIES.find((f) => f.value === alert.frequency)?.label;

  return (
    <>
      <tr>
        <td className="flex flex-col whitespace-nowrap py-1 text-sm">
          <SingleGroup
            id={alert.groupId || ""}
            hideHoverBorder={true}
            hideExternalLinkIcon={true}
          />
        </td>
        <Tooltip label={alert.setup.emails} placement="bottom">
          <td className="max-w-[150px] truncate whitespace-nowrap px-3 py-1 text-sm text-gray-900">
            {alert.setup.emails}
          </td>
        </Tooltip>
        <td className="whitespace-nowrap px-3 py-1 text-xs">
          <span
            className={`inline-flex rounded-md px-2 py-1 text-xs font-medium ${isEnabled ? "bg-green-100 text-green-700" : "bg-gray-100 text-gray-700"}`}
          >
            {isEnabled ? "Enabled" : "Disabled"}
          </span>
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {frequency}
        </td>
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-900">
          {moment.utc(alert.lastSentAt).fromNow() || "Never"}
        </td>
        <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <Button colorScheme="purple" variant="ghost" onClick={onOpen}>
            Edit
          </Button>
        </td>
      </tr>
      <ViewAlertEditorDrawer
        isOpen={isOpen}
        onClose={onClose}
        groupId={alert.groupId || ""}
      />
    </>
  );
};
