import { useMauThreshold } from "core/hooks/useMauThreshold";
import useFlag from "core/hooks/useFlag";
import { PAYWALLED } from "core/constants/features";

export const HeaderTextComponent: React.FC = () => {
  const isPaywalled = useFlag(PAYWALLED);
  const { isApproachingLimit, isAboveLimit, isTrialEnded } = useMauThreshold();

  if (isTrialEnded || isPaywalled)
    return <p className="text-3xl font-bold">Your free trial is over</p>;
  if (!(isAboveLimit || isApproachingLimit)) return null;
  return (
    <p className="text-3xl font-bold">
      {isApproachingLimit
        ? `You're about to reach your limit`
        : `You've reached your limit`}
    </p>
  );
};
