import { IViewInsight } from "core/models/viewInsights";
import {
  useGetInsightTokenQuery,
  useRemovePublicAccessMutation,
  useShareInsightMutation,
} from "../models/insights";
import { useEffect, useState } from "react";

export const useShareViewInsight = ({
  viewInsight,
}: {
  viewInsight: IViewInsight;
}) => {
  const [token, setToken] = useState("");
  const [removeInsightPublicAccess] = useRemovePublicAccessMutation();
  const [shareInsight] = useShareInsightMutation();

  const { data, isLoading } = useGetInsightTokenQuery({
    appId: viewInsight.appId,
    reportId: Number(viewInsight.reportId),
    insightType: viewInsight.insightId as number,
  });

  useEffect(() => {
    if (data) setToken(data.token);
  }, [data]);

  const level = viewInsight.level;
  const BASE_URL = import.meta.env.VITE_APP_HOST || "http://localhost:3000";
  const publicUrl = `${BASE_URL}/a/${viewInsight.appId}/report/${viewInsight.reportId}/${viewInsight.insight?.slug}/${token}?insight_id=${viewInsight.insightId}&level=${level}`;

  function onRemoveInsightAccess() {
    removeInsightPublicAccess({
      appId: viewInsight.appId,
      reportId: viewInsight.reportId,
      insightType: viewInsight.insightId,
    }).then((response: any) => {
      setToken("");
    });
  }

  function onShareInsight() {
    shareInsight({
      appId: viewInsight.appId,
      reportId: viewInsight.reportId,
      insightType: viewInsight.insightId,
    }).then((response: any) => setToken(response?.data?.token));
  }

  return {
    token,
    isSharing: Boolean(token),
    isLoading,
    publicUrl,
    onRemoveInsightAccess,
    onShareInsight,
  };
};
