import { IBarItem } from "@src/core/types/BarChart";

export const FunnelTooltip: React.FC<{ item: IBarItem; index: number }> = ({
  item,
  index,
}) => {
  return (
    <div className="flex w-[280px] flex-col p-1">
      <div className="flex  items-center justify-between">
        <p className="text-gray-500">{index !== 0 ? "Converted" : "Entered"}</p>
        <p>{item.conversion.count}</p>
      </div>
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Dropped off</p>
          <p>{item?.dropped?.count}</p>
        </div>
      )}
      <div className="flex items-center justify-between">
        <p className="text-gray-500">Conversion so far</p>
        <p>{item.conversion.percentage}%</p>
      </div>
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Conversion from previous</p>
          <p>{item.conversion.percentageFromPreviousStep}%</p>
        </div>
      )}
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Median time from previous</p>
          <p>{item.medianTimeTaken}</p>
        </div>
      )}
      {index !== 0 && (
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Average time from previous</p>
          <p>{item.averageTimeTaken}</p>
        </div>
      )}
    </div>
  );
};
