import { ICustomTooltipProps as ITooltipProps } from "core/types/CustomTooltip";
import { Box, Stack, Text } from "@chakra-ui/react";

interface IProps extends ITooltipProps {
  hideEventNames?: boolean;
}

export const BarCustomTooltip: React.FC<IProps> = ({
  label,
  payload,
  active,
  hideEventNames = false,
  ...props
}) => {
  if (active && payload && payload.length) {
    // Get the first payload item to access the complete data
    const firstPayload = payload[0].payload;

    // Calculate total count per feature
    const featureTotals = Object.entries(firstPayload)
      .filter(([key]) => key !== "x")
      .map(([key, value]) => ({ name: key, value: value as number }))
      .sort((a, b) => b.value - a.value);

    const totalCount = featureTotals.reduce(
      (sum, feature) => sum + feature.value,
      0,
    );

    return (
      <Box bg="gray.700" px={3} py={2} borderRadius="lg" {...props}>
        <div className="flex items-center gap-x-1">
          <Text fontSize="xs" fontWeight="bold" color="white" mb={2}>
            {totalCount.toLocaleString()} total
          </Text>
          <Text fontSize="xs" color="gray.400" mb={2}>
            in {label}
          </Text>
        </div>
        {featureTotals.map((feature, idx) => {
          const payloadItem = payload.find((p) => p.name === feature.name);
          return (
            <Stack direction="row" mb={1} spacing="3" align="center" key={idx}>
              <div className="flex w-full items-center justify-between gap-2">
                <div className="flex items-center gap-2 text-xs">
                  <Box
                    h="2"
                    w="2"
                    bg={payloadItem?.color}
                    borderRadius="lg"
                  ></Box>
                  <div>
                    <Text fontSize="xs" color="white">
                      {feature.name}
                    </Text>
                    <Text fontSize="xs" color="gray.300" fontWeight="medium">
                      {feature.value.toLocaleString()} events
                      <p className="ml-1 inline-block text-gray-600">
                        {feature.value
                          ? ((feature.value / totalCount) * 100).toFixed(1)
                          : 0}
                        %
                      </p>
                    </Text>
                  </div>
                </div>
              </div>
            </Stack>
          );
        })}
      </Box>
    );
  }
  return null;
};
