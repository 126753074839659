import { Link } from "react-router-dom";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  AdjustmentsHorizontalIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Box, Text } from "@chakra-ui/react";

export const DigestEmptyState: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { groupAppObject } = useAppObjects();

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <div className="flex">
            <UserGroupIcon className="h-7 w-7 text-purple-500" />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Rally your team behind your most important metrics
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Get everyone up to speed with what's happening inside a specific{" "}
              {groupAppObject?.singularName?.toLowerCase()}.
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex">
            <CurrencyDollarIcon className="h-7 w-7 text-purple-500" />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Monitor customer health and engagement
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Stay informed about your most engaged{" "}
              {groupAppObject?.pluralName?.toLowerCase()} and identify those at
              risk of churn before it's too late.
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex">
            <AdjustmentsHorizontalIcon className="h-7 w-7 text-purple-500" />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Stay in control when you get alerted
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Control when to receive alerts. Choose from daily, weekly, or
              monthly.
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex">
            <Cog6ToothIcon className="h-7 w-7 text-purple-500" />
          </div>
          <div className="flex flex-col">
            <Text fontSize="sm" fontWeight="bold">
              Turn on digest for {groupAppObject?.pluralName?.toLowerCase()} you
              want to follow
            </Text>
            <Text fontSize="sm" fontWeight="sm" color="gray.600">
              Go to{" "}
              <Link to={`/a/${appId}/people?level=group`}>
                <Text as="span" color="primary">
                  {groupAppObject?.pluralName?.toLowerCase()}
                </Text>
              </Link>{" "}
              and turn on the digest for a{" "}
              {groupAppObject?.singularName?.toLowerCase()} on their profile.
            </Text>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex w-full items-center justify-center">
          <Box
            borderRadius="lg"
            h="400px"
            w="100%"
            bgImage={`url('/company_digest.png')`}
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="contain"
          />
        </div>
      </div>
    </div>
  );
};
