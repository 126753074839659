import { useParams } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { IViewInsightContainer } from "modules/ViewInsight/ViewInsight";
import { ViewInsightCard } from "modules/ViewInsight/CardContainer";
import { GraphType, Size } from "core/types/ViewInsight";
import { IQuery } from "core/types/Query";
import { Plan } from "core/types/App";
import {
  useAddViewInsightMutation,
  useDeleteViewInsightMutation,
} from "core/models/viewInsights";
import { useToast } from "core/hooks/useToast";
import { useSqlEditor } from "core/hooks/useSqlEditor";
import { usePaywall } from "core/hooks/usePaywall";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { REPORT_CONFIGS } from "core/constants/report-configurations";
import { SimpleTable } from "core/components/ViewInsight/Graphs/SimpleTable";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { formatValue } from "core/components/AI/Graph/Graph";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

export const AIViewInsightContainer: React.FC<IViewInsightContainer> = ({
  viewInsight,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  isSlackInsight,
  execute,
  skip,
}) => {
  const { plan } = usePaywall();
  const isPaywalled = plan !== Plan.Pro || false;
  const toast = useToast();
  const { appId, viewId: paramViewId, groupId } = useParams();
  const _viewId = viewId ? viewId : paramViewId;
  const [showHighlightDescription, setShowHighlightDescription] =
    useState(false);
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [addViewInsight] = useAddViewInsightMutation();
  const { data, isExecuteFetching, isExecuteLoading } = useSqlEditor({
    viewInsight,
    execute: execute !== undefined ? execute : !isPaywalled,
    skip,
  });

  const filter = isPaywalled || isBlurred ? "blur(10px)" : "none";
  const isTable = viewInsight.graphType === GraphType.Table;
  const isAreaChart = viewInsight.graphType === GraphType.Area;
  const isBarChart = viewInsight.graphType === GraphType.Bar;

  function transformGraphData() {
    if (!data) return [];

    return data?.result?.data?.map((point: { [key: string]: string }) => {
      return {
        x: formatValue(point[(viewInsight.query as IQuery)?.graphXAxis]),
        y: formatValue(point[(viewInsight.query as IQuery)?.graphYAxis]),
      };
    });
  }

  return (
    <ViewInsightCard
      viewInsight={viewInsight}
      isAI
      isAdding={isAdding}
      isAdded={isAdded}
      isBlurred={isBlurred}
      isPaywalled={isPaywalled}
      paywallPlan={Plan.Pro}
      reachedLimit={false}
      paywallFeature="SQL insights"
      isEmpty={!isPaywalled && Boolean((data?.result?.data?.length || 0) === 0)}
      groupId={groupId}
      description={viewInsight.insight.reportName || ""}
      showHighlightDescription={showHighlightDescription}
      title={viewInsight.title || viewInsight.insight.name}
      isLoading={isExecuteFetching || isExecuteLoading}
      timerange={""}
      onMouseEnter={() => setShowHighlightDescription(true)}
      onMouseLeave={() => setShowHighlightDescription(false)}
      isSlackInsight={isSlackInsight}
      onDelete={(e: SyntheticEvent) => {
        e.preventDefault();
        deleteViewInsight({
          id: viewInsight.id,
          appId: viewInsight.appId,
        }).then(() => {
          toast({
            title: "Insight removed",
            description: `Insight "${viewInsight.insight.name}" has been removed from the view.`,
            status: "success",
          });
        });
      }}
      onAdd={(e: SyntheticEvent) => {
        e.preventDefault();
        addViewInsight({
          appId: Number(appId),
          viewId: Number(_viewId),
          reportId: Number(viewInsight.reportId),
          queryId: Number(viewInsight.queryId),
        }).then(() => {
          toast({
            title: "Insight added",
            description: `Insight "${viewInsight.insight.name}" has been added to the view.`,
            status: "success",
          });
        });
      }}
      result={data?.result}
    >
      <div className="flex h-full w-full" style={{ filter: filter }}>
        {isTable && (
          <SimpleTable
            columns={data?.result?.columns || []}
            data={data?.result?.data}
            size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
            useMockData={isPaywalled}
          />
        )}
        {isAreaChart && (
          <SimpleAreaChart
            data={transformGraphData()}
            color={COLOR_NAME_TO_PALETTE[viewInsight.color]}
            size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
            useMockData={isPaywalled}
          />
        )}
        {isBarChart && (
          <SimpleBarChart
            data={transformGraphData()}
            color={COLOR_NAME_TO_PALETTE[viewInsight.color]}
            size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size}
            useMockData={isPaywalled}
          />
        )}
      </div>
    </ViewInsightCard>
  );
};
