import { createSlice } from "@reduxjs/toolkit";
import { IViewInsight } from "../viewInsights";

export interface IDashboardState {
  openedInsight: IViewInsight | null;
  fullModeInsight: IViewInsight | null;
}

export const initialState: IDashboardState = {
  openedInsight: null,
  fullModeInsight: null,
};

export const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    _setOpenedInsight: (state, { payload }) => {
      state.openedInsight = payload;
    },
    _setFullModeInsight: (state, { payload }) => {
      state.fullModeInsight = payload;
    },
  },
});

export default slice.reducer;
