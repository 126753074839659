import { useParams } from "react-router-dom";
import { useViewAlert } from "core/hooks/useViewAlert";
import { IAlertFrequencyEnum, IFrequencyOption } from "core/constants/alert";
import { BellAlertIcon as BellAlertIconSolid } from "@heroicons/react/24/solid";
import { BellAlertIcon as BellAlertIconOutline } from "@heroicons/react/24/outline";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ViewAlertEditorDrawer } from "./ViewAlertEditorDrawer";

export const FREQUENCIES: IFrequencyOption[] = [
  {
    value: IAlertFrequencyEnum.Daily,
    label: "Daily",
    description: "Once a day, first thing in the morning.",
  },
  {
    value: IAlertFrequencyEnum.Weekly,
    label: "Weekly",
    description: "Every Monday morning",
  },
  {
    value: IAlertFrequencyEnum.Monthly,
    label: "Monthly",
    description: "The first day of every month",
  },
];

export const ViewAlertButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { groupId: encodedGroupId } = useParams<{
    groupId: string;
  }>() as {
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { isEnabled } = useViewAlert({ groupId });

  return (
    <>
      <Button mr={-2} variant="ghost" size="xs" onClick={onOpen}>
        {isEnabled ? (
          <BellAlertIconSolid className="h-5 w-5 text-purple-500" />
        ) : (
          <BellAlertIconOutline className="h-5 w-5 text-gray-700" />
        )}
      </Button>
      <ViewAlertEditorDrawer
        isOpen={isOpen}
        onClose={onClose}
        groupId={groupId}
      />
    </>
  );
};
