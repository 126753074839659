import { useNavigate, useParams } from "react-router-dom";
import { HUMANIZED_FREQUENCY } from "core/types/Crm";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { useCrmSyncs } from "core/hooks/useCrmSyncs";
import { LoadingListItem } from "core/components/List/private/LoadingItem";
import { Button, ComponentDefaultProps, useDisclosure } from "@chakra-ui/react";
import { RecordsDrilldown } from "./RecordsDrilldown";
import { LastSyncStatusDot } from "./LastSyncStatusDot";
import { cx } from "helpers/cx";

interface ICrmSyncSettingsRow extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
  comingSoon?: boolean;
}

export const CrmSyncSettingsRow: React.FC<ICrmSyncSettingsRow> = ({
  appObject,
  integration,
  comingSoon = false,
}) => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { crmSyncSetting } = useCrmSyncSettings({
    integration,
    objectType: appObject.objectType,
  });
  const { lastSyncedAt, lastSyncStatus } = useCrmSyncs({
    integration,
    objectType: appObject.objectType,
  });

  if (!crmSyncSetting) {
    return (
      <tr>
        <td colSpan={8}>
          <LoadingListItem hideAvatar={true} />
        </td>
      </tr>
    );
  }

  return (
    <tr
      className="w-full cursor-pointer"
      onClick={(e) => {
        if (comingSoon) return;

        navigate(
          `/a/${appId}/integrations/${integration}/${appObject.objectType}`,
        );
      }}
      key={appObject.objectType}
    >
      <td className="w-[50px] items-center py-3 pl-4 pr-3">
        <LastSyncStatusDot status={lastSyncStatus} />
      </td>
      <td
        className={cx(
          "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium  sm:pl-0",
          comingSoon ? "text-gray-600" : "text-gray-900",
        )}
      >
        <div className="flex flex-row items-center gap-x-2">
          <div className="flex flex-row items-center gap-1">
            {appObject.pluralName}
          </div>
          {comingSoon && (
            <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0 text-[11px] font-medium text-blue-600">
              Coming Soon
            </span>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        {crmSyncSetting?.createEnabled ? (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-0.5 text-[11px] font-medium text-green-700">
            Enabled
          </span>
        ) : (
          <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-0.5 text-[11px] font-medium text-gray-600">
            Disabled
          </span>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        {crmSyncSetting?.updateEnabled ? (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-0.5 text-[11px] font-medium text-green-700">
            Enabled
          </span>
        ) : (
          <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-0.5 text-[11px] font-medium text-gray-600">
            Disabled
          </span>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-600">
        {HUMANIZED_FREQUENCY[crmSyncSetting.frequency]}
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-600">
        {lastSyncedAt || "Never synced"}
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-600">
        <Button
          isDisabled={crmSyncSetting.objectsSyncedCount === 0}
          colorScheme="purple"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          {crmSyncSetting.objectsSyncedCount.toLocaleString()} {` `}
          {appObject.pluralName.toLowerCase()}
        </Button>
      </td>
      {!comingSoon && (
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-600">
          <p
            onClick={(e) =>
              navigate(
                `/a/${appId}/integrations/${integration}/${appObject.objectType}`,
              )
            }
            className="font-medium text-purple-500"
          >
            Edit
          </p>
        </td>
      )}
      {crmSyncSetting.objectsSyncedCount > 0 && (
        <RecordsDrilldown
          appObject={appObject}
          integration={integration}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </tr>
  );
};
