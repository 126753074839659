import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SalesforceWorkspaceData } from "modules/Settings/Integrations/Salesforce/WorkspaceData";
import { SalesforceUserData } from "modules/Settings/Integrations/Salesforce/UserData";
import { SalesforceCompanyData } from "modules/Settings/Integrations/Salesforce/CompanyData";
import { UserData } from "modules/Settings/Integrations/Hubspot/UserData";
import { GroupData } from "modules/Settings/Integrations/Hubspot/GroupData";
import { CompanyData } from "modules/Settings/Integrations/Hubspot/CompanyData";
import { WorkspaceData } from "modules/Settings/Integrations/Attio/WorkspaceData";
import { CompanyData as AttioCompanyData } from "modules/Settings/Integrations/Attio/CompanyData";
import { AttioUserData } from "modules/Settings/Integrations/Attio/AttioUserData";
import { Frequency } from "core/types/Crm";
import { IAudienceFilters } from "core/types/Audience";
import { Plan } from "core/types/App";
import { AudienceCountPreview } from "core/modules/reports/setup/Audience/CountPreview";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { Integration, integrationNames } from "core/models/dataMappings";
import { ICrmSyncSetting } from "core/models/crmSyncSettings";
import {
  useGetAudienceQuery,
  useUpdateAudienceMutation,
} from "core/models/audiences";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import useFlag from "core/hooks/useFlag";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import { useAppObjects } from "core/hooks/useAppObjects";
import { AND } from "core/constants/report-setup";
import {
  ATTIO,
  FIVE_MINUTE_CRM_SYNC,
  HUBSPOT,
  SALESFORCE,
} from "core/constants/features";
import { PaywallPopover } from "core/components/Paywall/Popover";
import {
  Button,
  ComponentDefaultProps,
  Divider,
  Select,
  Switch,
  Tooltip,
} from "@chakra-ui/react";

interface ICrmSyncSettingProps extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
  crmSyncSetting: ICrmSyncSetting;
}

const OBJECT_TYPE_TO_MAPPINGS_COMPONENT = {
  [Integration.Hubspot]: {
    [AppObjectType.User]: UserData,
    [AppObjectType.Company]: CompanyData,
    [AppObjectType.Group]: GroupData,
  },
  [Integration.Attio]: {
    [AppObjectType.User]: AttioUserData,
    [AppObjectType.Company]: AttioCompanyData,
    [AppObjectType.Group]: WorkspaceData,
  },
  [Integration.Salesforce]: {
    [AppObjectType.User]: SalesforceUserData,
    [AppObjectType.Company]: SalesforceCompanyData,
    [AppObjectType.Group]: SalesforceWorkspaceData,
  },
};

const ASSOCIATE_OBJECTS = {
  [Integration.Hubspot]: {},
  [Integration.Attio]: {
    [AppObjectType.User]: {
      object: "People",
      property: "email",
    },
    [AppObjectType.Group]: {
      object: "Companies",
      property: "domain",
    },
  },
  [Integration.Salesforce]: {},
} as {
  [integration in Integration]: {
    [objectType in AppObjectType]: {
      object: string;
      property: string;
    };
  };
};

export const CrmSettings: React.FC<ICrmSyncSettingProps> = ({
  appObject,
  integration,
  crmSyncSetting,
}) => {
  const { appId } = useParams();
  const { groupAppObject, companyAppObject } = useAppObjects();
  const { data: audience } = useGetAudienceQuery({
    appId: Number(appId),
    audienceId: crmSyncSetting.audience.id as number,
  });
  const [updateAudience, { isLoading }] = useUpdateAudienceMutation();
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>({
    filterGroups: [],
    joinOperator: AND,
  });
  const hasFiveMinuteSyncEnabled = useFlag(FIVE_MINUTE_CRM_SYNC);

  useEffect(() => {
    if (!audience) return;

    setAudienceFilters({
      filterGroups: audience.filterGroups,
      joinOperator: audience.joinOperator,
    });
  }, [audience]);

  function onSaveAudience() {
    if (!audience) return;

    updateAudience({
      audienceId: Number(audience.id),
      appId: Number(appId),
      audienceFilters,
    });
  }

  const {
    groupCrmSyncSettings,
    companyCrmSyncSettings,
    onUpdateCrmSyncSetting,
  } = useCrmSyncSettings({
    integration,
    objectType: appObject.objectType,
  });

  const MappingComponent =
    OBJECT_TYPE_TO_MAPPINGS_COMPONENT[integration as Integration]?.[
      appObject.objectType
    ];
  const [frequency, setFrequency] = useState<Frequency | undefined>(
    crmSyncSetting?.frequency,
  );
  const hasHubspotEnabled = useFlag(HUBSPOT);
  const hasAttioEnabled = useFlag(ATTIO);
  const hasSalesforceEnabled = useFlag(SALESFORCE);
  const isEnabled =
    integration === Integration.Hubspot
      ? hasHubspotEnabled
      : integration === Integration.Attio
        ? hasAttioEnabled
        : hasSalesforceEnabled;

  const integrationName = integrationNames[integration];
  const isAudienceUpdated =
    JSON.stringify(audienceFilters) !==
    JSON.stringify({
      filterGroups: audience?.filterGroups,
      joinOperator: audience?.joinOperator,
    });

  useEffect(() => {
    if (crmSyncSetting?.frequency !== undefined && frequency === undefined)
      setFrequency(crmSyncSetting?.frequency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmSyncSetting?.frequency]);

  if (!crmSyncSetting) return <></>;

  function isDisabled() {
    if (integration === Integration.Attio) return false;

    if (appObject.objectType === AppObjectType.Company)
      return (
        groupCrmSyncSettings?.createEnabled ||
        groupCrmSyncSettings?.updateEnabled
      );
    if (appObject.objectType === AppObjectType.Group)
      return (
        companyCrmSyncSettings?.createEnabled ||
        companyCrmSyncSettings?.updateEnabled
      );
  }

  function disabledText(): string {
    if (isDisabled())
      return `Disable ${appObject.objectType === AppObjectType.Company ? groupAppObject?.pluralName.toLowerCase() : companyAppObject?.pluralName?.toLowerCase()} to enable ${appObject.pluralName?.toLowerCase()}`;

    return "";
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col gap-3">
        <div>
          <h3 className="text-base font-semibold leading-7 text-black">
            General
          </h3>
        </div>
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm">
                Auto-create {appObject.pluralName.toLocaleLowerCase()}
              </p>
              <p className="text-sm text-gray-500">
                Automatically creates {appObject?.singularName.toLowerCase()} in{" "}
                {integrationName}
              </p>
            </div>

            <Tooltip shouldWrapChildren hasArrow label={disabledText()}>
              <PaywallPopover
                feature={`automatic creation to ${integrationName}`}
                redirect={`settings/integrations/${integration}`}
                overridePaywall={isEnabled}
                plan={Plan.Pro}
              >
                <Switch
                  isChecked={crmSyncSetting?.createEnabled}
                  colorScheme="purple"
                  isDisabled={isDisabled()}
                  onChange={() =>
                    onUpdateCrmSyncSetting(crmSyncSetting?.id, {
                      createEnabled: !crmSyncSetting?.createEnabled,
                    })
                  }
                />
              </PaywallPopover>
            </Tooltip>
          </div>
          <Divider />
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm">
                Auto-update {appObject.pluralName.toLocaleLowerCase()}
              </p>
              <p className="text-sm text-gray-500">
                Automatically updates {appObject?.singularName.toLowerCase()} in{" "}
                {integrationName}
              </p>
            </div>

            <Tooltip shouldWrapChildren hasArrow label={disabledText()}>
              <PaywallPopover
                feature={`automatic updates to ${integrationName}`}
                redirect={`settings/integrations/${integration}`}
                overridePaywall={isEnabled}
                plan={Plan.Pro}
              >
                <Switch
                  isChecked={crmSyncSetting?.updateEnabled}
                  colorScheme="purple"
                  isDisabled={isDisabled()}
                  onChange={() =>
                    onUpdateCrmSyncSetting(crmSyncSetting?.id, {
                      updateEnabled: !crmSyncSetting?.updateEnabled,
                    })
                  }
                />
              </PaywallPopover>
            </Tooltip>
          </div>
          <Divider />
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm">Frequency</p>
              <p className="text-sm text-gray-500">
                How often should we sync to{" "}
                <p className="inline-block capitalize">{integrationName}</p>?
              </p>
            </div>
            <div className="max-w-[200px]">
              <Select
                bg="gray.50"
                border="none"
                value={frequency}
                defaultValue={frequency}
                colorScheme="purple"
                focusBorderColor="purple.500"
                size="sm"
                borderRadius="md"
                onChange={(e) => {
                  const _frequency = Number(e.currentTarget.value) as Frequency;
                  setFrequency(_frequency);
                  onUpdateCrmSyncSetting(crmSyncSetting.id, {
                    frequency: _frequency,
                  });
                }}
              >
                {hasFiveMinuteSyncEnabled && (
                  <option value={Frequency.FIVE_MINUTES}>
                    Every 5 minutes
                  </option>
                )}
                <option value={Frequency.HOURLY}>Hourly</option>
                <option value={Frequency.DAILY}>Daily</option>
              </Select>
            </div>
          </div>
          {ASSOCIATE_OBJECTS[integration][appObject.objectType] && (
            <div className="flex w-full flex-row items-center justify-between">
              <div className="flex flex-col gap-1">
                <p className="text-sm">
                  Associate {appObject.pluralName.toLocaleLowerCase()} to{" "}
                  {integrationName}{" "}
                  {ASSOCIATE_OBJECTS[integration][appObject.objectType].object}
                </p>
                <p className="text-sm text-gray-500">
                  When syncing {appObject.pluralName.toLocaleLowerCase()} to{" "}
                  {integrationName}, we will also associate them to{" "}
                  {ASSOCIATE_OBJECTS[integration][appObject.objectType].object}{" "}
                  by{" "}
                  {
                    ASSOCIATE_OBJECTS[integration][appObject.objectType]
                      .property
                  }{" "}
                </p>
              </div>
              <Switch
                isChecked={crmSyncSetting?.associate}
                colorScheme="purple"
                isDisabled={isDisabled()}
                onChange={() =>
                  onUpdateCrmSyncSetting(crmSyncSetting?.id, {
                    associate: !crmSyncSetting?.associate,
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
      <Divider my={5} />
      <div>
        <div className="mb-5">
          <h3 className="text-base font-semibold leading-7 text-black">
            Audience
          </h3>
          <p className="mt-2 text-sm">
            Which {appObject.pluralName.toLocaleLowerCase()} do you wish to
            sync?
          </p>
          <p className="mt-2 text-sm text-gray-500">
            If you do not add any audience filters, we will sync all{" "}
            {appObject.pluralName.toLocaleLowerCase()}
          </p>
        </div>
        <div className="my-4">
          <AudienceCountPreview
            audienceFilters={audienceFilters}
            showAvatars={false}
          />
        </div>
        <div className="flex w-full items-start justify-between">
          <AudienceFilters
            key={appObject?.slug}
            audienceFilters={audienceFilters}
            setAudienceFilters={setAudienceFilters}
            view={appObject?.slug}
          />
          <Tooltip
            label={
              isAudienceUpdated
                ? "Update the audience"
                : "Audience has not changed"
            }
          >
            <Button
              colorScheme="purple"
              variant="ghost"
              isDisabled={!isAudienceUpdated}
              onClick={onSaveAudience}
              isLoading={isLoading}
            >
              Update
            </Button>
          </Tooltip>
        </div>
      </div>
      <Divider my={5} />
      <div>
        <div className="mb-5">
          <h3 className="text-base font-semibold leading-7 text-black">
            Sync data
          </h3>
        </div>
        <div>
          {appObject && MappingComponent && (
            <MappingComponent appObject={appObject} />
          )}
        </div>
      </div>
    </div>
  );
};
