import humps from "humps";
import { Frequency } from "core/types/Crm";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Integration } from "./dataMappings";
import { IAppObject } from "./appObjects";
import type { SnakeCasedPropertiesDeep } from "type-fest";
import { IAudience } from "core/types/Audience";

export interface ICrmSyncSetting {
  id: number;
  appId: number;
  appObject: IAppObject;
  createEnabled: boolean;
  updateEnabled: boolean;
  frequency: Frequency;
  integration: Integration;
  objectsSyncedCount: number;
  associate: boolean;
  audience: IAudience;
  objectName?: string;
  objectSlug?: string;
  isSetup: boolean;
}

export const crmSyncSettingsApi = createApi({
  baseQuery,
  reducerPath: "crmSyncSettingsApi",
  tagTypes: ["CrmSyncSettings", "Audience"],
  endpoints: (builder) => ({
    getCrmSyncSettings: builder.query<
      ICrmSyncSetting[],
      { appId: number; integration: string }
    >({
      query: ({ appId, integration }) => ({
        url: `/crm_sync_settings`,
        params: humps.decamelizeKeys({ appId, integration }),
      }),
      providesTags: ["CrmSyncSettings"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ICrmSyncSetting[]>,
      ): ICrmSyncSetting[] => humps.camelizeKeys(response) as ICrmSyncSetting[],
    }),
    updateCrmSyncSetting: builder.mutation<
      ICrmSyncSetting,
      {
        id: number;
        appId: number;
        createEnabled?: boolean;
        updateEnabled?: boolean;
        frequency?: Frequency;
        objectSlug?: string;
        objectName?: string;
        associate?: boolean;
      }
    >({
      query: ({
        id,
        appId,
        objectName,
        objectSlug,
        createEnabled,
        updateEnabled,
        frequency,
        associate,
      }) => ({
        url: `/crm_sync_settings/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          id,
          appId,
          objectName,
          objectSlug,
          createEnabled,
          updateEnabled,
          frequency,
          associate,
        }),
      }),
      invalidatesTags: ["CrmSyncSettings"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<ICrmSyncSetting>,
      ): ICrmSyncSetting => humps.camelizeKeys(response) as ICrmSyncSetting,
    }),
  }),
});

export const { useGetCrmSyncSettingsQuery, useUpdateCrmSyncSettingMutation } =
  crmSyncSettingsApi;
