import { IViewInsight } from "core/models/viewInsights";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { Flex } from "@chakra-ui/react";
import { getInsightConfig } from "./ViewInsightContainer";

interface IViewInsightComponent {
  viewInsight: IViewInsight;
  data: any;
  filter?: string;
  opacity?: number;
}

export const ViewInsightComponent: React.FC<IViewInsightComponent> = ({
  viewInsight,
  data,
  filter = "none",
  opacity = undefined,
}) => {
  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId,
  );
  const Component = insightConfig?.view ? (
    insightConfig?.view?.Components &&
    viewInsight.graphType &&
    viewInsight.graphType !== "null" &&
    insightConfig?.view?.Components[
      viewInsight.graphType as keyof typeof insightConfig.view.Components
    ] ? (
      insightConfig?.view?.Components[
        viewInsight.graphType as keyof typeof insightConfig.view.Components
      ]
    ) : (
      insightConfig?.view?.Component
    )
  ) : (
    <></>
  );
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  return (
    <Flex
      pt={2}
      pb={0}
      h="full"
      w="full"
      filter={filter}
      position="relative"
      opacity={opacity}
    >
      <Component
        viewInsight={viewInsight}
        data={data}
        {...data}
        color={color}
        size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth]}
        interval={viewInsight.interval}
        measure={viewInsight.measure}
      />
    </Flex>
  );
};
