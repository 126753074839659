import { useEffect, useState } from "react";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";

export default function TrialStartedBanner() {
  const { trialDaysRemaining } = useCurrentApp();
  const [isVisible, setIsVisible] = useState(() => {
    const bannerVisibility = localStorage.getItem("trialBannerVisibility");
    return bannerVisibility !== "hidden";
  });

  useEffect(() => {
    if (!isVisible) {
      localStorage.setItem("trialBannerVisibility", "hidden");
    }
  }, [isVisible]);

  if (trialDaysRemaining <= 0 || !isVisible) {
    return <></>;
  }

  const hideBanner = () => setIsVisible(false);

  return (
    <div className="flex w-full items-center bg-purple-100 px-5 py-3">
      <div className="flex w-full items-center justify-center gap-3 text-center">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
          <Icon
            iconType="icon"
            name={"trial-step-rocket"}
            color="purple.900"
            fill="none"
            w={4}
            h={4}
          />
        </div>
        <div className="text-sm text-gray-700">
          Your 14 day free trial of June starts today
        </div>
      </div>
      <div
        className="cursor-pointer text-gray-500"
        onClick={hideBanner}
        data-testid="close-banner"
      >
        <Icon iconType="icon" name="close" h={3} w={3} />
      </div>
    </div>
  );
}
