import React from "react";
import { IVerticalBarChartProps } from "core/types/BarChart";
import { medianTimeTakenShortened } from "core/helpers/medianTimeTaken";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { FunnelTooltip } from "./FunnelTooltip";

export interface IHandleBarClickProps {
  index: number;
  humanizedName?: string;
  convertedCount?: number;
  droppedCount?: number;
  showConverted: boolean;
}

export const VerticalConversionChart: React.FC<IVerticalBarChartProps> = ({
  barData,
  formattedData,
  unit,
  handleBarClick,
  convertedLabel = "converted",
  droppedLabel = "dropped",
}) => {
  return (
    <Flex direction="column" w="100%" gridGap={5} px={8} py={4}>
      {barData.map(
        ({
          index,
          humanizedName,
          conversionPercentage: _cp,
          droppedCount,
          convertedCount,
          medianTimeTaken,
        }) => {
          const conversionPercentage = Math.round(_cp * 100) / 100;
          const droppedPercentage =
            Math.round((100 - conversionPercentage || 0) * 100) / 100;
          const droppedCountExists = typeof droppedCount === "number";
          return (
            <Flex gridGap={3} align="center" key={index} position="relative">
              <Flex w="5%">
                <Flex w="20px" bg="gray.100" borderRadius="sm">
                  <Text fontSize="sm" mx="auto">
                    {index + 1}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                maxW={droppedCountExists ? "12%" : "25%"}
                w={droppedCountExists ? "12%" : "25%"}
              >
                <Tooltip label={humanizedName} hasArrow placement="top">
                  <Text noOfLines={1} fontSize="sm" fontWeight="semibold">
                    {humanizedName}
                  </Text>
                </Tooltip>
              </Flex>
              <Tooltip
                label={
                  formattedData && (
                    <FunnelTooltip item={formattedData[index]} index={index} />
                  )
                }
                hasArrow
                placement="bottom"
              >
                <Flex
                  borderRadius="md"
                  display="inline-block"
                  direction="row"
                  mx={4}
                  w={droppedCountExists ? "40%" : "60%"}
                  bg="gray.100"
                  onClick={() =>
                    handleBarClick({
                      index,
                      humanizedName,
                      convertedCount,
                      droppedCount,
                      showConverted: false,
                    })
                  }
                  cursor="pointer"
                >
                  <Flex
                    borderRadius="md"
                    bg="purple.500"
                    h="20px"
                    w={`${conversionPercentage}%`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleBarClick({
                        index,
                        humanizedName,
                        convertedCount,
                        droppedCount,
                        showConverted: true,
                      });
                    }}
                    cursor="pointer"
                  ></Flex>
                </Flex>
              </Tooltip>
              <Flex w={droppedCountExists ? "15%" : "30%"} direction="column">
                <Flex>
                  <Text fontWeight="semibold">
                    {conversionPercentage || 0}%
                  </Text>
                </Flex>
                <Flex>
                  <Text
                    fontSize="xs"
                    color="purple.500"
                    fontWeight="semibold"
                    cursor="pointer"
                    noOfLines={1}
                    onClick={() =>
                      handleBarClick({
                        index,
                        humanizedName,
                        convertedCount,
                        droppedCount,
                        showConverted: true,
                      })
                    }
                  >
                    {convertedCount} {unit} {convertedLabel}
                  </Text>
                </Flex>
              </Flex>
              {droppedCountExists && (
                <Flex w="15%" direction="column">
                  <Flex>
                    <Text fontWeight="semibold">{droppedPercentage}%</Text>
                  </Flex>
                  <Flex>
                    <Text
                      fontSize="xs"
                      color="purple.500"
                      fontWeight="semibold"
                      cursor="pointer"
                      noOfLines={1}
                      onClick={() =>
                        handleBarClick({
                          index,
                          humanizedName,
                          convertedCount,
                          droppedCount,
                          showConverted: false,
                        })
                      }
                    >
                      {droppedCount} {unit} {droppedLabel}
                    </Text>
                  </Flex>
                </Flex>
              )}
              {index !== 0 && medianTimeTaken && (
                <Tooltip hasArrow label={`Median time taken`}>
                  <Text
                    cursor="pointer"
                    bg="white"
                    fontSize="xs"
                    borderColor="gray.200"
                    boxShadow="sm"
                    borderRadius="lg"
                    position="absolute"
                    top="-20px"
                    px={1}
                    noOfLines={1}
                  >
                    <TimeIcon mb={0.5} h={2.5} mr={1} />
                    {medianTimeTakenShortened({ medianTimeTaken }) || "n/a"}
                  </Text>
                </Tooltip>
              )}
            </Flex>
          );
        },
      )}
    </Flex>
  );
};
