import { GraphType, Size } from "@src/core/types/ViewInsight";
import { PreviewContainer } from "./PreviewContainer";
import { SetupContainer } from "./SetupContainer";
import { IColorPalette } from "core/design-system/constants/theme/colors";

export const LayoutContainer: React.FC<{
  viewId: number;
  data: any;
  size: Size;
  setSize: (size: Size) => void;
  graphType: GraphType;
  setGraphType: (graphType: GraphType) => void;
  colorPalette: IColorPalette;
  setColorPalette: (colorPalette: IColorPalette) => void;
  xAxis: string;
  setXAxis: (xAxis: string) => void;
  yAxis: string;
  setYAxis: (yAxis: string) => void;
  title: string;
  setTitle: (title: string) => void;
  prompt: string;
  setPrompt: (prompt: string) => void;
  query: string;
  setQuery: (query: string) => void;
  suggestion: string | null;
  isPromptFetching: boolean;
  isExecuteFetching: boolean;
  isPromptLoading: boolean;
  isExecuteLoading: boolean;
  onPrompt: () => void;
  onExecute: () => void;
  onApply: () => void;
  onReject: () => void;
  hasError: boolean;
  showAIAssistant: boolean;
  setShowAIAssistant: (showAIAssistant: boolean) => void;
}> = ({
  viewId,
  data,
  size,
  setSize,
  graphType,
  setGraphType,
  colorPalette,
  setColorPalette,
  xAxis,
  setXAxis,
  yAxis,
  setYAxis,
  title,
  setTitle,
  prompt,
  setPrompt,
  query,
  setQuery,
  suggestion,
  isPromptFetching,
  isExecuteFetching,
  isPromptLoading,
  isExecuteLoading,
  onPrompt,
  onExecute,
  onApply,
  onReject,
  hasError,
  showAIAssistant,
  setShowAIAssistant,
}) => {
  return (
    <div className="flex h-full w-full flex-grow gap-x-4">
      <div className="flex w-2/3 flex-grow flex-col">
        <PreviewContainer
          viewId={viewId}
          data={data}
          size={size}
          setSize={setSize}
          graphType={graphType}
          setGraphType={setGraphType}
          colorPalette={colorPalette}
          setColorPalette={setColorPalette}
          xAxis={xAxis}
          setXAxis={setXAxis}
          yAxis={yAxis}
          setYAxis={setYAxis}
          title={title}
          setTitle={setTitle}
          isLoadingData={isExecuteFetching || isExecuteLoading}
        />
      </div>
      <div className="flex w-1/3 flex-grow flex-col">
        <div className="flex flex-grow flex-col">
          <SetupContainer
            prompt={prompt}
            setPrompt={setPrompt}
            query={query}
            setQuery={setQuery}
            suggestion={suggestion}
            isPromptFetching={isPromptFetching}
            isExecuteFetching={isExecuteFetching}
            isPromptLoading={isPromptLoading}
            isExecuteLoading={isExecuteLoading}
            onPrompt={onPrompt}
            onExecute={onExecute}
            onApply={onApply}
            onReject={onReject}
            hasError={hasError}
            showAIAssistant={showAIAssistant}
            setShowAIAssistant={setShowAIAssistant}
          />
        </div>
      </div>
    </div>
  );
};
