import { Divider } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { BoltIcon } from "@heroicons/react/24/solid";
import {
  Command,
  CommandInput,
  CommandList,
} from "core/design-system/components/Command";
import { TraitOptionsList } from "core/modules/audience/Filters/TraitOptionsList";
import { useTraits } from "core/hooks/useTraits";
import { ITraitKeyResponse } from "core/models/traits";
import { TraitLevel } from "core/modules/audience/Filters/TraitMenu";
import { IPercentageTraitConfig, ITraitWithConfig } from "core/types/Trait";
import { useEffect, useState } from "react";
import { DIVIDER, FilterType } from "core/constants/filters";
import { useAppObjects } from "core/hooks/useAppObjects";

const TraitList: React.FC<{
  traits: ITraitKeyResponse[];
  onSelectTrait: (trait: ITraitKeyResponse) => void;
  level: TraitLevel;
}> = ({ traits, onSelectTrait, level }) => {
  const [searchTrait, setSearchTrait] = useState<string>("");

  const { groupAppObject, companyAppObject } = useAppObjects();
  const filterTypeNames: { [x: number]: string } = {
    [FilterType.GroupFilter]: groupAppObject?.singularName || "Group",
    [FilterType.CompanyFilter]: companyAppObject?.singularName || "Company",
  };

  const companyTraitDivider = {
    trait: "Company traits",
    filterType: DIVIDER,
    valueCount: 0,
  };
  const groupTraitDivider = {
    trait: `${filterTypeNames[FilterType.GroupFilter]} traits`,
    filterType: DIVIDER,
    valueCount: 0,
  };
  const divider =
    level === TraitLevel.COMPANY_LEVEL
      ? companyTraitDivider
      : groupTraitDivider;

  return (
    <Command className="max-h-90 w-full min-w-[200px] max-w-[250px]">
      <CommandInput
        placeholder="Search filter..."
        value={searchTrait}
        onChange={(e) => {
          setSearchTrait(e.target.value);
        }}
      />
      <CommandList data-testid={`audience-trait-options`}>
        <TraitOptionsList
          traits={[divider, ...traits]}
          searchTrait={searchTrait}
          onAddTraitFilter={() => {}}
          onAddEventFilter={() => {}}
          isLoading={false}
          filterTypeNames={[]}
          isEventFilterDisabled={true}
          onSelectTrait={onSelectTrait}
        />
      </CommandList>
    </Command>
  );
};

export const Percentage: React.FC<{
  trait: ITraitWithConfig;
  onChange: (config: IPercentageTraitConfig) => void;
}> = ({ trait, onChange }) => {
  const { companyTraits, groupTraits } = useTraits();
  const traits =
    trait.level === TraitLevel.GROUP_LEVEL
      ? groupTraits
      : trait.level === TraitLevel.COMPANY_LEVEL
        ? companyTraits
        : [];
  const numeratorTraitModel: ITraitKeyResponse | null =
    traits.find((t) => t.trait === trait.config.numeratorTrait) || null;
  const denominatorTraitModel: ITraitKeyResponse | null =
    traits.find((t) => t.trait === trait.config.denominatorTrait) || null;

  const [numeratorTrait, setNumeratorTrait] =
    useState<ITraitKeyResponse | null>(numeratorTraitModel);
  const [denominatorTrait, setDenominatorTrait] =
    useState<ITraitKeyResponse | null>(denominatorTraitModel);

  useEffect(() => {
    setNumeratorTrait(numeratorTraitModel);
    setDenominatorTrait(denominatorTraitModel);
  }, [numeratorTraitModel, denominatorTraitModel]);

  useEffect(() => {
    setDenominatorTrait(null);
    setNumeratorTrait(null);
  }, [trait.level]);

  function onChangeTrait(
    trait: ITraitKeyResponse | null,
    type: "numerator" | "denominator",
  ) {
    if (type === "numerator") {
      setNumeratorTrait(trait);
      onChange({
        numeratorTrait: trait?.trait || null,
        denominatorTrait: denominatorTrait?.trait || null,
      });
    } else {
      setDenominatorTrait(trait);
      onChange({
        numeratorTrait: numeratorTrait?.trait || null,
        denominatorTrait: trait?.trait || null,
      });
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {numeratorTrait ? (
        <div>
          <span className="inline-flex items-center gap-2 rounded-lg bg-gray-50 px-2 py-1 text-sm font-medium text-gray-700">
            {numeratorTrait.isComputed && (
              <BoltIcon className="h-4 w-4 text-purple-500" />
            )}
            {numeratorTrait.trait}
            <XMarkIcon
              className="h-4 w-4 cursor-pointer text-gray-500 hover:text-gray-700"
              onClick={() => onChangeTrait(null, "numerator")}
            />
          </span>
        </div>
      ) : (
        <Popover className="relative">
          <PopoverButton>
            <p className="flex items-center gap-2 text-sm font-medium text-purple-500">
              <PlusIcon className="h-4 w-4" />
              Add trait
            </p>
          </PopoverButton>
          <PopoverPanel>
            <TraitList
              traits={traits}
              level={trait.level}
              onSelectTrait={(trait: ITraitKeyResponse) => {
                onChangeTrait(trait, "numerator");
              }}
            />
          </PopoverPanel>
        </Popover>
      )}
      <p className="flex flex-row items-center gap-2 text-xs font-medium uppercase text-gray-500">
        Over
        <Divider my={2} />
      </p>
      {denominatorTrait ? (
        <div>
          <span className="inline-flex items-center gap-2 rounded-lg bg-gray-50 px-2 py-1 text-sm font-medium text-gray-700">
            {denominatorTrait.isComputed && (
              <BoltIcon className="h-4 w-4 text-purple-500" />
            )}
            {denominatorTrait.trait}
            <XMarkIcon
              className="h-4 w-4 cursor-pointer text-gray-500 hover:text-gray-700"
              onClick={() => onChangeTrait(null, "denominator")}
            />
          </span>
        </div>
      ) : (
        <Popover className="relative">
          <PopoverButton>
            <p className="flex items-center gap-2 text-sm font-medium text-purple-500">
              <PlusIcon className="h-4 w-4" />
              Add trait
            </p>
          </PopoverButton>
          <PopoverPanel>
            <TraitList
              traits={traits}
              level={trait.level}
              onSelectTrait={(trait: ITraitKeyResponse) => {
                onChangeTrait(trait, "denominator");
              }}
            />
          </PopoverPanel>
        </Popover>
      )}
    </div>
  );
};
