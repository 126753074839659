import { Navigate, useNavigate } from "react-router-dom";
import React from "react";
import { InviteTeam } from "modules/Onboarding/InviteTeam";
import { motion } from "framer-motion";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import useFlag from "core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { NEW_ONBOARDING } from "core/constants/features";
import { SDK } from "core/constants/apiKeys";
import {
  Avatar,
  Center,
  Container,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import TrialStartedBanner from "./TrialStartedBanner";
import { SetupCard } from "./SetupCard";
import { OnboardingContainer } from "./OnboardingContainer";
import { AssetsContainer } from "./AssetsContainer";
import { Asset } from "./Asset";

const EXISTING_PRODUCTS = [
  {
    name: "Segment",
    logo: "/logos/segment.svg",
    color: "#ddfee7",
  },
  {
    name: "Amplitude",
    logo: "/logos/amplitude.svg",
    color: "#e7f4ff",
  },
  {
    name: "Mixpanel",
    logo: "/logos/mixpanel.svg",
    color: "purple.100",
  },
  {
    name: "Rudderstack",
    logo: "/logos/rudderstack.svg",
    color: "gray.100",
  },
];

export const ChooseSetup: React.FC = () => {
  const { id: appId, name, logo } = useCurrentApp();
  const navigate = useNavigate();
  const hasNewOnboardingEnabled = useFlag(NEW_ONBOARDING);
  const { updateOnboardingChecklist } = useOnboardingChecklist();
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!hasNewOnboardingEnabled)
    return <Navigate to={`/a/${appId}/getting-started`} replace={true} />;

  return (
    <>
      <TrialStartedBanner />
      <OnboardingContainer>
        <Flex w="50%">
          <Container maxW="xl">
            <Flex direction="column" gap={10} pt={24}>
              <motion.div
                initial={{ opacity: 0, top: -20 }}
                animate={{ opacity: 1, top: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                style={{ width: "100%", position: "relative" }}
              >
                <CompanyJuneLogoIcon h="50px" w="50px" />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, top: -20 }}
                animate={{ opacity: 1, top: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                style={{ width: "100%", position: "relative" }}
              >
                <Text fontSize="3xl">
                  Choose how to get set up and see your product metrics
                </Text>
              </motion.div>
            </Flex>

            <Flex direction="column" gap={5} pt={16}>
              <motion.div
                initial={{ opacity: 0, top: -20 }}
                animate={{ opacity: 1, top: 0 }}
                transition={{ duration: 0.5, delay: 0.9 }}
                style={{ width: "100%", position: "relative" }}
              >
                <SetupCard
                  title="I already have a tool in place"
                  description="Set up June in 2 minutes with our integrations"
                  badge="No code required"
                  onClick={() => {
                    window.analytics.track(
                      "onboarding_already_have_a_tool",
                      {
                        app_id: appId,
                      },
                      { context: { groupId: appId } },
                    );
                    navigate(`/a/${appId}/getting-started`);
                  }}
                  avatars={
                    <div className="isolate flex -space-x-2 overflow-hidden">
                      {EXISTING_PRODUCTS.map((p, i) => (
                        <Tooltip label={p.name} hasArrow placement="top">
                          <Flex
                            bg={`${p.color}`}
                            p={2}
                            borderRadius="full"
                            h={10}
                            w={10}
                            className={`ring-2 ring-white`}
                            zIndex={EXISTING_PRODUCTS.length - i + 10}
                          >
                            <img
                              className={`relative m-auto`}
                              src={p.logo}
                              alt=""
                            />
                          </Flex>
                        </Tooltip>
                      ))}
                    </div>
                  }
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, top: -20 }}
                animate={{ opacity: 1, top: 0 }}
                transition={{ duration: 0.5, delay: 1.2 }}
                style={{ width: "100%", position: "relative" }}
              >
                <SetupCard
                  title="June is my first product analytics tool"
                  description="Set up June with our easy to implement code snippet"
                  badge="5 minutes"
                  badgeColor="purple"
                  onClick={() => {
                    window.analytics.track(
                      "onboarding_june_first_tool",
                      {
                        app_id: appId,
                      },
                      { context: { groupId: appId } },
                    );
                    updateOnboardingChecklist({
                      appId,
                      step: "data_source",
                      value: SDK,
                    }).then(() => {
                      navigate(`/a/${appId}/setup-feature`);
                    });
                  }}
                  avatars={
                    <Flex
                      bg={`purple.50`}
                      p={2}
                      borderRadius="full"
                      h={10}
                      w={10}
                      className={`ring-2 ring-white`}
                    >
                      <img
                        className={`relative m-auto`}
                        src="/logos/june.svg"
                        alt=""
                      />
                    </Flex>
                  }
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, top: -20 }}
                animate={{ opacity: 1, top: 0 }}
                transition={{ duration: 0.5, delay: 1.5 }}
                style={{ width: "100%", position: "relative" }}
              >
                <Center>
                  <p className="w-[320px] text-center text-sm text-gray-600">
                    💡 Don't worry, you can change your mind later. Still not
                    sure?{" "}
                    <p
                      className="inline cursor-pointer text-purple-500"
                      onClick={onOpen}
                    >
                      Invite a teammate
                    </p>
                  </p>
                </Center>
              </motion.div>
            </Flex>
          </Container>
        </Flex>
        <Flex w="50%" position="relative">
          <AssetsContainer>
            <Flex align="center" gap={4}>
              <Flex>
                <Avatar
                  color="white"
                  bg={"purple.500"}
                  size="lg"
                  name={name}
                  src={logo}
                  p={1}
                />
              </Flex>
              <Flex direction="column">
                <Text fontSize="2xl">{name}</Text>
                <Text fontSize="sm" color="gray.600">
                  Your product metrics are waiting for you
                </Text>
              </Flex>
            </Flex>

            <Asset imageUrl="/choose-setup.svg" />
          </AssetsContainer>
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose} size="3xl" data-id-share-modal>
          <ModalOverlay />
          <ModalContent borderRadius="lg" p={10}>
            <InviteTeam page="onboarding_welcome" />
            <ModalCloseButton />
          </ModalContent>
        </Modal>
      </OnboardingContainer>
    </>
  );
};
