import React from "react";
import { Size } from "core/types/ViewInsight";
import { Tooltip } from "@chakra-ui/react";
import { ISerie } from "./ExplorationGraph";
import { Dot } from "../../Milestones/CompletionRateContainer";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";
export const ExplorationLegend: React.FC<{
  series: ISerie[];
  size: Size;
  onHover: (index: string | undefined) => void;
}> = ({ series, size, onHover }) => {
  const LEGEND_SIZE = { [Size.Small]: 1, [Size.Medium]: 4, [Size.Large]: 7 };

  return (
    <div className="flex w-full items-center justify-center px-6">
      <div className="flex w-full items-center justify-center gap-4">
        {series?.slice(0, LEGEND_SIZE[size]).map((serie, index: number) => (
          <Tooltip key={index} label={serie.name} placement="bottom" hasArrow>
            <div
              className="flex items-center"
              onMouseEnter={() => onHover(serie.uuid)}
              onMouseLeave={() => onHover(undefined)}
            >
              <Dot color={serie.color} />
              <p className="max-w-[100px] cursor-pointer truncate text-xs text-gray-700">
                {serie.name}
              </p>
            </div>
          </Tooltip>
        ))}
        {series?.length > LEGEND_SIZE[size] && (
          <Popover trigger="hover">
            <PopoverTrigger>
              <div className="flex items-center">
                <Dot color="gray.400" />
                <p className="truncate text-xs text-gray-700">
                  {series.length - LEGEND_SIZE[size]} more
                </p>
              </div>
            </PopoverTrigger>
            <PopoverContent bg="black" shadow="none" p={1.5} w="fit-content">
              <div className="flex flex-col gap-y-2 p-1">
                {series
                  ?.slice(LEGEND_SIZE[size])
                  .map((serie, index: number) => (
                    <div
                      className="flex items-center"
                      onMouseEnter={() => onHover(serie.uuid)}
                      onMouseLeave={() => onHover(undefined)}
                    >
                      <Dot color={serie.color} />
                      <p className="text-xs text-gray-400">{serie.name}</p>
                    </div>
                  ))}
              </div>
            </PopoverContent>
          </Popover>
        )}
      </div>
    </div>
  );
};
