import PropTypes from "prop-types";
import React, { useState } from "react";
import { pull } from "lodash";
import {
  Box,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  Button,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { cx } from "helpers/cx";

function CohortDropdown({
  cohorts,
  selectedAggregated,
  selectedCohorts,
  onSelectAggregated,
  onSelectCohorts,
}) {
  const [open, setOpen] = useState(false);
  const text = () => {
    let str = "";
    if (!selectedAggregated && !selectedCohorts.length) return "Select Cohorts";
    if (selectedAggregated) str += "Aggregated";
    if (selectedAggregated && selectedCohorts.length > 0) str += " + ";
    if (selectedCohorts.length > 1) str += `${selectedCohorts.length} Cohorts`;
    else if (selectedCohorts.length > 0) str += " 1 Cohort";
    return str;
  };

  return (
    <Menu closeOnSelect={false} closeOnBlur={true} isOpen={open}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            minWidth="220px"
            pl={4}
            pr={2}
            py={4}
            onClick={() => setOpen(!open)}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <p className="text-sm">{text()}</p>
              <ChevronDownIcon
                className={cx(
                  "h-3 w-3",
                  isOpen ? "text-gray-800" : "text-gray-600",
                )}
              />
            </Flex>
          </MenuButton>
          <MenuList minWidth="220px" py={2}>
            <div className="flex items-center px-4 py-1">
              <Checkbox
                colorScheme="purple"
                size="md"
                isChecked={selectedAggregated}
                onChange={(e) => onSelectAggregated(e.target.checked)}
              >
                <p className="px-1 text-sm">Aggregated</p>
              </Checkbox>
            </div>
            <MenuDivider />
            <Box maxHeight="400px" overflow="auto">
              {cohorts.map((cohort, idx) => (
                <div
                  className="flex items-center rounded-md px-4 py-1 hover:bg-gray-100"
                  key={idx}
                >
                  <Checkbox
                    colorScheme="purple"
                    size="md"
                    isChecked={selectedCohorts.includes(cohort)}
                    onChange={() =>
                      selectedCohorts.includes(cohort)
                        ? onSelectCohorts([...pull(selectedCohorts, cohort)])
                        : onSelectCohorts([...selectedCohorts, cohort])
                    }
                  >
                    <p className="px-1 text-sm">{cohort}</p>
                  </Checkbox>
                </div>
              ))}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

CohortDropdown.propTypes = {
  cohorts: PropTypes.arrayOf(PropTypes.string),
  selectedAggregated: PropTypes.bool,
  selectedCohorts: PropTypes.arrayOf(PropTypes.string),
  onSelectAggregated: PropTypes.func,
  onSelectCohorts: PropTypes.func,
};

export default CohortDropdown;
