import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import {
  CommandLineIcon,
  PresentationChartLineIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";

export const AddMenu: React.FC<{
  onAddInsightClick: () => void;
  onAddSQLClick: () => void;
}> = ({ onAddInsightClick, onAddSQLClick }) => {
  return (
    <Menu>
      <MenuButton
        leftIcon={<SquaresPlusIcon className="h-4 w-4" />}
        variant="ghost"
        as={Button}
      >
        Add
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<PresentationChartLineIcon className="h-4 w-4" />}
          onClick={onAddInsightClick}
          as={Button}
          variant="ghost"
        >
          Add graph
        </MenuItem>
        <MenuItem
          icon={<CommandLineIcon className="h-4 w-4" />}
          onClick={onAddSQLClick}
          as={Button}
          rightIcon={
            <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
              New
            </span>
          }
          variant="ghost"
        >
          Add SQL
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
