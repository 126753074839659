import { useEffect, useState } from "react";
import { EMPTY_FILTER_GROUPS } from "helpers/filters";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { useLazyGetContactsQuery } from "core/models/contacts";
import { useQueryParam } from "core/hooks/useQueryParam";
import usePagination from "core/hooks/usePagination";
import { useListConfig } from "core/hooks/useListConfig";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";

export function useGroupContactsList({
  groupId,
  audience,
}: {
  groupId: string;
  audience?: IAudience;
}) {
  const { id: appId } = useCurrentApp();
  const query = useQueryParam("query");
  const { userAppObject } = useAppObjects();
  const skipPagination = true;
  const skipOptimization = true;
  const [sortBy, setSortBy] = useState("Last seen");
  const [sortOrder, setSortOrder] = useState("DESC");

  const { listConfig } = useListConfig(userAppObject?.objectType);

  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>(
    audience || EMPTY_FILTER_GROUPS,
  );

  const [searchQuery, setSearchQuery] = useState(query || "");
  const { currentPage, previousPage, nextPage, setPage } = usePagination({
    searchQuery,
  });

  const [
    getContacts,
    { data: contactsData, isFetching: isContactsLoading, error: contactsError },
  ] = useLazyGetContactsQuery();

  const getParams = {
    appId,
    page: currentPage,
    audienceFilters,
    groupId,
    searchQuery,
    skipPagination,
    skipOptimization,
    sortBy,
    sortOrder,
  };

  useEffect(() => {
    if (!userAppObject) return;

    getContacts(getParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appId,
    userAppObject,
    audienceFilters,
    searchQuery,
    currentPage,
    sortBy,
    sortOrder,
  ]);

  function onSetAudienceFilters(filters: IAudienceFilters) {
    setAudienceFilters(filters);
    setPage(1);
  }

  return {
    userAppObject,
    listConfig,
    audienceFilters,
    onSetAudienceFilters,
    searchQuery,
    setSearchQuery,
    setPage,
    pagy: contactsData?.pagy,
    contacts: contactsData?.contacts,
    isLoading: isContactsLoading,
    error: contactsError,
    skipPagination,
    skipOptimization,
    currentPage,
    previousPage,
    nextPage,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  };
}
