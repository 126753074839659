import React from "react";
import { motion } from "framer-motion";
import { ComponentDefaultProps, Flex } from "@chakra-ui/react";

interface IAssetsContainer extends ComponentDefaultProps {
  delay?: number;
  name?: string;
  logo?: string;
}

export const AssetsContainer: React.FC<IAssetsContainer> = ({
  children,
  delay = 2,
  name,
  logo,
  ...props
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, right: -100 }}
      animate={{ opacity: 1, right: 0 }}
      transition={{ duration: 0.5, delay: delay }}
      style={{
        width: "100%",
        height: "calc(100vh - 100px)",
        position: "relative",
        right: "-100px",
        marginTop: "100px",
        overflow: "hidden",
      }}
    >
      <Flex
        position="absolute"
        width="full"
        maxW="full"
        maxH="full"
        height="full"
        borderTop="1px solid"
        borderLeft="1px solid"
        borderTopLeftRadius="2xl"
        borderColor="gray.200"
        top="0"
        right="0"
        {...props}
      >
        <Flex
          position="absolute"
          top="-1px"
          left="-1px"
          w="60px"
          h="full"
          maxH="full"
          bg="white"
          zIndex="docked"
          border="1px solid"
          borderColor="gray.200"
          borderTopLeftRadius="2xl"
        ></Flex>
        <Flex
          direction="column"
          pl="120px"
          borderTopLeftRadius="2xl"
          w="full"
          h="full"
          maxH="full"
          maxW="full"
          overflow="hidden"
          bgGradient="linear(to-b, white, gray.100)"
          pt={20}
        >
          {children}
        </Flex>
      </Flex>
    </motion.div>
  );
};
