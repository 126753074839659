import React from "react";
import { Size } from "core/types/ViewInsight";
import { IBarData } from "core/types/BarChart";
import { IViewInsight } from "core/models/viewInsights";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { VStack } from "@chakra-ui/react";
import { FunnelStep } from "./FunnelStep";

export const FunnelOverviewGraph: React.FC<{
  viewInsight: IViewInsight;
  data: IBarData[];
  color?: IColorPalette;
  size?: Size;
}> = ({ viewInsight, data, color = colors.purple, size = Size.Small }) => {
  const formattedData = data.map((item) => {
    return {
      index: item.index,
      label: item.humanizedName,
      dropped: {
        onClick: () => {},
        count: item.droppedCount,
        unit: viewInsight?.appObject?.singularName.toLowerCase() || "",
      },
      conversion: {
        onClick: () => {},
        count: item.convertedCount,
        unit: viewInsight?.appObject?.singularName.toLowerCase() || "",
        percentage: item.conversionPercentage,
        percentageFromPreviousStep: item.conversionPercentageFromPreviousStep,
        breakdown: item?.breakdown,
      },
      medianTimeTaken: item.medianTimeTaken || "",
      averageTimeTaken: item.averageTimeTaken || "",
      barItems: data.length,
    };
  });

  return (
    <VStack data-testid="funnel-body" mt={2} w="full" spacing={0}>
      {formattedData?.map(
        (barItem, index) =>
          barItem && (
            <FunnelStep key={index} item={barItem} color={color} size={size} />
          ),
      )}
    </VStack>
  );
};
