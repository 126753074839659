import moment from "moment";
import { EditedByUser } from "modules/Report/Setup/EditedByUser";
import { cx } from "helpers/cx";
import { AnimatePresence, motion } from "framer-motion";
import {
  ITemplateConfig,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { MultiEventPicker } from "core/modules/reports/setup/Editor/Event";
import EnumPicker from "core/modules/reports/setup/Editor/Enum";
import { DynamicMultiEventPicker } from "core/modules/reports/setup/Editor/DynamicEvent";
import ConversionWindowPicker from "core/modules/reports/setup/Editor/ConversionWindowPicker";
import BreakdownPicker from "core/modules/reports/setup/Editor/BreakdownPicker";
import AudiencePicker from "core/modules/reports/setup/Editor/Audience";
import { AudienceCountPreview } from "core/modules/reports/setup/Audience/CountPreview";
import { useReportSetup } from "core/hooks/useReportSetup";
import {
  AUDIENCE_TYPE,
  BREAKDOWN_TYPE,
  CONVERSION_WINDOW_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import ReportSettings from "core/components/ReportSettings";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Button, Divider, Tooltip } from "@chakra-ui/react";
import { ReportTypeMenu } from "./ReportTypeMenu";

export const SetupContainer: React.FC<{
  templateConfig: ITemplateConfig;
  report: IReport;
  showSetup: boolean;
}> = ({ report, templateConfig, showSetup }) => {
  const { hasBeenPersisted, updateReportSetup } = useReportSetup();

  function handleSave() {
    updateReportSetup({ setup: templateConfig.setup });
  }

  return (
    <div
      key={`${report.reportType}-${JSON.stringify(report.config)}`}
      className={cx(
        "h-full overflow-y-auto rounded-lg bg-white",
        showSetup ? "border" : "p-0",
      )}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: showSetup ? 1 : 0 }}
          animate={{ opacity: showSetup ? 1 : 0 }}
          transition={{ delay: 0.2 }}
        >
          {showSetup && (
            <motion.div exit={{ opacity: 0 }}>
              <div className="sticky left-0 top-0 z-sticky flex items-center justify-between border-b bg-white px-4 py-3">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">Edit report</p>
                  {report.savedAt && report.lastUpdatedBy ? (
                    <EditedByUser
                      user={report.lastUpdatedBy}
                      timestamp={report.savedAt}
                      action="Updated"
                    />
                  ) : report.createdBy && report.createdAt ? (
                    <EditedByUser
                      user={report.createdBy}
                      timestamp={report.createdAt}
                      action="Created"
                    />
                  ) : (
                    <p className="text-xs text-gray-500">
                      Created {moment.utc(report.createdAt).fromNow()}
                    </p>
                  )}
                </div>

                <Button
                  isDisabled={hasBeenPersisted}
                  onClick={handleSave}
                  colorScheme="purple"
                >
                  Save
                </Button>
              </div>

              <div className="p-4">
                <div className="mb-4">
                  <ReportTypeMenu
                    templateConfig={templateConfig}
                    report={report}
                  />
                  <Divider my={4} />
                </div>
                {templateConfig?.setup?.setupSections.map(
                  (section: ITemplateConfigSetupSection, index: number) => {
                    if (section.type === MULTI_EVENT_TYPE) {
                      if (section.isDynamic) {
                        return (
                          <div key={index}>
                            <p className="mb-4 text-sm font-medium capitalize">
                              {`${section.configKey}s`}
                            </p>
                            <DynamicMultiEventPicker
                              setup={templateConfig.setup}
                              section={section}
                            />
                            <Divider my={4} />
                          </div>
                        );
                      } else {
                        return (
                          <div key={index}>
                            <p className="mb-4 text-sm font-medium">
                              {section.title}
                            </p>
                            <MultiEventPicker
                              setup={templateConfig.setup}
                              section={section}
                            />
                            <Divider my={4} />
                          </div>
                        );
                      }
                    }

                    if (section.type === SINGLE_EVENT_TYPE) {
                      return (
                        <div key={index}>
                          <p className="mb-4 text-sm font-medium">
                            {section.title}
                          </p>
                          <MultiEventPicker
                            setup={templateConfig.setup}
                            section={section}
                            singleEvent
                          />
                          <Divider my={4} />
                        </div>
                      );
                    }

                    if (section.type === AUDIENCE_TYPE) {
                      return (
                        <div key={index}>
                          <p className="mb-4 text-sm font-medium">Audience</p>
                          <div className="mb-4">
                            <AudienceCountPreview
                              audienceFilters={{
                                filterGroups: report.audience?.filterGroups,
                                joinOperator: report.audience?.joinOperator,
                              }}
                              showAvatars={false}
                            />
                          </div>
                          <AudiencePicker section={section} />
                          <Divider my={4} />
                        </div>
                      );
                    }

                    if (section.type === CONVERSION_WINDOW_TYPE) {
                      return (
                        <div key={index}>
                          <div className="mb-4 flex items-center gap-1">
                            <p className="text-sm font-medium">
                              Conversion Window
                            </p>
                            <Tooltip
                              label={section.description}
                              placement="top"
                            >
                              <InformationCircleIcon className="h-4 w-4" />
                            </Tooltip>
                          </div>
                          <ConversionWindowPicker />
                        </div>
                      );
                    }

                    if (section.type === BREAKDOWN_TYPE) {
                      return (
                        <div key={index}>
                          <p className="mb-4 text-sm font-medium">Breakdown</p>
                          <BreakdownPicker />
                        </div>
                      );
                    }

                    if (section.type === ENUM_TYPE) {
                      return (
                        <div key={index}>
                          <p className="mb-4 text-sm font-medium">
                            {section.title}
                          </p>
                          <EnumPicker section={section} />
                          <Divider my={4} />
                        </div>
                      );
                    }

                    if (section.type === SETTINGS_TYPE) {
                      return (
                        <div key={index}>
                          <p className="mb-4 text-sm font-medium">
                            {section.title}
                          </p>
                          <ReportSettings
                            report={report}
                            config={templateConfig}
                          />
                        </div>
                      );
                    }

                    return null;
                  },
                )}
              </div>
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
