import { useNavigate } from "react-router-dom";
import { ExportCsv } from "modules/People/ExportCsv";
import { BulkTrait } from "modules/People/BulkTrait";
import { IPagy } from "core/types/Pagy";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { IAudienceFilters } from "core/types/Audience";
import { IAppObject } from "core/models/appObjects";
import { useTraits } from "core/hooks/useTraits";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { useHubspotSettings } from "core/hooks/useHubspotSettings";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useAttioSettings } from "core/hooks/useAttioSettings";
import { Table } from "core/components/Table/People";
import { IListConfig } from "core/components/List/List.types";
import { SearchBar } from "Components/SearchBar";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Button } from "@chakra-ui/react";

export const ObjectList: React.FC<{
  appObject: IAppObject;
  listConfig: IListConfig;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  entities: IContact[] | IGroup[] | undefined;
  isLoading: boolean;
  pagy: IPagy | undefined;
  currentPage: number;
  previousPage: () => void;
  nextPage: () => void;
  audienceFilters: IAudienceFilters;
  onSetAudienceFilters?: (audienceFilters: IAudienceFilters) => void;
  showSearchBar?: boolean;
  showAddTraitButton?: boolean;
  showExportCsvButton?: boolean;
  skipPagination?: boolean;
  sortBy: string;
  sortOrder: string;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
}> = ({
  appObject,
  searchQuery,
  setSearchQuery,
  entities,
  isLoading,
  pagy,
  nextPage,
  currentPage,
  previousPage,
  audienceFilters,
  onSetAudienceFilters,
  showSearchBar = true,
  showAddTraitButton = true,
  showExportCsvButton = true,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) => {
  const navigate = useNavigate();
  const { id: appId } = useCurrentApp();
  const {
    tableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    updateTraitConfigOrder,
  } = useTableTraitConfig(appObject.objectType);
  const { traits } = useTraits(appObject.objectType);
  const { hasHubspotInstalled } = useHubspotSettings();
  const { hasAttioInstalled } = useAttioSettings();

  function onClearFilters() {
    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups = [];
    onSetAudienceFilters && onSetAudienceFilters(newAudienceFilters);
  }

  function onSyncToCrm() {
    if (hasAttioInstalled) {
      navigate(`/a/${appId}/integrations/attio/${appObject.objectType}`);
    } else if (hasHubspotInstalled) {
      navigate(`/a/${appId}/integrations/hubspot/${appObject.objectType}`);
    } else {
      navigate(`/a/${appId}/integrations/attio`);
    }
  }

  return (
    <div
      data-id-groups-list-container
      className="flex min-h-[500px] w-full flex-col justify-start"
    >
      <div className="flex items-center gap-2">
        {showSearchBar && (
          <SearchBar
            my={3}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder={`Search ${appObject.pluralName.toLocaleLowerCase()} by name...`}
          />
        )}
        {showAddTraitButton && (
          <BulkTrait
            appObject={appObject}
            audienceFilters={audienceFilters}
            searchQuery={searchQuery}
          />
        )}
        {showExportCsvButton && entities && (
          <ExportCsv
            appObject={appObject}
            audienceFilters={audienceFilters}
            searchQuery={searchQuery}
          />
        )}
        {showExportCsvButton && (
          <Button onClick={onSyncToCrm} colorScheme="purple">
            <div className="flex px-6">
              <div className="flex items-center gap-2">
                <ArrowPathIcon className="h-4 w-4" />
                <p>Sync to CRM</p>
              </div>
            </div>
          </Button>
        )}
      </div>
      <Table
        key={appObject.objectType}
        data={entities || []}
        appObject={appObject}
        tableTraitConfigs={tableTraitConfigs || []}
        traits={traits || []}
        createTraitConfig={createTraitConfig}
        deleteTraitConfig={deleteTraitConfig}
        updateTraitConfigOrder={updateTraitConfigOrder}
        hasFilters={!!audienceFilters.filterGroups.length}
        onClearFilters={onClearFilters}
        isLoading={isLoading}
        pagination={{
          currentPage,
          previousPage,
          nextPage,
        }}
        pagy={pagy}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
      />
    </div>
  );
};
