import { IPagy } from "core/types/Pagy";
import { IAlertWithAlertable } from "core/models/alerts";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Pagination } from "core/components/List/Pagination";
import { DigestRow } from "./DigestRow";
import { DigestEmptyState } from "./DigestEmptyState";

export const DigestTable: React.FC<{
  alerts: IAlertWithAlertable[];
  setPage: (page: number) => void;
  pagy: IPagy;
}> = ({ alerts, setPage, pagy }) => {
  const { groupAppObject } = useAppObjects();

  if (alerts.length === 0) {
    return <DigestEmptyState />;
  }

  const nextPage = () => {
    setPage(pagy.page + 1);
  };

  const previousPage = () => {
    setPage(pagy.page - 1);
  };

  const pagination = {
    nextPage,
    previousPage,
    currentPage: pagy.page,
  };

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="w-[250px] py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              {groupAppObject?.singularName?.charAt(0).toUpperCase() +
                groupAppObject?.singularName?.slice(1)}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Emails
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              State
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Frequency
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Last sent
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">View objects</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {alerts.map((alert) => (
            <DigestRow key={alert.id} alert={alert} />
          ))}
        </tbody>
      </table>
      <Pagination pagination={pagination} pagy={pagy} resourceName={"alerts"} />
    </>
  );
};
