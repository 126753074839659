import moment from "moment";
import humps from "humps";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { ComputationType } from "core/constants/traits";
import { ChangeIcon } from "core/components/Traits/Trait";
import { Td, Tr } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import Cell from "./Cell";
import { IListRow } from "../List.types";

function ListRow<T extends any>({
  index = 0,
  row,
  columns,
  pagy,
  mutate,
  canAddAdditionalColumns,
  onMouseEnter,
  onMouseLeave,
  onClick,
  tableTraitConfigs,
  isLastRow = false,
}: IListRow<T>) {
  const dataStyle = { borderBottom: isLastRow ? "none" : "" };

  return (
    <Tr
      data-testid="list-row"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      id={String(index)}
      _hover={{ bg: "gray.50" }}
      cursor={onClick ? "pointer" : "default"}
      onClick={() => onClick?.(row)}
    >
      {columns.map((columnConfig, colIndex: number) => {
        const text = row[columnConfig.name];
        if (columnConfig && columnConfig.Component) {
          return (
            <Td
              width={columnConfig.width}
              key={colIndex}
              py={3}
              style={dataStyle}
            >
              <Cell
                name={columnConfig.name}
                rowIndex={index}
                pagy={pagy}
                text={text}
                Component={columnConfig.Component}
                props={row}
                dataKey={columnConfig.dataKey}
                textAlign={columnConfig.align}
                fontSize={columnConfig.fontSize}
                color={columnConfig.color}
                previewMode={columnConfig.previewMode}
                sharingMode={columnConfig.sharingMode}
                additionalProps={columnConfig.additionalProps}
                mutate={mutate}
              />
            </Td>
          );
        }
        return (
          <Td key={colIndex} py={3} style={dataStyle}>
            <Cell
              rowIndex={index}
              pagy={pagy}
              text={text}
              textAlign={columnConfig.align}
              fontSize={columnConfig.fontSize}
              color={columnConfig.color}
              name={columnConfig.name}
            />
          </Td>
        );
      })}
      {canAddAdditionalColumns &&
        Array.isArray(tableTraitConfigs) &&
        tableTraitConfigs.map(
          (tableTraitConfig: ITableTraitConfig, index: number) => {
            let text =
              row?.traits?.[humps.camelize(tableTraitConfig.trait)] || "-";
            const isEventCountChange =
              tableTraitConfig?.computedTrait?.config?.computationType ===
              ComputationType.EventCountChange;
            const isPercentage =
              tableTraitConfig?.computedTrait?.config?.computationType ===
              ComputationType.Percentage;
            const isTimestamp = [
              ComputationType.FirstSeen,
              ComputationType.LastSeen,
            ].includes(
              tableTraitConfig?.computedTrait?.config?.computationType,
            );

            if (text === "true") {
              text = <CheckIcon h={3} color="green.400" />;
            } else if (text === "false") {
              text = <CloseIcon h={2.5} color="red.400" />;
            } else if (isEventCountChange && !isNaN(Number(text))) {
              text = (
                <div className="flex items-center">
                  <ChangeIcon value={text} />
                  {Math.abs(Number(text)).toString()}%
                </div>
              );
            } else if (isTimestamp) {
              const humanReadableDate = new Date(text).toLocaleDateString(
                "en-GB",
                {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                },
              );
              const humanReadableRelativeTime = moment.utc(text).fromNow();
              text = (
                <div className="flex flex-col">
                  <div className="font-sm ">{humanReadableDate}</div>
                  <div className="text-sm text-gray-600">
                    {humanReadableRelativeTime}
                  </div>
                </div>
              );
            } else if (isPercentage && !isNaN(Number(text))) {
              text = `${Math.abs(Number(text)).toString()}%`;
            }

            return (
              <Td key={index} py={3} style={dataStyle}>
                <Cell
                  rowIndex={index}
                  pagy={pagy}
                  text={text}
                  fontSize="sm"
                  name={tableTraitConfig.trait}
                />
              </Td>
            );
          },
        )}
    </Tr>
  );
}

export default ListRow;
