import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Spinner, Tooltip } from "@chakra-ui/react";

export const UseInternalUsersToggle: React.FC<{
  isEnabled: boolean;
  isLoading: boolean;
  onChange: (isEnabled: boolean) => void;
}> = ({ isEnabled, isLoading, onChange }) => {
  return (
    <div className="flex items-center gap-1">
      <div className="flex h-6 items-center">
        {isLoading ? (
          <Spinner
            size="sm"
            color="purple.500"
            thickness="3px"
            emptyColor="gray.200"
          />
        ) : (
          <input
            id="comments"
            name="comments"
            type="checkbox"
            checked={isEnabled}
            onChange={() => onChange(!isEnabled)}
            aria-describedby="comments-description"
            className="h-4 w-4 rounded border-gray-300 text-purple-500 focus:ring-purple-500"
          />
        )}
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="text-gray-900">
          Remove internal users
        </label>
      </div>
      <Tooltip
        label="These are users that have been defined in your workspace settings"
        hasArrow
      >
        <InformationCircleIcon className="h-4 w-4 text-gray-600" />
      </Tooltip>
    </div>
  );
};
