import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AddMenu } from "modules/Views/AddMenu";
import { ViewContainer } from "modules/ViewInsight/ViewInsightsContainer";
import { GettingStartedBanner } from "modules/Home/GettingStartedBanner";
import { AnimatePresence, motion } from "framer-motion";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { AddAudienceMenu } from "core/modules/audience/Filters/AddAudienceMenu";
import { IView, useUpdateViewMutation } from "core/models/views";
import {
  useGetAudienceQuery,
  useUpdateAudienceMutation,
} from "core/models/audiences";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { useAppObjects } from "core/hooks/useAppObjects";
import { TwEditable } from "core/design-system/components/TwEditable";
import { AND, JoinOperatorValue } from "core/constants/report-setup";
import { Shareable } from "core/components/Shareable";
import { PageContainer } from "core/components/PageContainer";
import { EmojiPicker } from "core/components/EmojiPicker";
import {
  AdjustmentsVerticalIcon,
  Squares2X2Icon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  Badge,
  Button,
  ComponentDefaultProps,
  Flex,
  Skeleton,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { ViewMenu } from "./ViewMenu";
import {
  EditingViewProvider,
  useIsEditingView,
} from "core/contexts/EditingViewContext";

export function getFilterCount(audienceFilters: IAudienceFilters) {
  return audienceFilters.filterGroups.reduce((count, group) => {
    return count + group.filters.length;
  }, 0);
}

export const ViewPageContainer: React.FC<ComponentDefaultProps> = ({
  children,
}) => {
  const isEditing = useIsEditingView();
  return (
    <PageContainer
      maxW="1106px"
      h="100vh"
      bgColor={isEditing ? "bg-gray-50" : "bg-white"}
      shadow={!isEditing}
    >
      {children}
    </PageContainer>
  );
};

export const ViewHeader: React.FC<{
  view?: IView;
  showAddModal: boolean;
  setShowAddModal: (show: boolean) => void;
  showSQLModal: boolean;
  setShowSQLModal: (show: boolean) => void;
  audienceFilters: IAudienceFilters;
  showAudienceFilters: boolean;
  setShowAudienceFilters: (show: boolean) => void;
}> = ({
  view,
  showAddModal,
  setShowAddModal,
  showSQLModal,
  setShowSQLModal,
  audienceFilters,
  showAudienceFilters,
  setShowAudienceFilters,
}) => {
  const isEditing = useIsEditingView();
  const { toggleEditDashboard } = useEditDashboard();
  return (
    <div className="flex items-center gap-1">
      <AddMenu
        onAddInsightClick={() => {
          setShowAddModal(!showAddModal);
          toggleEditDashboard();
        }}
        onAddSQLClick={() => {
          setShowSQLModal(!showSQLModal);
          toggleEditDashboard();
        }}
      />

      <Button
        key={isEditing ? "edit" : "done"}
        variant={isEditing ? "solid" : "ghost"}
        colorScheme={isEditing ? "purple" : "gray"}
        onClick={toggleEditDashboard}
        leftIcon={<Squares2X2Icon className="h-4 w-4" />}
      >
        {isEditing ? "Done" : "Edit"}
      </Button>
      {view && <Shareable shareable={view} shareableType="View" />}
      <Button
        variant="ghost"
        leftIcon={<AdjustmentsVerticalIcon className="h-[17px] w-[17px]" />}
        onClick={() => setShowAudienceFilters(!showAudienceFilters)}
      >
        {showAudienceFilters
          ? "Hide audience filters"
          : "Show audience filters"}{" "}
        <Badge
          ml={2}
          variant="subtle"
          colorScheme="purple"
          rounded="md"
          fontSize="xs"
        >
          {getFilterCount(audienceFilters)}
        </Badge>
      </Button>
      {view && <ViewMenu view={view} />}
    </div>
  );
};

export const View: React.FC = () => {
  const { appId, viewId } = useParams();
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const [showAudienceFilters, setShowAudienceFilters] = useState(() => {
    const stored = localStorage.getItem("showAudienceFilters");
    return stored !== null ? JSON.parse(stored) : true;
  });
  const { view, isLoadingViews, isFetchingViews, onFetchView } = useViews({
    location: ViewLocation.Home,
    pinned: false,
  });
  const [audience, setAudience] = useState<IAudience | null>(null);
  const { userAppObject } = useAppObjects();
  const {
    data,
    isFetching: isFetchingAudience,
    isLoading: isLoadingAudience,
  } = useGetAudienceQuery({
    appId: Number(appId),
    audienceId: view?.audience?.id as number,
  });

  const [updateAudience] = useUpdateAudienceMutation();
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>({
    filterGroups: [],
    joinOperator: AND as unknown as JoinOperatorValue,
  });

  const isAudienceUpdated =
    JSON.stringify(audienceFilters) !==
    JSON.stringify({
      filterGroups: view?.audience?.filterGroups,
      joinOperator: view?.audience?.joinOperator,
    });

  const [updateView] = useUpdateViewMutation();

  const onAddAudience = (audience: IAudience) => {
    if (!view) return;

    updateView({
      id: Number(view.id),
      appId: Number(appId),
      audienceId: Number(audience.id),
    });
  };

  const onRemoveAudience = () => {
    if (!view) return;

    setAudience(null);
    setAudienceFilters({
      filterGroups: [],
      joinOperator: AND as unknown as JoinOperatorValue,
    });

    updateView({
      id: Number(view.id),
      appId: Number(appId),
      audienceId: undefined,
    });
  };

  useEffect(() => {
    if (data) setAudience(data);
  }, [data]);

  useEffect(() => {
    if (!audience) return;

    setAudienceFilters({
      filterGroups: audience.filterGroups,
      joinOperator: audience.joinOperator,
    });
  }, [audience]);

  useEffect(() => {
    localStorage.setItem(
      "showAudienceFilters",
      JSON.stringify(showAudienceFilters),
    );
  }, [showAudienceFilters]);

  async function onSaveAudience() {
    if (!audience) return;

    await updateAudience({
      audienceId: Number(audience.id),
      appId: Number(appId),
      audienceFilters,
    });
    await onFetchView();
  }

  function handleEmojiClick(emojiObject: { emoji: string }, viewId: number) {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emojiObject.emoji,
    });
  }

  function onUpdateViewName(title: string) {
    if (view?.id)
      updateView({ id: Number(view.id), appId: Number(appId), name: title });
  }

  return (
    <EditingViewProvider>
      <ViewPageContainer>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <div className="flex">
                <EmojiPicker
                  emoji={view?.emoji ? view?.emoji : "🏠"}
                  handleEmojiClick={(e) =>
                    handleEmojiClick(e, Number(view?.id))
                  }
                  buttonProps={{ size: "normal", px: 2 }}
                  textProps={{ fontSize: "3xl" }}
                />
              </div>
              <div className="flex">
                {view && (
                  <TwEditable
                    key={view.name}
                    className={"text-2xl font-semibold"}
                    inputClassName={"text-2xl font-semibold"}
                    defaultValue={view.name}
                    placeholder={"Untitled view"}
                    fontWidth={14}
                    onSubmit={onUpdateViewName}
                  />
                )}
              </div>
            </div>
          </div>
          <ViewHeader
            view={view}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            showSQLModal={showSQLModal}
            setShowSQLModal={setShowSQLModal}
            audienceFilters={audienceFilters}
            showAudienceFilters={showAudienceFilters}
            setShowAudienceFilters={setShowAudienceFilters}
          />
        </div>
        <div className="my-5">
          <AnimatePresence>
            {showAudienceFilters && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.1, ease: "easeInOut" }}
                className="mt-10 flex"
              >
                <div className="flex w-full justify-between">
                  {isFetchingViews ||
                    (isFetchingAudience && isLoadingAudience && (
                      <Skeleton className="h-10 w-full rounded-md" />
                    ))}
                  {audience?.isSavedAudience ? (
                    <div className="flex">
                      <div className="flex w-full items-center gap-x-2 rounded-md bg-gray-50 pl-3 text-sm">
                        <div>
                          <UserGroupIcon className="h-4 w-4 " />
                        </div>
                        <Tooltip
                          label="This is a saved audience"
                          hasArrow
                          placement="top"
                        >
                          <p className="w-full truncate text-sm font-medium">
                            {audience?.name}
                          </p>
                        </Tooltip>
                        <Tooltip label="Remove audience" hasArrow>
                          <div
                            className="cursor-pointer rounded-md p-2 hover:bg-gray-100"
                            onClick={onRemoveAudience}
                          >
                            <XMarkIcon className="h-4 w-4" />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  ) : (
                    <>
                      <AudienceFilters
                        key={JSON.stringify(audienceFilters)}
                        audienceFilters={audienceFilters}
                        setAudienceFilters={setAudienceFilters}
                        view={userAppObject?.slug}
                        showClearFilters={false}
                      >
                        <AddAudienceMenu
                          isDisabled={getFilterCount(audienceFilters) > 0}
                          onAddAudience={onAddAudience}
                        />
                      </AudienceFilters>
                      <Tooltip
                        label={
                          isAudienceUpdated
                            ? "Update the audience"
                            : "Audience has not changed"
                        }
                        hasArrow
                      >
                        <Button
                          colorScheme="purple"
                          variant="ghost"
                          isDisabled={!isAudienceUpdated}
                          onClick={onSaveAudience}
                          isLoading={isLoadingAudience}
                        >
                          Save
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <Flex my={3} />
        {isLoadingViews && !view && (
          <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
            <Spinner emptyColor="gray.200" color="gray.500" thickness="3px" />
          </div>
        )}
        {view && (
          <ViewContainer
            key={audience?.updatedAt}
            viewId={Number(viewId)}
            view={view}
            location={ViewLocation.Home}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            showSQLModal={showSQLModal}
            setShowSQLModal={setShowSQLModal}
          />
        )}
        <div className="pb-10" />
        {shouldShowGettingStartedBanner && <GettingStartedBanner />}
      </ViewPageContainer>
    </EditingViewProvider>
  );
};
