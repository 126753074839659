import React, { useState } from "react";
import { IBarData, IBarItem } from "core/types/BarChart";
import { medianTimeTakenShortened } from "core/helpers/medianTimeTaken";
import {
  Box,
  Button,
  Divider,
  Flex,
  StyleProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { InfoOutlineIcon, TimeIcon } from "@chakra-ui/icons";
import { FunnelTooltip } from "./FunnelTooltip";
import Popover from "../components/Popover";

export interface IBarItemData {
  name: string;
  value: number;
  count?: number;
}

interface IBarItemProps {
  item: IBarItem;
  index: number;
  barItems: IBarItemData[] | IBarData[];
  infoPopoverBody: React.ReactNode;
}

interface BarChartProps extends StyleProps {
  children: React.ReactNode;
}

const BarChart: React.FC<BarChartProps> = ({ children, ...styleProps }) => {
  return (
    <Flex
      h="400px"
      w="100%"
      pos="relative"
      justifyContent="space-between"
      {...styleProps}
    >
      {children}
    </Flex>
  );
};

const BarItem: React.FC<IBarItemProps> = ({
  item,
  index,
  barItems,
  infoPopoverBody,
}) => {
  const [isIconVisible, setIsIconVisible] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const shouldShowSmallerCTAs = barItems.length > 7;

  return (
    <Flex
      w={`${100 / barItems.length}%`}
      align="center"
      direction="column"
      py={2}
      bg={index % 2 === 0 ? "white" : "#fcfcfc"}
      _hover={{ bg: "gray.50" }}
      pos="relative"
      id="bar-container"
      data-testid="bar-container"
    >
      {item.label && (
        <Flex maxW="100%" px={4}>
          <Tooltip
            hasArrow
            placement="bottom"
            label={item.tooltip || item.label}
          >
            <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>
              {item.label}
            </Text>
          </Tooltip>
        </Flex>
      )}
      <Divider mt={2} />
      <Tooltip
        label={<FunnelTooltip item={item} index={index} />}
        hasArrow
        placement="bottom"
        isDisabled={item.conversion.hideTooltip}
      >
        <div className="flex h-full w-full flex-col items-center">
          {item.dropped && (
            <Flex my={1}>
              <Button
                size="xs"
                onClick={() => {
                  item.dropped!.onClick && item.dropped!.onClick(item);
                }}
                colorScheme="purple"
                variant="ghost"
              >
                <Text maxW="100%" noOfLines={1} fontSize="xs">
                  {shouldShowSmallerCTAs
                    ? `${item.dropped.count} ${item.dropped.unit}`
                    : `${item.dropped.count} ${item.dropped.unit} ${item?.dropped?.label || `dropped`}`}
                </Text>
              </Button>
            </Flex>
          )}
          {/* TODO:@aliceclv refactor to get dropped conversion */}
          {item.conversion && (
            <Box
              cursor="pointer"
              mt={3}
              borderTopRadius="md"
              w="50%"
              bg="purple.100"
              h={`${100 - (item.conversion.percentage || 0)}%`}
              onClick={() => {
                if (isPopoverOpen) return;
                if (item.dropped) {
                  return (
                    item.dropped &&
                    item.dropped.onClick &&
                    item.dropped.onClick(item)
                  );
                }
                item.conversion.onClick && item.conversion.onClick(item);
              }}
              justifyContent="flex-end"
              onMouseEnter={() => setIsIconVisible(true)}
              onMouseLeave={() => setIsIconVisible(false)}
              data-testid={`conversion-bar-chart-dropped-baritem-${index}`}
            >
              {item.conversion.percentage === 0 && (
                <Flex w="100%" h="100%" align="center" justify="center">
                  <Popover
                    trigger="hover"
                    placement="right"
                    onOpen={() => setIsPopoverOpen(true)}
                    onClose={() => setIsPopoverOpen(false)}
                    popoverTrigger={
                      <InfoOutlineIcon
                        w={5}
                        h={5}
                        color="purple.500"
                        display={isIconVisible ? "block" : "none"}
                        data-testid={`conversion-bar-chart-dropped-info-outline-icon-${index}`}
                      />
                    }
                    popoverBody={infoPopoverBody}
                  />
                </Flex>
              )}
            </Box>
          )}
          {item.conversion && (
            <Box
              cursor="pointer"
              mb={3}
              borderTopRadius={index === 0 ? "md" : "none"}
              w="50%"
              bg="purple.500"
              h={`${item.conversion.percentage}%`}
              onClick={() =>
                item.conversion.onClick && item.conversion.onClick(item)
              }
            ></Box>
          )}
          <Flex>
            <Text fontWeight="bold">{item.conversion.percentage || 0}%</Text>
          </Flex>
          <Flex>
            {item.conversion && (
              <Flex>
                <Button
                  size="xs"
                  onClick={() =>
                    item.conversion.onClick && item.conversion.onClick(item)
                  }
                  colorScheme="purple"
                  variant="ghost"
                >
                  <Text maxW="100%" noOfLines={1} fontSize="xs">
                    {shouldShowSmallerCTAs
                      ? `${item.conversion.count} ${item.conversion.unit}`
                      : `${item.conversion.count} ${item.conversion.unit}${" "}
                  ${index === 0 ? "" : item.conversion.label || `converted`}`}
                  </Text>
                </Button>
              </Flex>
            )}
          </Flex>
        </div>
      </Tooltip>
      {index !== 0 && item.medianTimeTaken && (
        <Tooltip hasArrow label={`Median time taken`}>
          <Text
            cursor="pointer"
            bg="white"
            fontSize="xs"
            border="1px solid"
            borderColor="gray.200"
            boxShadow="sm"
            borderRadius="lg"
            pos="absolute"
            left={`-20px`}
            top="45%"
            px={1}
            noOfLines={1}
          >
            <TimeIcon mb={0.5} h={2.5} mr={1} />
            {medianTimeTakenShortened({
              medianTimeTaken: item.medianTimeTaken,
            }) || "n/a"}
          </Text>
        </Tooltip>
      )}
    </Flex>
  );
};

export { BarChart, BarItem };
