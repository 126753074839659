import React, { useEffect } from "react";
import { ReverseMappingRow } from "modules/Integrations/ReverseMappingRow";
import {
  Direction,
  Integration,
  integrationNames,
} from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { useDataMappings } from "core/hooks/useDataMappings";
import { useDataMapping } from "core/hooks/useDataMapping";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useCrmProperties } from "core/hooks/useCrmProperties";
import Icon from "core/design-system/components/Icon";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Button, Divider, Text, Tooltip } from "@chakra-ui/react";

interface IMappingTableProps {
  appObject: IAppObject;
  integration: Integration;
  isLoading: boolean;
}

export const ReverseMappingTable: React.FC<IMappingTableProps> = ({
  appObject,
  integration,
  isLoading,
}) => {
  const { id: appId } = useCurrentApp();
  const singularExtTraitWord =
    integration === Integration.Hubspot ? "property" : "attribute";
  const pluralExtTraitWord =
    integration === Integration.Hubspot ? "properties" : "attributes";
  const integrationName = integrationNames[integration];

  const {
    properties,
    currentProperties,
    page,
    search,
    hasMore,
    setPage,
    setSearch,
    onGetProperties,
  } = useCrmProperties({
    appId,
    integration,
    objectType: appObject.objectType,
  });
  const { reverseDataMappings } = useDataMappings({
    appId,
    integration,
    appObject,
  });

  const { createMapping } = useDataMapping({
    appId,
    integration,
    appObject,
  });

  const addMapping = () => {
    createMapping({
      appId: Number(appId),
      source: "",
      integration,
      destination: "",
      objectType: appObject.objectType,
      direction: Direction.FromIntegration,
    });
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", onGetProperties);

    return () => {
      window.removeEventListener("visibilitychange", onGetProperties);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <p className="text-sm">
          Which <p className="inline-block capitalize">{integrationName}</p>{" "}
          {pluralExtTraitWord} should we sync back to June{" "}
          {appObject.pluralName?.toLowerCase()}?
        </p>
        <p className="my-2 text-sm text-gray-500">
          These are the{" "}
          <p className="inline-block capitalize">{integrationName}</p>{" "}
          {pluralExtTraitWord} which we will sync to June{" "}
          {appObject.pluralName?.toLowerCase()}
        </p>
      </div>
      <div className="mt-6 flex w-full flex-row items-center justify-between">
        <div>
          <p className=" text-sm  text-gray-500">June traits</p>
        </div>
        <div>
          <div className="flex w-full flex-row items-center justify-end gap-1">
            <p className=" text-sm capitalize text-gray-500">
              {integrationName}{" "}
              <Text as="span" textTransform="lowercase">
                {pluralExtTraitWord}
              </Text>
            </p>
            <Tooltip
              hasArrow
              label={`Don't see a ${integrationName} ${singularExtTraitWord}? Create one in ${integrationName}`}
              placement="top"
            >
              <InformationCircleIcon className="h-4 text-gray-500" />
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider my={3} />
      <div className="flex flex-col gap-4">
        {reverseDataMappings.map((mapping, index) => {
          return (
            <ReverseMappingRow
              key={JSON.stringify(mapping) + index}
              dataMappings={reverseDataMappings}
              dataMapping={mapping}
              appObject={appObject}
              integration={integration}
              isLoading={isLoading}
              properties={properties}
              currentProperties={currentProperties}
              page={page}
              search={search}
              hasMore={hasMore}
              setPage={setPage}
              setSearch={setSearch}
            />
          );
        })}
      </div>
      <div className="mt-2 flex">
        <Button
          variant="ghost"
          colorScheme="purple"
          onClick={addMapping}
          leftIcon={<Icon iconType="icon" name="add" h={3} w={3} pb={0.5} />}
        >
          Add mapping
        </Button>
      </div>
    </>
  );
};
