import { useParams } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment";
import { CrmHeader } from "modules/Contacts/Contact/CrmHeader";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";
import { FavouriteObjectType } from "core/models/favourites";
import { useGroup } from "core/hooks/useGroup";
import useFlag from "core/hooks/useFlag";
import { useAppObjects } from "core/hooks/useAppObjects";
import { COMPANY_DIGEST, FAVOURITES } from "core/constants/features";
import {
  ClockIcon,
  IdentificationIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { ViewAlertButton } from "./ViewAlertButton";
import { DeleteGroupModal } from "./DeleteGroupModal";
import { CompanyLogo } from "./CompanyLogo";

export const Header: React.FC<StyleProps> = () => {
  const { groupAppObject } = useAppObjects();
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { group } = useGroup({ id: groupId });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hasFavouritesEnabled = useFlag(FAVOURITES);
  const hasCompanyDigestEnabled = useFlag(COMPANY_DIGEST);

  if (!group || !groupAppObject) {
    return (
      <div className="flex w-full flex-col p-8">
        <div className="mb-6 flex items-center">
          <CompanyLogo size="lg" />
          <div className="ml-4">
            <span className="text-lg font-normal">Unknown</span>
          </div>
        </div>
      </div>
    );
  }

  const groupCount = group.contacts?.length;

  if (group) {
    document.title = `June - ${group.name}`;
  }

  return (
    <div className="flex">
      <div className="z-docked flex w-full flex-col">
        <div className="flex items-start justify-between">
          <div className="mb-6">
            <div className="flex items-center gap-4">
              <CompanyLogo
                border="4px solid"
                borderColor="white"
                boxShadow="sm"
                size="lg"
              />
              <div className="flex flex-col">
                <Text fontSize="lg" fontWeight={"medium"}>
                  {group.name}
                </Text>
                {group.firstSeenAt && (
                  <Text color="gray.600" fontSize="sm">
                    Joined{" "}
                    {moment.utc(group.firstSeenAt).format("DD MMM, YYYY")}
                  </Text>
                )}
              </div>
            </div>
          </div>
          {hasCompanyDigestEnabled && <ViewAlertButton />}
          <Menu>
            <MenuButton
              as={Button}
              aria-label="Edit user menu"
              variant="ghost"
              size="xs"
              shadow="none"
              boxShadow="none"
              mr={-2}
            >
              <EllipsisVerticalIcon className="h-4 w-4 text-gray-700" />
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<TrashIcon className="h-4" />}
                onClick={() => setShowDeleteModal(!showDeleteModal)}
              >
                <Text fontSize="sm">
                  Delete {groupAppObject.singularName.toLowerCase()}
                </Text>
              </MenuItem>
              {hasFavouritesEnabled && (
                <FavouritesMenuItem
                  objectType={FavouriteObjectType.Group}
                  objectId={groupId}
                  appId={Number(appId)}
                />
              )}
            </MenuList>
          </Menu>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div className="flex items-center">
              <IdentificationIcon className="h-4 text-gray-600" />
            </div>
            <div className="flex items-center">
              <p className="text-sm text-gray-700">{groupId}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center">
              <UsersIcon className="h-4 text-gray-600" />
            </div>
            <div className="flex items-center">
              <p className="text-sm text-gray-700">
                {groupCount} user{groupCount === 1 ? "" : "s"}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center">
              <ClockIcon className="h-4 text-gray-600" />
            </div>
            {group.lastSeenAt ? (
              <div className="flex gap-1">
                <p className="text-sm text-gray-700">Last seen</p>
                <p className="text-sm text-purple-500">
                  {moment
                    .utc(group.lastSeenAt)
                    .format("DD MMM, YYYY [at] hh:mm a")}
                </p>
              </div>
            ) : (
              <div className="flex gap-1">
                <p className="text-sm text-gray-700">Pending...</p>
              </div>
            )}
          </div>
          {group && groupAppObject && (
            <CrmHeader entity={group} appObject={groupAppObject} />
          )}
        </div>
        <DeleteGroupModal
          appId={appId}
          group={group}
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      </div>
    </div>
  );
};
