import Icon from "core/design-system/components/Icon";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface ITraitsBanner {
  setShowTraitsBanner: (show: boolean) => void;
}

export const TraitsBanner: React.FC<ITraitsBanner> = ({
  setShowTraitsBanner,
}) => {
  const onDismiss = () => {
    localStorage.setItem("dismiss_traits_banner", "true");
    setShowTraitsBanner(false);
  };

  return (
    <Box mt={4}>
      <Flex
        p={4}
        pos="relative"
        bg="purple.50"
        border="2px solid"
        borderColor="purple.100"
        borderRadius="lg"
        align="center"
        justifyContent="space-between"
      >
        <Flex direction="column">
          <Flex align="center" justify="space-between">
            <Flex align="start">
              <Icon h={5} w={5} name="company" iconType="icon" mr={2} />
              <Text fontWeight="medium">What are traits?</Text>
            </Flex>
            <Button
              variant="ghost"
              colorScheme="purple"
              onClick={() => {
                onDismiss();
              }}
            >
              <CloseIcon w={3} />
            </Button>
          </Flex>
          <Flex>
            <Text fontSize="sm" color="gray.700">
              Details you know about a user or company to help slice your data
              e.g. email or is_paying:{" "}
              <Text
                as="a"
                color="purple.700"
                href="https://help.june.so/en/articles/6817653-which-traits-you-should-be-tracking"
                target="_blank"
              >
                Learn more
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
