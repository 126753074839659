import { IAudience } from "./Audience";

export enum OnboardingState {
  ONBOARDING = 0,
  SEGMENT_SETUP = 1,
}

export enum DeletionRequestState {
  NOT_REQUESTED = 0,
  PENDING_DELETION = 1,
  ALL_DATA_DELETED = 2,
}

export enum Plan {
  Free = "free_plan",
  Growth = "growth",
  Pro = "pro",
}

export type Business = "b2b" | "b2c";
export type CompanySize = "1-5" | "5-10" | "10-25" | "25-50" | "50+";
export type ReferralSource =
  | "word_of_mouth"
  | "linkedin"
  | "twitter"
  | "product_hunt"
  | "google_search"
  | "blogs"
  | "startup_communities"
  | "no_code_communities"
  | "other";

export type SubscriptionState =
  | "free"
  | "paying"
  | "payment_failed"
  | "trialing";

export interface IApp {
  id: number;
  createdAt: string;
  timeZone: string;
  utcOffset: number;
  groupContextEnabled: boolean;
  hasImpersonation: boolean;
  features: string[];
  idCode: string;
  logo: string | undefined;
  name: string;
  subscriptionState: SubscriptionState;
  isTrialing: boolean;
  isPaying: boolean;
  isFree: boolean;
  isPaymentOverdue: boolean;
  isFirstWeek: boolean;
  onboarded: boolean;
  onboardingState: OnboardingState;
  segmentConnected: boolean;
  firstSegmentTokenCreatedAt: string;
  monthlyUserCount: number;
  currentBillingUserCount: number;
  companyDomain?: string;
  committeeMember: boolean;
  enforceGoogleOauth: boolean;
  autoJoinCompanyWorkspaceEnabled: boolean;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  receivingGroupContext: boolean;
  isCurrentBillingGuardrail: boolean;
  noCardTrialEnded: boolean;
  trialDaysRemaining: number;
  business: Business;
  companySize: CompanySize;
  referralSource: ReferralSource;
  userCount?: number;
  hasRequestedAiAccess?: boolean;
  aiOptedIn: boolean;
  plan: Plan;
  stripeCustomerId?: string;
  computedTraitLimit: number;
  deletionRequestState: DeletionRequestState;
  sortingEnabled: boolean;
  bookedACall: boolean;
  isDemoApp: boolean;
  weeklyDigestEnabled: boolean;
  audience: IAudience;
}
