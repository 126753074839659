import { useNavigate } from "react-router-dom";
import {
  IView,
  useDeleteViewMutation,
  useDuplicateViewMutation,
} from "core/models/views";
import { useToast } from "core/hooks/useToast";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export const ViewMenu = ({ view }: { view: IView }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [duplicateView] = useDuplicateViewMutation();
  const [deleteView] = useDeleteViewMutation();

  return (
    <Menu>
      <MenuButton
        onClick={(e) => {
          e.stopPropagation();
        }}
        as={Button}
        variant="ghost"
      >
        <EllipsisVerticalIcon className="h-4 w-4" />
      </MenuButton>
      <MenuList minW="100px">
        <MenuItem
          fontSize="xs"
          onClick={(e) => {
            e.stopPropagation();
            duplicateView({
              id: view.id,
              appId: view.appId,
            })
              .unwrap()
              .then((data) => {
                toast({
                  status: "success",
                  title: "Dashboard duplicated successfully",
                });
                if (data) {
                  navigate(`/a/${view.appId}/home/${data.id}`);
                }
              })
              .catch(() => {
                toast({
                  status: "error",
                  title: "Failed to duplicate dashboard",
                });
              });
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          fontSize="xs"
          onClick={(e) => {
            e.stopPropagation();
            deleteView({
              id: view.id,
              appId: view.appId,
            })
              .unwrap()
              .then(() => {
                toast({
                  status: "success",
                  title: "Dashboard deleted successfully",
                });
                navigate(`/a/${view.appId}/home`);
              })
              .catch(() => {
                toast({
                  status: "error",
                  title: "Failed to delete dashboard",
                });
              });
          }}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
