import React, { useState } from "react";
import { useGetObjectsQuery } from "core/models/attio";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ICrmSyncSetting } from "@src/core/models/crmSyncSettings";

export const SelectObject: React.FC<{
  appObject: IAppObject;
  setting: ICrmSyncSetting | undefined | null;
  isLoading?: boolean;
  onUpdateCrmSyncSetting: (
    id: number,
    data: Pick<ICrmSyncSetting, "objectName" | "objectSlug">,
  ) => void;
}> = ({ appObject, setting, isLoading, onUpdateCrmSyncSetting }) => {
  const { id: appId } = useCurrentApp();
  const { data } = useGetObjectsQuery({ appId });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [object, setObject] = useState<{
    objectName: string;
    objectSlug: string;
  }>({
    objectName: setting?.objectName || "",
    objectSlug: setting?.objectSlug || "",
  });

  function onSelectObject({
    objectName,
    objectSlug,
  }: {
    objectName: string;
    objectSlug: string;
  }) {
    if (setting?.objectName && setting?.objectSlug) {
      setObject({ objectName, objectSlug });
      onOpen();
    } else if (setting) {
      setObject({ objectName, objectSlug });
      onUpdateCrmSyncSetting(setting.id, {
        objectName,
        objectSlug,
      });
    }
  }

  return (
    <Flex mb={5} direction="row" gridGap={2} justifyContent="space-between">
      <Flex direction="column" gridGap={1}>
        <Text fontSize="sm">Select Attio object</Text>
        {[AppObjectType.User, AppObjectType.Group].includes(
          appObject.objectType,
        ) && (
          <Text fontSize="sm" color="gray.600">
            Select which Attio object June{" "}
            {appObject.pluralName.toLocaleLowerCase()} should sync to
          </Text>
        )}
      </Flex>
      <Flex>
        <Menu>
          <MenuButton
            bg="gray.50"
            border="none"
            as={Button}
            rightIcon={<ChevronDownIcon />}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {setting?.objectName ? setting?.objectName : "Choose an object"}
          </MenuButton>
          <MenuList>
            {data?.map(({ objectName, objectSlug }) => (
              <MenuItem
                key={objectName}
                fontSize="sm"
                onClick={() => onSelectObject({ objectName, objectSlug })}
              >
                {objectName}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change object
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">
              Changing the Attio object will remove all of your
              {` `}
              {appObject.singularName.toLowerCase()} mappings
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                onClick={() => {
                  onClose();
                  onUpdateCrmSyncSetting(Number(setting?.id), {
                    objectName: object.objectName,
                    objectSlug: object.objectSlug,
                  });
                }}
                ml={3}
              >
                Change
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
