import React from "react";
import { ApiKeySource, IApiKey } from "core/models/apiKeys";
import { useApiKeys } from "core/hooks/useApiKeys";
import Icon from "core/design-system/components/Icon";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ApiKeyNameEditorModal from "./ApiKeyNameEditorModal";

interface IActionsMenu {
  apiKey: IApiKey;
  appId: number;
  source: ApiKeySource;
}

export const ActionsMenu: React.FC<IActionsMenu> = ({
  apiKey,
  appId,
  source,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteApiKey } = useApiKeys({ appId, source, apiKeyId: apiKey.id });

  return (
    <>
      <Menu>
        {({ isOpen: isActive }) => (
          <>
            <MenuButton
              variant="ghost"
              as={Button}
              isActive={isActive}
              size="sm"
            >
              <Icon iconType="icon" name="more" />
            </MenuButton>
            <MenuList minWidth="240px">
              <MenuItem onClick={onOpen}>
                <Flex justify="flex-start" alignItems="center">
                  <Icon iconType="icon" name="edit" mr={2} />
                  <Text fontSize="sm">Rename</Text>
                </Flex>
              </MenuItem>
              <MenuItem onClick={deleteApiKey}>
                <Flex justify="flex-start" alignItems="center">
                  <Icon iconType="icon" name="remove" mr={2} color="red.500" />
                  <Text fontSize="sm" color="red.500">
                    Remove
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
      <ApiKeyNameEditorModal
        isOpen={isOpen}
        toggle={onClose}
        apiKey={apiKey}
        appId={appId}
      />
    </>
  );
};

export default ActionsMenu;
