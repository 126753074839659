import { useEffect, useState } from "react";
import { IEventLog } from "core/types/EventLog";
import { useGetEventLogsQuery } from "core/models/eventLogs";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { SearchBar } from "Components/SearchBar";
import { Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import ContactEventLogGroups from "./ContactEventLogGroups";

export default function ContactEventLog({
  appId,
  contactId,
  groupId,
  groupType,
  useInternalUsers,
}: {
  appId: number;
  contactId?: string;
  groupId?: string;
  groupType?: GroupType;
  useInternalUsers?: boolean;
}) {
  const { audience: internalUsersAudience } = useCurrentApp();
  const [page, setPage] = useState(1);
  const [eventLogs, setEventLogs] = useState<IEventLog[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [previousSearchQuery, setPreviousSearchQuery] = useState("");
  const { data, isLoading, isFetching, error } = useGetEventLogsQuery({
    appId,
    page,
    contactId,
    groupId,
    groupType,
    audienceFilters: useInternalUsers ? internalUsersAudience : undefined,
    query: searchQuery,
  });

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setPage(1);
  };

  useEffect(() => {
    const searchCleared = previousSearchQuery && !searchQuery;

    if (data?.eventLogs) {
      if (searchQuery) {
        if (page === 1) {
          setEventLogs(data.eventLogs);
        } else {
          setEventLogs([...eventLogs, ...data.eventLogs]);
        }
      } else if (searchCleared) {
        setEventLogs(data.eventLogs);
      } else {
        setEventLogs([...eventLogs, ...data.eventLogs]);
      }
    }

    setPreviousSearchQuery(searchQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchQuery]);

  if (error) {
    return <Error />;
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <SearchBar
        mb={-4}
        searchQuery={searchQuery}
        onSearch={onSearch}
        placeholder="Search your events by name..."
      />
      {isLoading && (
        <div className="relative top-10 flex h-[500px] w-full flex-col gap-y-5">
          <Skeleton w="140px" h="30px" borderRadius="md" />
          <div className="flex w-full items-center justify-between">
            <Skeleton w="500px" h="20px" borderRadius="md" />
            <Skeleton w="80px" h="20px" borderRadius="md" />
          </div>
          <div className="flex w-full items-center justify-between">
            <Skeleton w="500px" h="20px" borderRadius="md" />
            <Skeleton w="80px" h="20px" borderRadius="md" />
          </div>
          <div className="flex w-full items-center justify-between">
            <Skeleton w="500px" h="20px" borderRadius="md" />
            <Skeleton w="80px" h="20px" borderRadius="md" />
          </div>
          <div className="flex w-full items-center justify-between">
            <Skeleton w="500px" h="20px" borderRadius="md" />
            <Skeleton w="80px" h="20px" borderRadius="md" />
          </div>
          <div className="flex w-full items-center justify-between">
            <Skeleton w="500px" h="20px" borderRadius="md" />
            <Skeleton w="80px" h="20px" borderRadius="md" />
          </div>
        </div>
      )}
      {!(isLoading || isFetching) && (!data || eventLogs.length === 0) && (
        <Flex width="250px" height="250px" align="center" justify="center">
          <Text>No events found</Text>
        </Flex>
      )}
      {data && eventLogs && eventLogs.length > 0 && (
        <div className="w-full">
          <ContactEventLogGroups
            key={eventLogs.length}
            eventLogs={eventLogs}
            showUser={!!groupId}
          />
        </div>
      )}
      {data && data.pagy.last > data.pagy.page && (
        <div className="mt-6 flex w-full justify-center">
          <Button
            onClick={() => {
              setPage(page + 1);
            }}
            isLoading={isLoading || isFetching}
          >
            Load more..
          </Button>
        </div>
      )}
    </div>
  );
}
