import { ReferenceLine } from "recharts";
import { useState } from "react";
import { simplifyXAxisTicks } from "helpers/xAxis";
import { IReport } from "core/types/Report";
import { ICustomTooltipProps } from "core/types/CustomTooltip";
import colors from "core/design-system/constants/theme/colors";
import TickText from "core/design-system/charts/TickText";
import {
  CommonDot,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FeatureUsageList } from "../Usage/FeatureUsageList";

interface ILineChart {
  unit: string;
  data: any;
  dataKey: string;
  previewMode: boolean;
  sharingMode: boolean;
  hasHour: boolean;
  intervalType: number;
  reportId?: number;
  groupId?: string;
  showGrid?: boolean;
  simplifyXAxis?: boolean;
  showYAxis?: boolean;
  report: IReport;
  h?: number;
}

interface IFormattedData {
  x: string;
  y: number;
  uniqueUserCount?: number;
  currentUniqueUserCount?: number;
  usageCount: number;
  humanizedDate: string;
  date: string;
  hour: string;
}

const LineChart: React.FC<ILineChart> = ({
  unit,
  data,
  dataKey = "uniqueUserCount",
  previewMode,
  sharingMode,
  intervalType,
  hasHour,
  showYAxis,
  showGrid,
  report,
  simplifyXAxis,
  h = 300,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [humanizedDate, setHumanizedDate] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState();
  const [usageCount, setUsageCount] = useState(0);
  const [uniqueUserCount, setUniqueUserCount] = useState(0);

  let marker: string | undefined;

  const formattedData = data?.reduce(
    (formatted: IFormattedData[], row: any, currentIndex: number) => {
      const lastRow = formatted[formatted.length - 1];
      const nextRow = data[currentIndex + 1];

      if (!marker && row.incomplete) {
        marker = lastRow.x;
      }

      formatted.push({
        x: row.chartDate ? row.chartDate : row.humanizedDate,
        y: row[dataKey],
        uniqueUserCount: !row.incomplete ? row[dataKey] : undefined,
        currentUniqueUserCount:
          nextRow?.incomplete || row.incomplete ? row[dataKey] : undefined,
        usageCount: row.usageCount,
        humanizedDate: row.humanizedDate,
        date: row.date,
        hour: row.hour,
      });
      return formatted;
    },
    [],
  );

  const { firstTick, lastTick } = simplifyXAxisTicks(formattedData, "x");

  function CustomTooltip({ label, payload, active }: ICustomTooltipProps) {
    if (active && payload && payload.length) {
      return (
        <Box w="200px" bg="gray.700" px={3} py={2} borderRadius={5}>
          <Text fontSize="xs" color="gray.400">
            {payload[0].payload.humanizedDate}
          </Text>
          <Text fontSize="sm" color="white">
            {payload[0].payload.y} {unit}
          </Text>
          {!previewMode && (
            <Text mt={2} fontSize="xs" color="gray.400">
              Click on the chart to see the list of {unit}
            </Text>
          )}
        </Box>
      );
    }
    return null;
  }

  const onDataPointClick = (
    _data: any,
    index: { payload: { [key: string]: any } },
  ) => {
    if (!previewMode && !sharingMode) {
      onOpen();
      setHumanizedDate(index.payload.x);
      setDate(index.payload.date);
      setUsageCount(index.payload.usageCount);
      setUniqueUserCount(
        index.payload.uniqueUserCount !== undefined
          ? index.payload.uniqueUserCount
          : index.payload.currentUniqueUserCount,
      );
      if (hasHour) {
        setHour(index.payload.hour);
      }
    }
  };

  return (
    <Flex justify="center" h={h}>
      <AreaChart
        margin={{ top: 30, left: showYAxis ? 0 : -30, right: 50, bottom: 20 }}
        height="100%"
        data={formattedData}
      >
        <defs>
          <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.purple[400]}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.purple[100]}
              stopOpacity={0.4}
            />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="greyGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.gray[100]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        {showGrid && <CommonGrid />}
        {/* @ts-ignore */}
        <CommonXAxis
          ticks={simplifyXAxis ? [firstTick, lastTick] : ""}
          dataKey="x"
        />
        {/* @ts-ignore */}
        <CommonYAxis
          tick={({ x, ...props }: { x: number; payload: { value: any } }) =>
            showYAxis && (
              // @ts-ignore
              <TickText x={x - 5} {...props}>
                {props.payload.value}
              </TickText>
            )
          }
        />
        <ReferenceLine
          x={marker}
          stroke={colors.gray[600]}
          strokeDasharray="3 3"
        />
        {/* @ts-ignore */}
        <CommonTooltip content={<CustomTooltip />} />
        <Area
          dataKey={dataKey}
          activeDot={
            // @ts-ignore
            <CommonDot
              // @ts-ignore
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode ? "pointer" : undefined}
            />
          }
          type="monotone"
          fillOpacity={1}
          fill="url(#purpleGradient)"
        />
        <Area
          stackId="0"
          name="Current unique user count"
          dataKey="currentUniqueUserCount"
          fill="url(#greyGradient)"
          stroke={colors.gray[500]}
          type="monotone"
          activeDot={
            <CommonDot
              // @ts-ignore
              onClick={onDataPointClick}
              r={7}
              cursor={!previewMode ? "pointer" : undefined}
            />
          }
        />
      </AreaChart>
      {!sharingMode && !previewMode && (
        <FeatureUsageList
          key={date}
          unit={unit}
          isOpen={isOpen}
          onClose={onClose}
          humanizedDate={humanizedDate}
          date={date}
          hour={hour}
          usageCount={usageCount}
          uniqueUserCount={uniqueUserCount}
          intervalType={intervalType}
          report={report}
        />
      )}
    </Flex>
  );
};

export default LineChart;
