import { IHightlight } from "core/types/TemplateConfig";
import HelpCircle from "core/design-system/components/Icon/Interface/HelpCircle";
import {
  ComponentDefaultProps,
  Flex,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";

const CHAR_LIMIT = 25;

export interface ITitleProps extends ComponentDefaultProps {
  title: string;
  description: string;
  shouldBePaywalled: boolean;
  isLoading: boolean;
  showHighlightDescription: boolean;
  highlight?: IHightlight;
  tooltipText?: string | React.ReactNode;
  icon?: React.ReactNode;
  color?: IColorPalette;
}

export const Title: React.FC<ITitleProps> = ({
  title,
  description,
  shouldBePaywalled,
  highlight,
  tooltipText,
  showHighlightDescription,
  isLoading,
  icon,
  color = colors.purple,
  children,
}) => {
  if (isLoading)
    return (
      <Flex direction="column" gridGap={2}>
        <Skeleton w="100px" h="20px" />
        <Skeleton w="150px" h="10px" />
        <Skeleton w="40px" h="35px" />
      </Flex>
    );

  return (
    <div className="flex w-full">
      <div className="flex w-full flex-col" data-testid="highlight-card-title">
        <Tooltip
          label={(title?.length || 0) > CHAR_LIMIT ? title : ""}
          placement="top"
          hasArrow
        >
          <div className="flex w-full items-center gap-1">
            {icon ? <Flex>{icon}</Flex> : null}
            <Flex>
              <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
                {title}
              </Text>
            </Flex>
            {tooltipText && (
              <Tooltip label={tooltipText} hasArrow placement="top">
                <HelpCircle h={4} w={4} color="purple.500" ml={1} />
              </Tooltip>
            )}
          </div>
        </Tooltip>
        <Text pt={0.5} fontSize="xs" color="gray.600" noOfLines={1}>
          {description}
        </Text>
        {!shouldBePaywalled &&
          highlight &&
          (highlight?.value || highlight?.description || highlight?.unit) && (
            <p className="mt-1 line-clamp-1 text-3xl font-medium leading-tight">
              {highlight?.value?.toLocaleString()}
              {highlight?.unit}
              {showHighlightDescription && (
                <span
                  className="ml-1 whitespace-nowrap text-xs"
                  style={{ color: color[500] }}
                >
                  {highlight?.description}
                </span>
              )}
            </p>
          )}
      </div>
      <div className="absolute right-[-8px] top-0 bg-white pl-1">
        {children}
      </div>
    </div>
  );
};
