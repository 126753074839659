import { Code, Text } from "@chakra-ui/react";

export const UnidentifiedUserDescription = ({
  contactId,
}: {
  contactId: string;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Text>
        We received track calls, but no identify calls for a user with{" "}
        <Code
          fontSize="xs"
          colorScheme={"gray"}
          color="red.400"
          children={contactId}
        />{" "}
        as a{" "}
        <Code
          fontSize="xs"
          colorScheme={"gray"}
          color="red.400"
          children="userId"
        />
        . Send an identify call to add information about this user.
      </Text>
      <Text
        as="a"
        fontWeight="semibold"
        color="yellow.600"
        target="blank"
        href="https://segment.com/docs/connections/spec/identify/"
      >
        Learn more →
      </Text>
    </div>
  );
};
