import { cx } from "helpers/cx";
import { motion } from "framer-motion";
import { useNavigation } from "core/hooks/useNavigation";
import { DynamicExpandedSidebar } from "./DynamicExpandedSidebar";
import { BaseSidebar } from "./BaseSidebar";

export type CurrentSection =
  | "Discover"
  | "Home"
  | "New report"
  | "Reports"
  | "Events"
  | "Templates"
  | "Feed"
  | "Settings"
  | "People"
  | "Getting started"
  | "AIExplorer";

export interface ISidebarProps {
  currentSection: CurrentSection;
}

export const Sidebar: React.FC<ISidebarProps> = ({ currentSection }) => {
  const { navigation, x } = useNavigation();
  const { isOpen } = navigation;

  return (
    <div>
      <motion.nav
        initial={{ x }}
        animate={{ x }}
        exit={{ x }}
        transition={{ type: "spring", bounce: 0, duration: 0.4 }}
        className={cx(isOpen ? "flex h-full w-full overflow-auto" : "")}
      >
        <BaseSidebar />
        {isOpen && <DynamicExpandedSidebar currentSection={currentSection} />}
      </motion.nav>
    </div>
  );
};
