import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selector as appSelector } from "core/models/app";
import { usePaywall } from "core/hooks/usePaywall";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { useBilling } from "core/hooks/useBilling";
import { baseUrl } from "core/constants/paywall";
import { DEMO_APP_ID } from "core/constants/appIds";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { Progress, Text } from "@chakra-ui/react";

export const UsageThreshold = () => {
  const { data: currentApp, isLoading } = useSelector(appSelector);
  const { plan } = usePaywall();
  const {
    userCount,
    barColor,
    threshold,
    hasUsageBasedPricing,
    isAboveLimit,
    isApproachingLimit,
  } = useMauThreshold();
  const navigate = useNavigate();
  const { redirectToBillingPortal } = useBilling();

  if (isLoading) return null;
  if (!currentApp) return null;

  const isPaying = currentApp.isPaying;
  const isPaymentOverdue = currentApp.isPaymentOverdue;
  const isTrial = currentApp.isTrialing && currentApp.trialDaysRemaining >= 0;
  const hasTrialed = currentApp.noCardTrialEnded;
  const isDesignCommitteeMember = currentApp.committeeMember;
  const redirectUrl = `${baseUrl}/a/${currentApp.id}/getting-started`;

  function getBackgroundColor() {
    if (isPaymentOverdue) {
      return "bg-red-100";
    } else if (isAboveLimit) {
      return "bg-red-50";
    } else if (isApproachingLimit) {
      return "bg-red-50";
    }

    return "bg-purple-50";
  }

  function getTextColor() {
    if (isPaymentOverdue || isAboveLimit) {
      return "red.500";
    } else if (isApproachingLimit) {
      return "orange.900";
    }

    return "purple.500";
  }

  function getProgressBackgroundColor() {
    if (isPaymentOverdue) {
      return "red.100";
    } else if (isAboveLimit) {
      return "red.100";
    } else if (isApproachingLimit) {
      return "orange.100";
    }

    return "purple.100";
  }

  return (
    <div className="mx-3 my-2">
      <div
        className={`flex flex-col items-start gap-1 rounded-md px-2 pb-4 ${getBackgroundColor()}`}
      >
        <div className="mb-1 flex flex-row items-center justify-between">
          <div
            className="flex cursor-pointer flex-row items-center justify-between"
            onClick={() =>
              hasUsageBasedPricing
                ? navigate(`/a/${currentApp.id}/settings/billing`)
                : redirectToBillingPortal()
            }
          >
            {isPaymentOverdue && (
              <Text
                color="red.500"
                fontSize="xs"
                fontWeight="bold"
                mt={2}
                ml={2}
                as="span"
                borderRadius="md"
                noOfLines={1}
              >
                Payment overdue
              </Text>
            )}
          </div>
        </div>
        <PaywallModal
          redirectUrl={redirectUrl}
          isCloseable={!currentApp.isTrialing || !hasTrialed}
        >
          {(paywallModal: IPaywallModalChildren) => (
            <>
              {!isPaying &&
                !isPaymentOverdue &&
                !isTrial &&
                !isDesignCommitteeMember && (
                  <paywallModal.UpgradeButton
                    tooltipText={
                      hasTrialed && currentApp.isTrialing
                        ? "Your trial has ended. Choose your plan to continue using June"
                        : "Increase your monthly active user threshold and unlock premium features"
                    }
                    variant="ghost"
                    colorScheme="purple"
                    size="xs"
                    isDisabled={currentApp.id === DEMO_APP_ID}
                  >
                    {hasTrialed && currentApp.isTrialing
                      ? "Choose plan"
                      : hasTrialed
                        ? "Upgrade"
                        : "Start Free Trial"}
                  </paywallModal.UpgradeButton>
                )}
              <paywallModal.Modal />
            </>
          )}
        </PaywallModal>
        <div className="flex w-full flex-row items-center justify-between px-2">
          <Text color="gray.600" fontSize="xs">
            <Text as="span" color="gray.900" fontWeight="medium">
              {userCount.toLocaleString("en-US")}{" "}
            </Text>
            /{" "}
            <Text as="span" color="gray.900" fontWeight="medium">
              {isDesignCommitteeMember
                ? "Unlimited"
                : threshold.toLocaleString("en-US")}{" "}
              MAU
            </Text>{" "}
          </Text>
          {isPaying && !isDesignCommitteeMember && (
            <Text
              color={getTextColor()}
              fontSize="xs"
              fontWeight="medium"
              ml={2}
              py={0.5}
              as="span"
              textTransform="capitalize"
              noOfLines={1}
            >
              {plan.replace("_", " ")}
            </Text>
          )}
          {isDesignCommitteeMember && (
            <Text
              color={getTextColor()}
              fontSize="xs"
              fontWeight="medium"
              ml={2}
              py={0.5}
              noOfLines={1}
            >
              Committee
            </Text>
          )}
        </div>
        <div className="w-full px-2">
          <Progress
            value={(userCount / threshold) * 100}
            size="xs"
            colorScheme={barColor}
            borderRadius="sm"
            bgColor={getProgressBackgroundColor()}
          />
        </div>
      </div>
      {isTrial && (
        <Link to={`/a/${currentApp.id}/settings/billing`}>
          <div
            className={
              "mb-1 mt-2 flex flex-row items-center gap-1 rounded-md bg-orange-100 px-4 py-2"
            }
          >
            <RocketLaunchIcon className="h-4 w-4 text-orange-700" />
            <span className="text-xs font-medium text-gray-800">
              Trial active
            </span>
            <span className="m-0 text-ellipsis rounded-md text-xs font-medium text-orange-700">
              {currentApp.trialDaysRemaining === 0
                ? "until today"
                : currentApp.trialDaysRemaining > 1
                  ? `${currentApp.trialDaysRemaining} days left`
                  : `${currentApp.trialDaysRemaining} day left`}
            </span>
          </div>
        </Link>
      )}
    </div>
  );
};
