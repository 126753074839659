import { Integration, integrationNames } from "core/models/dataMappings";
import { IAppObject } from "core/models/appObjects";
import { IDataMapping } from "core/hooks/useDataMappings";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { EditableSourceMenu } from "./EditableSourceMenu";
import { EditableDestinationMenu } from "./EditableDestinationMenu";

interface IMatchingMappingRow {
  appObject: IAppObject;
  integration: Integration;
  matchingIdentifierDataMapping: IDataMapping;
  isLoading?: boolean;
}

export const MatchingMappingRow: React.FC<IMatchingMappingRow> = ({
  appObject,
  integration,
  matchingIdentifierDataMapping,
  isLoading,
}) => {
  const integrationName = integrationNames[integration];

  return (
    <div className="flex w-full flex-row items-start justify-between">
      <div className="max-w-lg">
        <p className="text-sm">
          How should June {appObject.pluralName?.toLowerCase()} match with{" "}
          <p className="inline-block capitalize">{integrationName}</p>?
        </p>
        <p className="mt-1 text-sm text-gray-500">
          This is what we will use when we search{" "}
          <p className="inline-block capitalize">{integrationName}</p> for{" "}
          {appObject.pluralName?.toLowerCase()}
        </p>
      </div>

      <div className="flex flex-col">
        <div className="flex max-w-sm flex-row items-center justify-between gap-4">
          <div className="max-w-full">
            {matchingIdentifierDataMapping?.isEditable ? (
              <EditableSourceMenu
                appObject={appObject}
                integration={integration}
                dataMapping={matchingIdentifierDataMapping}
              />
            ) : (
              <p className="text-sm">{matchingIdentifierDataMapping.source}</p>
            )}
          </div>
          <div>
            <ArrowRightIcon className="h-4 text-gray-600" />
          </div>
          <div>
            <EditableDestinationMenu
              appObject={appObject}
              integration={integration}
              matchingIdentifierDataMapping={matchingIdentifierDataMapping}
              isLoading={isLoading}
            />
          </div>
        </div>
        {!["name", "domain", "email"].includes(
          matchingIdentifierDataMapping.destination,
        ) &&
          integration === Integration.Hubspot && (
            <div className="mt-2 flex max-w-sm items-center justify-between bg-gray-50 px-4 py-2 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
              <p className="text-xs text-gray-600">
                <strong>Note:</strong> If you choose your own unique custom
                property, make sure that the property definition is a{" "}
                <strong>"single-line text"</strong> field type and the rules are
                set to require unique values.
              </p>
            </div>
          )}
      </div>
    </div>
  );
};
