import { useNavigate } from "react-router-dom";
import { IAudience } from "core/types/Audience";
import { useGetAudiencesQuery } from "core/models/contacts";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { UsersIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "@chakra-ui/react";

interface IAudienceBadgeContainer {
  contactId: string;
}

export const AudienceBadgeContainer: React.FC<IAudienceBadgeContainer> = ({
  contactId,
}) => {
  const navigate = useNavigate();
  const { id: appId } = useCurrentApp();
  const { data: audiences, isFetching } = useGetAudiencesQuery({
    appId,
    contactId,
  });

  if (isFetching)
    return (
      <div className="mt-2 flex flex-row items-start justify-between gap-2">
        <div className="flex items-center gap-2">
          <UsersIcon className="h-4 text-gray-600" />
          <p className="text-sm">Audiences</p>
        </div>
        <div className="flex flex-wrap gap-1">
          <Skeleton className="h-5 w-[100px] rounded" />
          <Skeleton className="h-5 w-[100px] rounded" />
        </div>
      </div>
    );

  if (!audiences) return null;

  return (
    <div className="mt-2 flex flex-row items-start justify-between gap-2">
      <div className="flex items-center gap-2">
        <UsersIcon className="h-4 text-gray-600" />
        <p className="text-sm">Audiences</p>
      </div>
      <div className="flex flex-wrap justify-end gap-1">
        {audiences.map((audience: IAudience, index: number) => (
          <span
            key={audience.id}
            className={
              "inline-flex cursor-pointer items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-500"
            }
            onClick={() => navigate(`/a/${appId}/audience/${audience.id}`)}
          >
            {audience.name}
          </span>
        ))}
      </div>
    </div>
  );
};
