import React from "react";
import useSmartTrait from "core/hooks/useSmartTrait";
import { ComputedTraitEditor } from "./ComputedTraitEditor";
import { Navigate, useParams } from "react-router-dom";

export const EditComputedTrait: React.FC = () => {
  const { appId, traitId } = useParams();
  const { trait, isLoading, onCompute, onSave, onSetLive, onDelete } =
    useSmartTrait({});

  if (
    window.location.pathname.includes(`/settings/computed-traits/${traitId}`)
  ) {
    return (
      <Navigate to={`/a/${appId}/integrations/computed-traits/${traitId}`} />
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!trait) {
    return <div>Not found</div>;
  }

  return (
    <ComputedTraitEditor
      trait={trait}
      onCompute={onCompute}
      onSave={onSave}
      onSetLive={onSetLive}
      onDelete={onDelete}
    />
  );
};
