import {
  ApiKeyKeyType,
  ApiKeySource,
  ApiKeyTypes,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetApiKeysQuery,
  useUpdateApiKeyMutation,
} from "core/models/apiKeys";

interface IUseApiKeys {
  apiKeyId?: number;
  appId: number;
  source: ApiKeySource;
  name?: string;
  keyType?: ApiKeyKeyType;
}

export const useApiKeys = ({
  appId,
  source,
  name,
  apiKeyId,
  keyType = ApiKeyTypes.WRITE,
}: IUseApiKeys) => {
  const {
    data: apiKeys,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetApiKeysQuery({ appId, source, keyType });
  const [_createApiKey] = useCreateApiKeyMutation();
  const [_updateApiKey] = useUpdateApiKeyMutation();
  const [_deleteApiKey] = useDeleteApiKeyMutation();

  const createApiKey = () => {
    _createApiKey({ appId, source, keyType });
  };

  const updateApiKey = () => {
    if (!apiKeyId || !name) {
      console.log("Missing apiKeyId or name");
      return;
    }

    _updateApiKey({ appId, name, id: apiKeyId, keyType });
  };

  const deleteApiKey = () => {
    if (!apiKeyId) {
      console.log("Missing apiKeyId");
      return;
    }

    _deleteApiKey({ appId, source, id: apiKeyId, keyType });
  };

  return {
    apiKeys,
    isLoading,
    isFetching,
    isUninitialized,
    createApiKey,
    deleteApiKey,
    updateApiKey,
  };
};
