import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { thousandsToK } from "core/helpers/thousands";
import { Interval, Measure } from "core/types/ViewInsight";
import { cx } from "helpers/cx";

export interface ITrendData {
  count: number;
  change: number;
  interval: Interval;
}

interface ITrendProps {
  data: ITrendData;
  measure: Measure;
}

const HUMANIZED_TREND_INTERVAL = {
  [Interval.Daily]: "vs yesterday",
  [Interval.Weekly]: "vs last week",
  [Interval.Monthly]: "vs last month",
};

export const Trend: React.FC<ITrendProps> = ({ data, measure }) => {
  if (
    !data ||
    data?.count === undefined ||
    data?.change === undefined ||
    data?.interval === undefined
  )
    return <></>;

  return (
    <div
      className={cx(
        "relative bottom-2 ml-6 flex h-full w-full flex-col items-center justify-center gap-y-3 rounded-lg bg-gradient-to-t",
        data.change < 0 ? "from-red-50 to-white" : "from-green-50 to-white",
      )}
    >
      <div className="flex w-full flex-col items-center justify-center pt-4">
        <div className="flex">
          <p className="truncate text-6xl font-medium">
            {thousandsToK(data.count)}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-x-1 pb-4">
        <div
          className={cx(
            "text-md flex items-center",
            data.change < 0 ? " text-red-600" : "text-green-600 ",
          )}
        >
          {data.change < 0 ? (
            <ArrowLongDownIcon className="h-3.5 w-3.5" />
          ) : (
            <ArrowLongUpIcon className="h-3.5 w-3.5" />
          )}
          <p className="text-sm font-medium">{data.change || 0}%</p>
        </div>
        <p className="text-sm text-gray-600">
          {HUMANIZED_TREND_INTERVAL[data.interval]}
        </p>
      </div>
    </div>
  );
};
