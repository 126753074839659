import { getInsightConfig } from "modules/ViewInsight/ViewInsightContainer";
import { Interval, Size } from "core/types/ViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import { useViewInsightTimerange } from "core/hooks/useViewInsightTimerange";
import { useQueryParam } from "core/hooks/useQueryParam";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import { GRID_WIDTH_TO_SIZE } from "core/hooks/useEditViewInsight";
import { FUNNEL, REPORT_CONFIGS } from "core/constants/report-configurations";
import { GridItem, Skeleton } from "@chakra-ui/react";
import { ViewInsightComponent } from "./ViewInsightComponent";
import { PublicViewInsightCard } from "./PublicViewInsightCard";
import { PublicAIViewInsightCard } from "./PublicAIViewInsightCard";
import { INTERVAL_TYPES } from "./Builder/Preview/Container";
import { GRID_TO_PX, SIZE_TO_INDEX } from "./Builder/LayoutContainer";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

export interface IPublicViewInsight {
  viewInsight: IViewInsight;
  sharingSecretToken?: string;
  isAI?: boolean;
}

export const PublicViewInsight: React.FC<IPublicViewInsight> = ({
  viewInsight,
  sharingSecretToken,
  isAI,
}) => {
  const size = useQueryParam("size");
  const { response, isLoading, isFetching } = useInsightDataFetching({
    report: viewInsight.report,
    view: viewInsight.view,
    insightType: viewInsight.insightId,
    insightParams: {
      ...viewInsight.insight?.defaultParams,
      interval_type:
        INTERVAL_TYPES[viewInsight.interval as unknown as Interval],
      size: GRID_WIDTH_TO_SIZE[viewInsight.gridWidth],
      breakdown_enabled:
        viewInsight.insight.reportType === FUNNEL &&
        Boolean(viewInsight.report.config.breakdown)
          ? false
          : undefined,
    },
    sharingSecretToken,
    previewMode: false,
    groupType: viewInsight?.appObject?.slug,
    timerangeType: viewInsight?.timerangeType,
    timerangeValue: viewInsight?.timerangeValue,
    timerangeStartTimestamp: viewInsight?.timerangeStartTimestamp,
    timerangeEndTimestamp: viewInsight?.timerangeEndTimestamp,
    audience: viewInsight.useViewAudience
      ? viewInsight.view.audience
      : undefined,
    skip: isAI,
  });
  const { label } = useViewInsightTimerange(viewInsight);

  if (viewInsight.insightId === undefined) return null;

  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId,
  );
  const data = insightConfig?.view
    ? insightConfig?.view?.transform(response?.data, viewInsight.interval)
    : [];
  const highlight = insightConfig?.view?.highlight
    ? insightConfig?.view?.highlight(response?.data)
    : {};

  const isEmpty = data?.length === 0;

  if (!data || isLoading || isFetching) {
    const height = GRID_TO_PX[viewInsight?.gridHeight as unknown as Size];
    const width = GRID_TO_PX[viewInsight?.gridWidth as unknown as Size];
    return (
      <GridItem
        rowSpan={viewInsight.gridHeight}
        colSpan={viewInsight.gridWidth}
        h="315px"
        minW="315px"
        minH="315px"
      >
        <Skeleton borderRadius="lg" minH={height} minW={width} />
      </GridItem>
    );
  }

  if (isAI) {
    return (
      <GridItem
        key={viewInsight.id}
        rowSpan={viewInsight.gridHeight}
        colSpan={viewInsight.gridWidth}
        h="full"
        w="full"
        minH={GRID_TO_PX[viewInsight.gridHeight]}
        minW={GRID_TO_PX[size ? SIZE_TO_INDEX[size] : viewInsight.gridWidth]}
      >
        <PublicAIViewInsightCard
          viewInsight={viewInsight}
          highlight={highlight}
          sharingSecretToken={sharingSecretToken}
        />
      </GridItem>
    );
  }

  return (
    <GridItem
      key={viewInsight.id}
      rowSpan={viewInsight.gridHeight}
      colSpan={viewInsight.gridWidth}
      h="full"
      w="full"
      minH={GRID_TO_PX[viewInsight.gridHeight]}
      minW={GRID_TO_PX[size ? SIZE_TO_INDEX[size] : viewInsight.gridWidth]}
    >
      <PublicViewInsightCard
        viewInsight={viewInsight}
        title={viewInsight.title || response?.reportName}
        description={viewInsight.insight.name}
        highlight={highlight}
        timerange={label}
        isLoading={isLoading || isFetching}
        isEmpty={isEmpty}
      >
        <ViewInsightComponent viewInsight={viewInsight} data={data} />
      </PublicViewInsightCard>
    </GridItem>
  );
};
