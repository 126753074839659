import { useEffect, useState } from "react";
import { Resizable } from "re-resizable";
import { cx } from "helpers/cx";
import { motion } from "framer-motion";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { ReportLevelToggle } from "core/modules/reports/Navbar/ReportLevelToggle";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useNavigation } from "core/hooks/useNavigation";
import { PERSISTED } from "core/constants/reportStates";
import {
  EXPLORER,
  FUNNEL,
  MILESTONES,
} from "core/constants/report-configurations";
import { SetupContainer } from "./SetupContainer";
import { ReportContainer } from "./ReportContainer";
import { ReportNavbar } from "./Navbar/Navbar";
import { InsightsContainer } from "./InsightsContainer";

const DEFAULT_WIDTH = 70;

export const ReportLayoutContainer: React.FC<{
  templateConfig: ITemplateConfig;
  report: IReport;
}> = ({ report, templateConfig }) => {
  const groupId = useQueryParam("groupId");
  const { navigation, toggleSidebar } = useNavigation();
  const showReportSetup =
    report.state !== PERSISTED ||
    ([EXPLORER, FUNNEL, MILESTONES].includes(report.reportType) &&
      groupId === null);
  const [showSetup, setShowSetup] = useState(showReportSetup);
  const [width, setWidth] = useState(showReportSetup ? DEFAULT_WIDTH : 100);
  const [isResizing, setIsResizing] = useState(false);

  const displayLevelToggle =
    ![EXPLORER].includes(report.reportType) && groupId === null;

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ml-2 flex h-screen min-w-[1300px] overflow-hidden bg-white">
      <Resizable
        defaultSize={{
          width: `${width}%`,
          height: "100%",
        }}
        size={{
          width: `${width}%`,
          height: "100%",
        }}
        minWidth={"60%"}
        maxWidth={`${showSetup ? DEFAULT_WIDTH : 100}%`}
        className={cx(
          "transition-width relative h-full min-w-[550px] overflow-y-auto overflow-x-hidden",
          !isResizing && "duration-200",
        )}
        onResizeStart={() => setIsResizing(true)}
        onResizeStop={(e, d, r) => {
          const newWidthPercentage = (r.offsetWidth / window.innerWidth) * 100;
          setWidth(newWidthPercentage);
          setIsResizing(false);
        }}
        handleClasses={{
          right:
            "hover:bg-purple-500 active:bg-purple-500 bg-gray-200 w-[2px] h-full",
        }}
        handleStyles={{
          right: { width: "2px", right: 0 },
        }}
      >
        <ReportContainer>
          <div className="flex flex-col">
            <ReportNavbar
              report={report}
              templateConfig={templateConfig}
              showSetup={showSetup}
              onToggleSetup={() => {
                if (!showSetup) {
                  setWidth(DEFAULT_WIDTH);
                } else {
                  setWidth(100);
                }
                setShowSetup(!showSetup);
              }}
            />
            {displayLevelToggle && (
              <div className="mt-10 flex justify-center">
                <ReportLevelToggle />
              </div>
            )}
            <div className={cx("mt-10", displayLevelToggle && "mt-5")}>
              <InsightsContainer
                report={report}
                templateConfig={templateConfig}
              />
            </div>
          </div>
        </ReportContainer>
      </Resizable>
      <motion.div
        initial={{ width: "100%" }}
        animate={{ width: "100%" }}
        exit={{ width: 0 }}
        transition={{ duration: 0.2 }}
        className={cx(
          "min-w-[300px] max-w-full bg-gray-50",
          showSetup ? "p-2 px-3" : "w-0",
        )}
      >
        <SetupContainer
          report={report}
          templateConfig={templateConfig}
          showSetup={showSetup}
        />
      </motion.div>
    </div>
  );
};
