import { useParams } from "react-router-dom";
import moment from "moment";
import { useGroup } from "core/hooks/useGroup";
import { useClearbitLogo } from "core/hooks/useClearbitLogo";
import { isValidUrl } from "core/helpers/isValidUrl";
import { getDomainFromEmails } from "core/helpers/domainFromEmails";
import CompanyAvatar from "core/components/Avatars/CompanyAvatar";
import { ComponentDefaultProps } from "@chakra-ui/react";
import { useEffect } from "react";

type ICompanyLogoProps = {
  appId?: number;
  groupId?: string;
  size?: string;
};

export const CompanyLogo: React.FC<
  ICompanyLogoProps & ComponentDefaultProps
> = ({ appId, groupId: paramGroupId, ...props }) => {
  const { groupId: encodedGroupId } = useParams<{ groupId: string }>() as {
    groupId: string;
  };
  const groupId = paramGroupId
    ? String(paramGroupId)
    : decodeURIComponent(encodedGroupId);
  const { group, fetchGroup } = useGroup({
    id: groupId,
    appId: String(appId),
    showError: false,
  });

  useEffect(() => {
    if (groupId !== undefined) {
      fetchGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  // Remove empty emails
  const emails = group?.contacts?.reduce((emails, contact) => {
    if (contact?.email) {
      emails.push(contact.email);
    }
    return emails;
  }, [] as string[]);

  const domain = getDomainFromEmails(emails);
  const avatar = group?.traits?.find(
    ({ name, value }) => name === "avatar" && value,
  )?.value;
  const logoURL = useClearbitLogo({ domain });
  const url = isValidUrl(avatar) ? avatar : logoURL;
  const isOnline = Boolean(
    group?.lastSeenAt &&
      moment.utc().diff(moment.utc(group?.lastSeenAt), "minutes") <= 5,
  );

  if (!group) {
    return (
      <CompanyAvatar
        size={props.size || "md"}
        unidentified={true}
        background="gray.100"
        border={"4px solid white"}
        {...props}
      />
    );
  }

  return (
    <CompanyAvatar
      border={"4px solid white"}
      group={group}
      size="md"
      isOnline={isOnline}
      logoURL={url}
      {...props}
    />
  );
};
