import { AND } from "core/constants/report-setup";
import {
  useGetAudienceQuery,
  useUpdateAudienceMutation,
} from "core/models/audiences";
import { IAudienceFilters, JoinOperatorValue } from "core/types/Audience";
import { useEffect, useState } from "react";

export const useEditAudience = ({
  appId,
  audienceId,
}: {
  appId: number;
  audienceId: number;
}) => {
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>({
    filterGroups: [],
    joinOperator: AND as unknown as JoinOperatorValue,
  });

  const {
    data: audience,
    isLoading,
    isFetching,
  } = useGetAudienceQuery({ appId, audienceId });
  const [updateAudience, { isLoading: isUpdating }] =
    useUpdateAudienceMutation();

  useEffect(() => {
    if (!audience) return;

    setAudienceFilters({
      filterGroups: audience.filterGroups,
      joinOperator: audience.joinOperator,
    });
  }, [audience]);

  function onUpdateAudience() {
    updateAudience({
      appId,
      audienceId,
      audienceFilters,
    });
  }

  const isDirty =
    JSON.stringify(audienceFilters) !==
    JSON.stringify({
      filterGroups: audience?.filterGroups,
      joinOperator: audience?.joinOperator,
    });

  return {
    audience,
    audienceFilters,
    setAudienceFilters,
    isLoading: isLoading || isFetching,
    isSaving: isUpdating,
    isDirty,
    onUpdateAudience,
  };
};
