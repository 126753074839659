import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { IFilterEvents } from "modules/Contacts/Contact/ContactActivity";
import { fetchOneGroupEvents } from "core/models/group/actions/fetchOneGroupEvents";
import { fetchOneGroupActivity } from "core/models/group/actions/fetchOneGroupActivity";
import { fetchOne } from "core/models/group/actions/fetchOne";
import { groupAdapter } from "core/models/group";
import { IntervalTypeNames } from "core/constants/timerange";
import { IGroup } from "../types/Group";
import { RootState, store } from "../initializers/store";

interface IUseGroupProps {
  id: string;
  appId?: string;
  intervalType?: IntervalTypeNames;
  filterEvents?: IFilterEvents;
  showError?: boolean;
  useInternalUsers?: boolean;
}

export const useGroup = ({
  id,
  intervalType = IntervalTypeNames.DAY,
  filterEvents,
  appId: paramAppId,
  showError = true,
}: IUseGroupProps) => {
  const params = useParams<{ appId: string }>() as { appId: string };

  const appId = paramAppId ? paramAppId : params.appId;

  const dispatch = useDispatch();
  const groupLoading = useSelector(
    (state: RootState) => state.group.fetch.loading[id],
  );
  const groupError = useSelector(
    (state: RootState) => state.group.fetch.error[id],
  );
  const groupSelectors = groupAdapter.getSelectors(
    (state: RootState) => state.group,
  );
  const group = useSelector((): IGroup | undefined =>
    groupSelectors.selectById(store.getState(), id),
  );

  const groupActivityLoading = useSelector(
    (state: RootState) => state.group.fetchGroupActivity.loading[id],
  );
  const groupActivityError = useSelector(
    (state: RootState) => state.group.fetchGroupActivity.error[id],
  );
  const groupActivity = useSelector(
    (state: RootState) => state.group.groupActivity.data[id],
  );

  const groupEvents = useSelector(
    (state: RootState) => state.group.groupEvents.data[id],
  );
  const groupEventsLoading = useSelector(
    (state: RootState) => state.group.fetchGroupEvents.loading[id],
  );
  const groupEventsError = useSelector(
    (state: RootState) => state.group.fetchGroupEvents.error[id],
  );

  const fetchGroup = useCallback(
    (groupId?: string, appIdParam?: string) => {
      if (
        ((appId !== "undefined" && appId !== undefined) || appIdParam) &&
        (id || groupId)
      ) {
        dispatch(
          fetchOne({
            appId: appIdParam || appId,
            id: groupId || id,
            showError,
          }),
        );
      }
    },
    [dispatch, appId, id, showError],
  );

  const fetchGroupActivity = useCallback(() => {
    if (filterEvents) {
      dispatch(
        fetchOneGroupActivity({ appId, id, intervalType, filterEvents }),
      );
    }
  }, [dispatch, appId, id, intervalType, filterEvents]);

  const fetchGroupEvents = useCallback(() => {
    dispatch(fetchOneGroupEvents({ appId, id, filterEvents }));
  }, [dispatch, appId, id, filterEvents]);

  return {
    fetchGroup,
    fetchGroupActivity,
    fetchGroupEvents,
    group,
    groupActivity,
    groupEvents,
    groupEventsLoading,
    groupEventsError,
    groupActivityError,
    groupActivityLoading,
    groupError,
    groupLoading,
  };
};
