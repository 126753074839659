import React from "react";
import { Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export default function WarningBanner({
  title,
  description,
  align = "items-center",
}: {
  title: string;
  description: string | React.ReactNode;
  align?: "items-start" | "items-center";
}) {
  return (
    <div className="inline-block max-w-lg items-center rounded-lg bg-orange-100 px-4 py-2">
      <div className={`flex w-full ${align} gap-4`}>
        <InfoOutlineIcon
          w={5}
          h={5}
          mt={align === "items-start" ? 1 : 0}
          color="yellow.500"
        />
        <div className="flex flex-col gap-1">
          <Text fontWeight="semibold" fontSize="sm" color="gray.800">
            {title}
          </Text>
          <Text fontSize="xs" color="gray.700">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
}
