import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { FRESHPAINT, RUDDERSTACK, SDK, SEGMENT } from "core/constants/apiKeys";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface IApiKey {
  name: string;
  key: string;
  id: number;
  source: ApiKeySource;
  keyType: ApiKeyKeyType;
}

export type ApiKeySource =
  | typeof SEGMENT
  | typeof FRESHPAINT
  | typeof SDK
  | typeof RUDDERSTACK;

export type ApiKeyKeyType = "write" | "secret";

export enum ApiKeyTypes {
  WRITE = "write",
  SECRET = "secret",
}

interface IGetApiKeysArgs {
  appId: number;
  source: ApiKeySource;
  keyType?: ApiKeyKeyType;
}

interface ICreateApiKeyArgs {
  appId: number;
  source: string;
  keyType?: ApiKeyKeyType;
}

interface IDeleteApiKeyArgs {
  appId: number;
  id: number;
  source: ApiKeySource;
  keyType?: ApiKeyKeyType;
}

interface IUpdateApiKeyArgs {
  appId: number;
  id: number;
  name: string;
  keyType?: ApiKeyKeyType;
}

export const apiKeysApi = createApi({
  baseQuery,
  reducerPath: "apiKeysApi",
  tagTypes: ["ApiKey"],
  endpoints: (builder) => ({
    getApiKeys: builder.query<IApiKey[], IGetApiKeysArgs>({
      query: ({ appId, source, keyType = "write" }) => ({
        url: "/api_keys",
        params: { app_id: appId, source, key_type: keyType },
      }),
      providesTags: ["ApiKey"],
      transformResponse: (
        response: SnakeCasedProperties<IApiKey[]>,
      ): IApiKey[] => humps.camelizeKeys(response) as IApiKey[],
    }),
    createApiKey: builder.mutation<IApiKey, ICreateApiKeyArgs>({
      query: ({ appId, source, keyType = "write" }) => ({
        url: "/api_keys",
        method: "POST",
        body: { app_id: appId, source, key_type: keyType },
      }),
      invalidatesTags: ["ApiKey"],
      transformResponse: (response: SnakeCasedProperties<IApiKey>) =>
        humps.camelizeKeys(response) as IApiKey,
    }),
    updateApiKey: builder.mutation<IApiKey, IUpdateApiKeyArgs>({
      query: ({ id, appId, name, keyType = "write" }) => ({
        url: `/api_keys/${id}`,
        method: "PUT",
        body: { id, app_id: appId, name, key_type: keyType },
      }),
      invalidatesTags: ["ApiKey"],
      transformResponse: (response: SnakeCasedProperties<IApiKey>) =>
        humps.camelizeKeys(response) as IApiKey,
    }),
    deleteApiKey: builder.mutation<void, IDeleteApiKeyArgs>({
      query: ({ appId, id, source, keyType = "write" }) => {
        const endpoint = source === SDK ? "sdk_keys" : "api_keys";

        return {
          url: `/${endpoint}/${id}`,
          method: "DELETE",
          params: { app_id: appId, key_type: keyType },
        };
      },
      invalidatesTags: ["ApiKey"],
    }),
  }),
});

export const {
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useUpdateApiKeyMutation,
} = apiKeysApi;
