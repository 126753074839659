import React, { useState } from "react";
import { ITemplateConfigSetupSection } from "core/types/TemplateConfig";
import { IEvent } from "core/types/Event";
import {
  EventProperty,
  useGetPropertiesQuery,
  useLazyGetPropertyValuesQuery,
} from "core/models/properties";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { QueueListIcon } from "@heroicons/react/20/solid";
import { Button, ComponentDefaultProps, Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  Command,
  CommandList,
  CommandInput,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";

const MAX_PROPERTY_BREAKDOWN_COUNT = 20;

interface IPropertySelectProps extends ComponentDefaultProps {
  event: IEvent;
  section?: ITemplateConfigSetupSection;
  onSelectProperty: (event: IEvent, property: string, value: string) => void;
  setPropertyFilterEventId: (propertyFilterEventId: string) => void;
}

export const PropertyBreakdownMenu: React.FC<IPropertySelectProps> = ({
  event,
  section,
  onSelectProperty,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const currentApp = useCurrentApp();
  const { data, isLoading } = useGetPropertiesQuery({
    appId: currentApp.id,
    eventId: event.id,
  });

  const [getPropertyValues] = useLazyGetPropertyValuesQuery();

  if (!section?.breakdown) return null;

  function onChooseProperty(property: string) {
    getPropertyValues({
      appId: currentApp.id,
      eventId: event.id,
      property,
    }).then(({ data }) => {
      if (!data) return;

      const propertyValues = [...data?.data];
      propertyValues
        ?.sort((a, b) => b.count - a.count)
        ?.slice(0, MAX_PROPERTY_BREAKDOWN_COUNT)
        ?.forEach(({ value }) => onSelectProperty(event, property, value));
    });
  }

  const hasNoProperties = data?.length === 0;

  const properties = data
    ?.map((p: EventProperty) => ({ property: p.property, count: p.count }))
    .filter(
      (property: { property: string; count: number }) =>
        property.property.toLowerCase().includes(searchValue.toLowerCase()) &&
        property.property !== "",
    );

  if (isLoading) return null;

  if (hasNoProperties) {
    return (
      <Tooltip label="This event has no properties" placement="bottom">
        <Button
          color="gray.500"
          background="gray.100"
          border="none"
          borderRadius="none"
          cursor="not-allowed"
        >
          <QueueListIcon style={{ height: "15px" }} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <PopoverButton className="flex h-full items-center px-2">
            <QueueListIcon style={{ height: "15px" }} />
          </PopoverButton>
          <PopoverPanel className="flex w-full flex-col">
            <Lifecycle onUnmount={() => setSearchValue("")} />
            <Command>
              <CommandInput
                placeholder="Search..."
                value={searchValue}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setSearchValue(e?.currentTarget?.value);
                }}
              />
              <CommandList>
                {properties && properties.length > 0 ? (
                  properties.map(
                    (
                      property: { property: string; count: number },
                      index: number,
                    ) => {
                      return (
                        <CommandOption
                          key={index}
                          value={property.property}
                          onClick={() => {
                            onChooseProperty(property.property);
                            close();
                          }}
                        >
                          {property.property}
                        </CommandOption>
                      );
                    },
                  )
                ) : (
                  <p className="text-sm">No property found</p>
                )}
              </CommandList>
            </Command>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
