import { useNavigate } from "react-router";
import { useNavigation } from "core/hooks/useNavigation";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  IMilestone,
  IMilestones,
  useContactMilestones,
} from "core/hooks/useContactMilestones";
import { Template } from "core/constants/templates";
import {
  Box,
  Button,
  Divider,
  Flex,
  SkeletonText,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";

export const Milestones: React.FC<{
  contactId: string;
}> = ({ contactId }) => {
  const currentApp = useCurrentApp();
  const appId = currentApp.id;
  const navigate = useNavigate();
  const { setIsNewReportModalOpen } = useNavigation();
  const { milestones, isLoading, error } = useContactMilestones({
    contactId: contactId,
  });

  if (isLoading) {
    return (
      <Box>
        <Flex mb={3} justifyContent="space-between" align="center">
          <Text fontWeight="medium">Activation</Text>
        </Flex>
        <SkeletonText w="100%" />
      </Box>
    );
  }

  return (
    <Box>
      <Flex mb={3} justifyContent="space-between" align="center">
        <Flex>
          <Text fontWeight="medium">Activation</Text>
        </Flex>
      </Flex>

      {!milestones || milestones?.length === 0 || error ? (
        <Box p={3} textAlign="center">
          <Text color="gray.600" fontSize="sm">
            Measure your users activation journey
          </Text>
          <Button
            onClick={() => {
              setIsNewReportModalOpen(true);
              navigate({
                search: `?${new URLSearchParams({
                  template: Template.Activation,
                }).toString()}`,
              });
            }}
            colorScheme="purple"
            variant="ghost"
            mt={3}
          >
            Create milestones
          </Button>
        </Box>
      ) : (
        <>
          {milestones.map((milestone: IMilestones, index: number) => {
            const reportName = milestone.reportName || "Unknown";
            return (
              <Flex _last={{ mb: 0 }} direction="column" key={index}>
                <Flex mb={1} align="center" justifyContent="space-between">
                  <Flex>
                    <Text noOfLines={1} fontSize="sm" fontWeight="medium">
                      {reportName}
                    </Text>
                  </Flex>
                  <Flex>
                    <Tooltip label={reportName}>
                      <Button
                        data-testid="highlight-card-cta"
                        variant="ghost"
                        colorScheme="purple"
                        rightIcon={<ArrowForwardIcon />}
                        size="xs"
                        onClick={() => {
                          // eslint-disable-next-line no-undef
                          window.analytics.track(
                            "report_opened_from_user_milestones",
                            {
                              app_id: appId,
                              contact_id: contactId,
                              report_id: milestone.reportId,
                            },
                            { context: { groupId: appId } },
                          );
                          navigate(`/a/${appId}/report/${milestone.reportId}`);
                        }}
                      >
                        View
                      </Button>
                    </Tooltip>
                  </Flex>
                </Flex>

                <Flex direction="column">
                  {milestone.milestones.map(
                    (
                      { name, completed, configured }: IMilestone,
                      i: number,
                    ) => {
                      if (!configured) return null;

                      return (
                        <Flex key={i} align="center" gridGap={2} my={1}>
                          <Flex>
                            <CheckCircleIcon
                              w={4}
                              color={completed ? "green.400" : "gray.400"}
                            />
                          </Flex>
                          <Flex>
                            <Text noOfLines={1} fontSize="sm">
                              {name}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    },
                  )}
                </Flex>
                {index !== milestones.length - 1 && <Divider my={3} />}
              </Flex>
            );
          })}
        </>
      )}
    </Box>
  );
};
