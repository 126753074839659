import { useSelectedEventLog } from "core/hooks/useSelectedEventLog";
import { EventIcon } from "core/design-system/components/Icon/Custom/EventIcon";

const EmptyState = () => (
  <div className="flex h-full w-full items-center justify-center">
    <div className="flex flex-col items-center gap-4">
      <EventIcon w={6} h={6} />
      <span className="text-sm font-semibold">
        Select a call for more details
      </span>
    </div>
  </div>
);

export const LogDetails = () => {
  const { selectedLog } = useSelectedEventLog();
  if (!selectedLog) {
    return <EmptyState />;
  }

  const { type, ...log } = selectedLog;

  return (
    <div className="flex flex-col overflow-scroll">
      <div className="flex flex-col gap-4 p-4">
        <pre className="overflow-auto rounded-lg pt-4 text-xs">
          {JSON.stringify(log, null, 2)}
        </pre>
      </div>
    </div>
  );
};
