import { DataSource } from "core/models/onboardingChecklist";
import { IApiKey } from "core/models/apiKeys";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useApiKeys } from "core/hooks/useApiKeys";
import { ComponentDefaultProps, Spinner } from "@chakra-ui/react";
import CreateApiKey from "./CreateApiKey";
import ApiKeysEmptyState from "./ApiKeysEmptyState";
import ApiKey from "./ApiKey";

interface IApiKeys extends ComponentDefaultProps {
  source: DataSource;
  showCreateApiKey?: boolean;
}

const ApiKeys: React.FC<IApiKeys> = ({ source, showCreateApiKey = true }) => {
  const { id: appId } = useCurrentApp();
  const { apiKeys, isFetching } = useApiKeys({ appId, source });

  if (isFetching && !apiKeys) return <Spinner />;

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-2">
        {apiKeys?.length === 0 && <ApiKeysEmptyState />}
        {apiKeys?.map((key: IApiKey) => (
          <div
            key={`api-key-${key.id}`}
            className="flex items-center justify-between gap-2"
          >
            <ApiKey apiKey={key} />
          </div>
        ))}
      </div>
      {showCreateApiKey && (
        <div className="mt-3 flex justify-end">
          <CreateApiKey source={source} />
        </div>
      )}
    </div>
  );
};
export default ApiKeys;
