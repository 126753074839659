import { createSlice } from "@reduxjs/toolkit";

export interface NavigationState {
  isOpen: boolean;
  newReportModalIsOpen: boolean;
}

const getPersistedState = (): Partial<NavigationState> => {
  const persistedState = localStorage.getItem("navigationState");

  return persistedState ? JSON.parse(persistedState) : {};
};

const initialState: NavigationState = {
  isOpen: true,
  newReportModalIsOpen: false,
  ...getPersistedState(),
};

export const slice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
      localStorage.setItem("navigationState", JSON.stringify(state));
    },
    setNewReportModalIsOpen: (state, action) => {
      state.newReportModalIsOpen = action.payload;
      localStorage.setItem("navigationState", JSON.stringify(state));
    },
  },
});

export const { toggle, setNewReportModalIsOpen } = slice.actions;
export const selectNavigation = (state: { navigation: NavigationState }) =>
  state.navigation;

export default slice.reducer;
