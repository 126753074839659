import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  IView,
  useGetViewTokenQuery,
  useShareViewMutation,
  useUnshareViewMutation,
} from "core/models/views";
import { GlobeAltIcon, ShareIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { PaywallPopover } from "./Paywall/Popover";
import { ClipboardButton } from "./ClipboardButton";
import { Plan } from "../types/App";
import { usePaywall } from "../hooks/usePaywall";

interface IShareable {
  shareable: IView;
  shareableType: "View";
}

export const Shareable: React.FC<IShareable> = ({
  shareable,
  shareableType,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: viewToken } = useGetViewTokenQuery({
    appId: shareable.appId,
    viewId: shareable.id,
  });
  const { plan } = usePaywall();
  const [share] = useShareViewMutation();
  const [unshare] = useUnshareViewMutation();

  const onShare = () => {
    share({ appId: shareable.appId, viewId: shareable.id });
  };

  const onUnshare = () => {
    unshare({ appId: shareable.appId, viewId: shareable.id });
  };

  const isShared =
    viewToken &&
    typeof viewToken === "object" &&
    Object.keys(viewToken).length > 0;

  return (
    <>
      <Button
        variant="ghost"
        colorScheme="gray"
        onClick={onOpen}
        leftIcon={
          isShared ? (
            <GlobeAltIcon className="h-4 w-4" />
          ) : (
            <ShareIcon className="h-4 w-4" />
          )
        }
      >
        {isShared ? "Public" : "Share"}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share {shareable.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex items-center justify-between pb-4">
              <div>
                <p className="text-sm font-medium">Enable public sharing</p>
                <p className="text-sm text-gray-600">
                  Publish and share link with anyone
                </p>
              </div>
              <Switch
                isChecked={isShared}
                isDisabled={plan !== Plan.Pro}
                colorScheme="purple"
                onChange={isShared ? onUnshare : onShare}
              />
            </div>
            <AnimatePresence>
              {isShared && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <p className="text-sm font-medium">Copy public link</p>
                  <p className="text-sm text-gray-600">
                    Anyone with the link can view this dashboard
                  </p>
                  <div className="mt-4 flex w-full items-center gap-1">
                    <p className="truncate text-sm text-gray-700 underline">{`${window.location.origin}/a/${shareable.appId}/dashboard/${shareable.id}/${viewToken.token}`}</p>
                    <ClipboardButton
                      value={`${window.location.origin}/a/${shareable.appId}/dashboard/${shareable.id}/${viewToken.token}`}
                      showLabel={false}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </ModalBody>
          <ModalFooter>
            <div className="flex w-full items-center justify-end gap-2">
              <PaywallPopover
                feature="public dashboards"
                redirect="home"
                plan={Plan.Pro}
              >
                <Button colorScheme="purple" onClick={onClose}>
                  {plan !== Plan.Pro ? "Share" : "Done"}
                </Button>
              </PaywallPopover>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
