import React from "react";
import { IReport } from "core/types/Report";
import { isUserReportLevel } from "core/modules/reports/utils";
import { useInsightCsvExport } from "core/hooks/useInsightCsvExport";
import { useContactDrilldown } from "core/hooks/useContactDrilldown";
import { INTERVAL_TYPES } from "core/constants/timerange";
import { GroupListSingleGroup } from "core/components/Group/GroupListSingleGroup";
import Error from "core/components/Error";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { UseDisclosureReturn } from "@chakra-ui/react";

interface IFeatureUsageListProps
  extends Pick<UseDisclosureReturn, "isOpen" | "onClose"> {
  report: IReport;
  intervalType: number;
  humanizedDate: string;
  usageCount: number;
  uniqueUserCount: number;
  date: string;
  hour?: string;
  unit: string;
}

export const FeatureUsageList: React.FC<IFeatureUsageListProps> = ({
  intervalType,
  humanizedDate,
  usageCount,
  uniqueUserCount,
  date,
  hour,
  unit,
  isOpen,
  onClose,
  report,
}) => {
  const isUserReport = isUserReportLevel();
  const insightId = isUserReport ? 4 : 35;

  const getInsightParams = () => {
    const baseParams = { intervalType, date };

    return hour !== "" ? { ...baseParams, hour } : baseParams;
  };

  const {
    contacts,
    isLoading,
    error,
    hasMoreContacts,
    loadMore,
    searchQuery,
    onSearch,
  } = useContactDrilldown({
    report,
    insightType: insightId,
    skip: !isOpen,
    insightParams: getInsightParams(),
  });

  const { requestCsvExport, csvExportLoading } = useInsightCsvExport({
    report,
    insightId: insightId,
    insightParams: {
      intervalType: intervalType,
      date: date,
    },
    count: uniqueUserCount,
  });

  if (error) {
    return <Error />;
  }

  const title = `This feature was used ${usageCount} times ${
    intervalType === INTERVAL_TYPES.DAY || intervalType === INTERVAL_TYPES.WEEK
      ? "on"
      : "in"
  } ${humanizedDate}`;

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={title}
              description={`Used by ${uniqueUserCount} ${unit}`}
              isLoading={isLoading}
            />
            <Drilldown.DownloadButton
              isDisabled={uniqueUserCount <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
            {unit === "users" && (
              <Drilldown.Search
                placeholder="Search your users by email address..."
                searchQuery={searchQuery}
                onSearch={onSearch}
                disabled={uniqueUserCount <= 0}
              />
            )}
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={contacts}
              itemRenderer={({ item }) => {
                return unit === "users" ? (
                  <UserListSingleContact
                    key={item.userId}
                    id={item.userId}
                    traits={item.traits}
                    description={`Used ${item?.usageCount} times`}
                  />
                ) : (
                  <GroupListSingleGroup
                    key={item.groupId}
                    id={item.groupId}
                    traits={item.traits}
                    groupType={item.groupType}
                    description={`Used ${item?.usageCount} times`}
                  />
                );
              }}
              hasMore={hasMoreContacts}
              isLoading={isLoading}
              loadNext={loadMore}
              emptyStateText={`No ${unit}`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
