import humps from "humps";
import { IAudience } from "core/types/Audience";
import { Business, CompanySize, ReferralSource } from "core/types/App";
import Http from "./http";

const WITH_CREDENTIALS = { withCredentials: true };
const WITH_CREDENTIALS_AND_CORS = { withCredentials: true, mode: "cors" };
const SHOW = 0;
const PUT = 2;

export const resourceAction = (
  actionType: number,
  resource: string,
  params: any,
  dispatch: any,
) => {
  if (actionType === SHOW) {
    return Http.get({
      path: `/${resource}/${params.id}`,
      config: { params: humps.decamelizeKeys(params), ...WITH_CREDENTIALS },
      dispatch,
    });
  }
  if (actionType === PUT) {
    return Http.put({
      path: `/${resource}`,
      body: { ...humps.decamelizeKeys(params) },
      config: { ...WITH_CREDENTIALS_AND_CORS },
      dispatch,
    });
  }
};

// app
export const fetchApp = ({
  appId,
  dispatch,
}: {
  appId: string;
  dispatch: any;
}) => resourceAction(SHOW, "apps", { id: appId }, dispatch);

export const createApp = ({
  appName,
  dispatch,
}: {
  appName: string;
  dispatch: any;
}) =>
  Http.post({
    path: `/apps`,
    body: { app: { name: appName } },
    config: WITH_CREDENTIALS_AND_CORS,
    dispatch,
  });

export const deleteApp = ({
  appId,
  dispatch,
}: {
  appId: string;
  dispatch: any;
}) =>
  Http.delete({
    path: `/apps/${appId}`,
    config: WITH_CREDENTIALS_AND_CORS,
    dispatch,
  });

export const updateApp = ({
  appId,
  name,
  timeZone,
  business,
  companySize,
  referralSource,
  groupContextEnabled,
  enforceGoogleOauth,
  autoJoinCompanyWorkspaceEnabled,
  companyDomain,
  hasRequestedAiAccess,
  aiOptedIn,
  dispatch,
}: {
  appId: number;
  name?: string;
  timeZone?: string;
  groupContextEnabled?: boolean;
  enforceGoogleOauth?: boolean;
  autoJoinCompanyWorkspaceEnabled?: boolean;
  dispatch: any;
  business?: Business;
  companySize?: CompanySize;
  referralSource?: ReferralSource;
  companyDomain?: string;
  hasRequestedAiAccess?: boolean;
  aiOptedIn?: boolean;
}) =>
  resourceAction(
    PUT,
    `apps/${appId}`,
    {
      name,
      timeZone,
      groupContextEnabled,
      enforceGoogleOauth,
      autoJoinCompanyWorkspaceEnabled,
      business,
      companySize,
      referralSource,
      companyDomain,
      hasRequestedAiAccess,
      aiOptedIn,
    },
    dispatch,
  );

export const updateLastUsedApp = ({
  appId,
  dispatch,
}: {
  appId: string;
  dispatch: any;
}) =>
  Http.post({
    path: `/apps/${appId}/last_used_app`,
    body: { appId },
    config: WITH_CREDENTIALS_AND_CORS,
    dispatch,
  });

// report
export const fetchReport = ({
  reportId,
  appId,
  level,
  dispatch,
}: {
  reportId: string;
  appId: string;
  level: string;
  dispatch: any;
}) => resourceAction(SHOW, "reports", { id: reportId, appId, level }, dispatch);

export const fetchPublicReport = ({
  reportId,
  appId,
  sharingSecretToken,
  dispatch,
}: {
  reportId: string;
  appId: string;
  sharingSecretToken: string | null;
  dispatch: any;
}) =>
  resourceAction(
    SHOW,
    "reports",
    { id: reportId, appId, sharingSecretToken },
    dispatch,
  );

export const updateReportSetup = ({
  reportId,
  appId,
  config,
  audience,
  dispatch,
}: {
  reportId: string;
  appId: string;
  config: any;
  audience: IAudience;
  dispatch: any;
}) =>
  resourceAction(
    PUT,
    `reports/${reportId}`,
    { id: reportId, appId, config, audience },
    dispatch,
  );

export const updateReportMailSetting = ({
  reportId,
  appId,
  isEnabled,
  dispatch,
}: {
  reportId: string;
  appId: string;
  isEnabled: boolean;
  dispatch: any;
}) =>
  resourceAction(
    PUT,
    `reports/${reportId}/update_report_mail_setting`,
    { id: reportId, appId, isEnabled },
    dispatch,
  );

export const updateReportTimerange = ({
  reportId,
  appId,
  audience,
  timerangeType,
  timerangeValue,
  timerangeStartTimestamp,
  timerangeEndTimestamp,
  dispatch,
}: {
  reportId: string;
  appId: string;
  audience: IAudience;
  timerangeType: string | number;
  timerangeValue: string | number;
  timerangeStartTimestamp: string;
  timerangeEndTimestamp: string;
  dispatch: any;
}) =>
  resourceAction(
    PUT,
    `reports/${reportId}`,
    {
      id: reportId,
      appId,
      audience,
      timerangeType,
      timerangeValue,
      timerangeStartTimestamp,
      timerangeEndTimestamp,
    },
    dispatch,
  );

export const updateReportType = ({
  reportId,
  appId,
  reportType,
  reportConfig,
  dispatch,
}: {
  reportId: string;
  appId: string;
  reportType: number;
  reportConfig: string;
  dispatch: any;
}) =>
  resourceAction(
    PUT,
    `reports/${reportId}`,
    {
      id: reportId,
      appId,
      reportType,
      reportConfig,
    },
    dispatch,
  );

// contact
export const fetchContact = ({
  userId,
  appId,
  dispatch,
}: {
  userId: string;
  appId: string;
  dispatch: any;
}) => resourceAction(SHOW, "contacts", { id: 1, userId, appId }, dispatch);

// user
export const logout = ({ dispatch }: { dispatch: any }) =>
  Http.delete({ path: `/logout`, config: WITH_CREDENTIALS, dispatch });
