import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BoltIcon, XMarkIcon } from "@heroicons/react/24/solid";
import useSmartTrait from "core/hooks/useSmartTrait";
import { ITraitWithConfig } from "core/types/Trait";
import { ComputedTraitEditor } from "routes/Settings/sections/ComputedTraitEditor";

export const TraitFilterCta: React.FC<{
  onClick: () => void;
  onComplete: (trait: ITraitWithConfig) => void;
}> = ({ onClick, onComplete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCreate } = useSmartTrait({
    onCreated: (trait) => {
      onClose();
      onComplete(trait);
    },
  });

  return (
    <>
      <div className="relative w-full border-t border-gray-200 bg-white pt-2">
        <Button
          w="full"
          leftIcon={<BoltIcon className="h-3 w-3" />}
          onClick={() => {
            onClick();
            onOpen();
          }}
        >
          New computed trait
        </Button>
      </div>
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <ComputedTraitEditor compact onSave={onCreate}>
            <Tooltip label="Close" hasArrow>
              <Button onClick={onClose} variant="ghost">
                <XMarkIcon className="h-4 w-4" />
              </Button>
            </Tooltip>
          </ComputedTraitEditor>
        </DrawerContent>
      </Drawer>
    </>
  );
};
