import moment from "moment";
import { PropertyList } from "modules/Contacts/EventLog/PropertyList";
import { IEventLog } from "core/types/EventLog";
import { IDENTIFY, PAGE, TRACK } from "core/constants/events";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { CalendarIcon } from "@heroicons/react/24/outline";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { DisplayName } from "./EventLog/DisplayName";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const EVENT_LOG_BG_COLORS = {
  [TRACK]: "bg-purple-200",
  [PAGE]: "bg-green-200",
  [IDENTIFY]: "bg-blue-200",
} as { [key: number]: string };

const EVENT_LOG_RING_COLORS = {
  [TRACK]: "ring-purple-400",
  [PAGE]: "ring-green-400",
  [IDENTIFY]: "ring-blue-400",
} as { [key: number]: string };

export const EVENT_LOG_DECORATION_COLORS = {
  [TRACK]: "decoration-purple-400",
  [PAGE]: "decoration-green-400",
  [IDENTIFY]: "decoration-blue-400",
} as { [key: number]: string };

const EVENT_LOG_VERB = {
  [TRACK]: "Triggered",
  [PAGE]: "Visited",
  [IDENTIFY]: "Triggered",
} as { [key: number]: string };

const groupByEventsByDays = (eventLogs: IEventLog[]) => {
  const events = eventLogs.map((e) => ({
    day: moment(e.timestamp).format("Do MMMM YYYY"),
    eventLog: e,
  }));
  return events.reduce(function (r, e) {
    r[e.day] = r[e.day] || [];
    r[e.day].push(e);
    return r;
  }, Object.create(null));
};

export default function ContactEventLogGroups({
  eventLogs,
  showUser = false,
}: {
  eventLogs: IEventLog[];
  showUser: boolean;
}) {
  const eventsByDays = groupByEventsByDays(eventLogs);

  if (!eventsByDays) return null;

  return (
    <div className="flow-root">
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <ul role="list">
        {Object.keys(eventsByDays).map((dayKey: string, index: number) => {
          return (
            <>
              <li key={`${index}`} className="pt-6 text-sm">
                <p className="sticky top-5 z-10 mt-4 w-fit rounded-md border bg-white px-2 py-1 text-gray-600 shadow-sm">
                  <div className="flex items-center gap-2">
                    <CalendarIcon className="h-5 w-5" />
                    {dayKey}
                  </div>
                </p>
                {eventsByDays[dayKey].map(
                  (
                    { day, eventLog }: { day: string; eventLog: IEventLog },
                    eventIdx: number,
                  ) => {
                    return (
                      <li key={`${eventIdx}`}>
                        <div className="relative top-5 pb-5">
                          <div
                            className={classNames(
                              eventIdx === eventsByDays[dayKey].length - 1
                                ? "-h-2"
                                : "-bottom-6",
                              "absolute -left-1 top-3 flex w-6 justify-center",
                            )}
                          >
                            <div className="w-px bg-gray-100" />
                          </div>
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={`relative left-1 top-3 flex h-2 w-2 items-center justify-center rounded-full ring-4 ring-white`}
                              >
                                <div
                                  className={classNames(
                                    EVENT_LOG_BG_COLORS[eventLog.event.type],
                                    EVENT_LOG_RING_COLORS[eventLog.event.type],
                                    `h-1.5 w-1.5 rounded-full ring-1`,
                                  )}
                                />
                              </span>
                            </div>
                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                              <div className="flex items-center gap-1 truncate text-ellipsis">
                                {showUser && (
                                  <UserListSingleContact
                                    id={eventLog.contact.id}
                                    traits={eventLog.contact.traits}
                                    p={0}
                                    hideHoverBorder
                                    hideExternalLinkIcon
                                  />
                                )}
                                <p className="truncate text-sm text-gray-600">
                                  {showUser
                                    ? EVENT_LOG_VERB[
                                        eventLog.event.type
                                      ].toLowerCase()
                                    : EVENT_LOG_VERB[eventLog.event.type]}{" "}
                                  <Popover trigger="hover" placement="right">
                                    <PopoverTrigger>
                                      <span
                                        className={classNames(
                                          EVENT_LOG_DECORATION_COLORS[
                                            eventLog.event.type
                                          ],
                                          eventLog.properties &&
                                            Object.keys(eventLog.properties)
                                              .length > 0
                                            ? "cursor-help underline decoration-dotted underline-offset-2"
                                            : "",
                                          "font-medium text-gray-900",
                                        )}
                                      >
                                        <DisplayName
                                          eventLog={eventLog}
                                          showGroup={!showUser}
                                        />
                                      </span>
                                    </PopoverTrigger>
                                    <PopoverContent
                                      color="white"
                                      bg="gray.800"
                                      borderColor="gray.900"
                                      borderRadius="lg"
                                    >
                                      <PopoverBody>
                                        <PropertyList
                                          properties={eventLog.properties}
                                        />
                                      </PopoverBody>
                                    </PopoverContent>
                                  </Popover>
                                </p>
                              </div>
                              <div className="whitespace-nowrap text-right text-xs text-gray-600">
                                <time dateTime={eventLog.timestamp}>
                                  {moment
                                    .utc(eventLog.timestamp)
                                    .format("hh:mm a")}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  },
                )}
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
}
