export enum Measure {
  ActiveUsers = "active_users",
  NewUsers = "new_users",
  Features = "features",
  Retention = "retention",
  Funnel = "funnel",
  Power = "power",
}

export enum GraphType {
  Bar = "bar",
  Area = "area",
  Line = "line",
  Table = "table",
  Scatter = "scatter",
  StackedLine = "stacked_line",
  StackedBar = "stacked_bar",
  CohortGraph = "cohort_graph",
  ContributionGraph = "contribution_graph",
  Trend = "trend",
  Ratio = "ratio",
}

export enum Summary {
  Total = "total",
  Average = "average",
}

export enum Size {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum Interval {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export enum State {
  Unpersisted = 0,
  Persisted = 1,
}
