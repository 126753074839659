import { useEffect, useState } from "react";
import moment from "moment";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";
import { IContact } from "core/types/Contact";
import { FavouriteObjectType } from "core/models/favourites";
import useFlag from "core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useLazyClearbitLogo } from "core/hooks/useClearbitLogo";
import { useAppObjects } from "core/hooks/useAppObjects";
import { getVisibleName } from "core/helpers/contacts/getVisibleName";
import { getContactEmail } from "core/helpers/contactEmail";
import { FAVOURITES, FIRST_TOUCHPOINT } from "core/constants/features";
import ContactAvatar from "core/components/Avatars/ContactAvatar";
import {
  ArrowRightEndOnRectangleIcon,
  AtSymbolIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import Editor from "./Editor";
import { DeleteContactModal } from "./DeleteContactModal";
import { CrmHeader } from "./CrmHeader";
import { AudienceBadgeContainer } from "./AudienceBadgeContainer";

interface IContactHeader extends StyleProps {
  contactId: string;
  contact: IContact;
  contactError?: string;
}

export const Header: React.FC<IContactHeader> = ({
  contact,
  contactId,
  contactError,
}) => {
  const { id: appId } = useCurrentApp();
  const { userAppObject } = useAppObjects();
  const hasFavouritesEnabled = useFlag(FAVOURITES);
  const [editModal, showEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const email = getContactEmail(contact);
  const hasFirstTouchpointEnabled = useFlag(FIRST_TOUCHPOINT);

  const visibleName = getVisibleName({ contactError, contact });
  const isOnline = Boolean(
    contact.lastSeenAt &&
      moment.utc().diff(moment.utc(contact.lastSeenAt), "minutes") <= 5,
  );
  const { getClearbitLogo, data: logoURL } = useLazyClearbitLogo();
  useEffect(() => {
    if (contact?.firstTouchpoint) {
      getClearbitLogo({ domain: contact.firstTouchpoint, size: 100 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.firstTouchpoint]);

  if (contact) {
    document.title = `June - ${visibleName}`;
  }

  return (
    <div>
      <div className="mb-4 flex flex-row justify-between">
        <div className="flex w-full flex-1 items-center gap-4">
          <div>
            <ContactAvatar
              bg="gray.100"
              border="4px solid white"
              shadow="sm"
              contact={contact}
              isOnline={isOnline}
              margin={"auto"}
              size="lg"
            />
          </div>
          {contact?.firstSeenAt && (
            <div>
              <p className="max-w-[220px] truncate font-medium">
                {visibleName}
              </p>
              <Tooltip
                label={`at ${moment.utc(contact?.firstSeenAt).format("hh:mm a")}`}
              >
                <p className="text-sm text-gray-600">
                  Joined{" "}
                  {moment.utc(contact?.firstSeenAt).format("DD MMM, YYYY")}
                </p>
              </Tooltip>
            </div>
          )}
        </div>
        <Menu>
          <MenuButton
            as={Button}
            aria-label="Edit user menu"
            variant="ghost"
            size="xs"
            shadow="none"
            boxShadow="none"
            mr={-2}
          >
            <EllipsisVerticalIcon className="h-4 w-4" />
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<PencilIcon className="h-4" />}
              onClick={() => showEditModal(!editModal)}
            >
              <Text fontSize="sm">Edit user</Text>
            </MenuItem>
            <MenuItem
              icon={<TrashIcon className="h-4" />}
              onClick={() => setShowDeleteModal(!showDeleteModal)}
            >
              <Text fontSize="sm">Delete user</Text>
            </MenuItem>
            {hasFavouritesEnabled && (
              <FavouritesMenuItem
                objectType={FavouriteObjectType.User}
                appId={Number(appId)}
                objectId={contactId}
              />
            )}
          </MenuList>
        </Menu>
      </div>
      <div className="flex flex-col gap-2">
        {contactId && (
          <div className="flex flex-1 items-center space-x-2">
            <div className="flex-shrink-0">
              <UserCircleIcon className="h-[17px] text-gray-600" />
            </div>
            <Tooltip label={contactId.toString()} hasArrow>
              <p className="truncate text-sm">{contactId.toString()}</p>
            </Tooltip>
          </div>
        )}
        {email && (
          <div className="flex flex-1 items-center space-x-2">
            <div className="flex-shrink-0">
              <AtSymbolIcon className="h-[17px] text-gray-600" />
            </div>
            <Tooltip label={email} hasArrow>
              <p className="truncate text-sm">{email}</p>
            </Tooltip>
          </div>
        )}
        {contact?.lastSeenAt && (
          <div className="flex flex-1 items-center space-x-2">
            <div className="flex-shrink-0">
              <ClockIcon className="h-[17px] text-gray-600" />
            </div>
            <p className="text-sm">
              Last seen{" "}
              {moment
                .utc(contact?.lastSeenAt)
                .format("DD MMM, YYYY [at] hh:mm a")}
            </p>
          </div>
        )}
        {hasFirstTouchpointEnabled && contact?.firstTouchpoint && (
          <div className="flex flex-1 items-center space-x-2">
            <div className="flex-shrink-0">
              <ArrowRightEndOnRectangleIcon className="h-[17px] text-gray-600" />
            </div>
            <div className="flex items-center space-x-2">
              <Tooltip label="First touchpoint" hasArrow>
                <div className="flex items-center gap-2">
                  {logoURL && (
                    <div className="h-3 w-3 overflow-hidden rounded-full">
                      <img
                        src={logoURL}
                        alt="Logo"
                        className="h-full w-full object-cover"
                      />
                    </div>
                  )}
                  <p className="text-sm">{contact?.firstTouchpoint}</p>
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        <AudienceBadgeContainer contactId={contactId} />
        <CrmHeader entity={contact} appObject={userAppObject} />
      </div>
      <Editor
        appId={appId}
        contact={contact}
        contactId={contactId}
        mutate={() => {}}
        isOpen={editModal}
        toggle={() => showEditModal(false)}
      />
      <DeleteContactModal
        appId={appId}
        contact={contact}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default Header;
