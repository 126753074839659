import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { UnidentifiedUserDescription } from "modules/Contacts/Contact/UnidentifiedUserDescription";
import { Milestones } from "modules/Contacts/Contact/Milestones";
import Header from "modules/Contacts/Contact/Header";
import { EnrichmentCard } from "modules/Contacts/Contact/EnrichmentCard";
import { ContactPages } from "modules/Contacts/Contact/ContactPages";
import { ContactMostTriggeredEvents } from "modules/Contacts/Contact/ContactMostTriggeredEvents";
import { ContactAISummary } from "modules/Contacts/Contact/ContactAISummary";
import { ContactActivity } from "modules/Contacts/Contact/ContactActivity";
import { Companies } from "modules/Contacts/Contact/Companies";
import { ITrait } from "core/types/Trait";
import { useRecentlyViewedPeople } from "core/hooks/useRecentlyViewedPeople";
import useFlag from "core/hooks/useFlag";
import { useContact } from "core/hooks/useContact";
import { useAppObjects } from "core/hooks/useAppObjects";
import { getVisibleName } from "core/helpers/contacts/getVisibleName";
import { getContactEmail } from "core/helpers/contactEmail";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from "core/design-system/components/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { TRAIT_LEVEL } from "core/constants/traits";
import { AI_SUMMARY } from "core/constants/features";
import WarningBanner from "core/components/WarningBanner";
import TraitsContainer from "core/components/Traits/Container";
import { PageContainer } from "core/components/PageContainer";
import { Box, Divider, Skeleton, TabPanels } from "@chakra-ui/react";
import {
  BuildingOfficeIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { useNavigation } from "core/hooks/useNavigation";

function Contact() {
  const { appId, contactId: encodedContactId } = useParams<{
    appId: string;
    contactId: string;
  }>() as {
    appId: string;
    contactId: string;
  };
  const id = decodeURIComponent(encodedContactId);
  const { navigation, toggleSidebar } = useNavigation();

  const {
    contact,
    fetchContact,
    isLoadingContact,
    contactError,
    contactGroups,
    contactCompanies,
    isLoadingContactGroups,
    isLoadingContactCompanies,
    fetchContactGroups,
    fetchContactCompanies,
  } = useContact({ appId, id });

  const { save } = useRecentlyViewedPeople();

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
  }, []);

  useEffect(() => {
    fetchContact();
    fetchContactGroups();
    fetchContactCompanies();
  }, [fetchContact, fetchContactGroups, fetchContactCompanies]);

  const hasGroups = Boolean(contactGroups && contactGroups.groups.length > 0);
  const hasCompanies = Boolean(
    contactCompanies && contactCompanies?.companies?.length > 0,
  );
  const { userAppObject, groupAppObject, companyAppObject } = useAppObjects();
  const hasAISummary = useFlag(AI_SUMMARY);
  contact && userAppObject && save(appId, contact, userAppObject.objectType);

  const pages = [
    {
      name: userAppObject?.pluralName || "Users",
      path: `/a/${appId}/people`,
      current: false,
    },
    {
      name: getVisibleName({ contactError, contact }) ?? "Unknown",
      path: `/a/${appId}/contact/${encodedContactId}`,
      current: true,
    },
  ];

  return (
    <PageContainer
      className="h-full bg-gray-50"
      bgColor="bg-gray-50"
      minW="2xl"
      maxW="full"
      shadow={false}
      h="full"
      py="4px"
      pr="0"
      pl="0"
    >
      <div className="min-w-screen-lg mx-auto flex h-full flex-row">
        <Box minW="340px" w="25%" overflowY="auto" pr="16px">
          <div className="flex max-h-full flex-col gap-5">
            {isLoadingContact ? (
              <div className="px-4">
                <Skeleton borderRadius="lg" height="264px" w="100%" />
              </div>
            ) : (
              <div className="mx-4 border-b border-gray-200 bg-gray-50 pb-4 opacity-100">
                {contactError && (
                  <div className="flex py-2">
                    <WarningBanner
                      title={`This user has not been identified`}
                      description={
                        <UnidentifiedUserDescription contactId={id} />
                      }
                      align="items-start"
                    />
                  </div>
                )}
                <div className="mb-6 mt-2">
                  <Breadcrumbs pages={pages} />
                </div>
                {!isLoadingContact && contact && contact.id && (
                  <Header
                    contactId={id}
                    contact={contact}
                    contactError={contactError}
                  />
                )}
              </div>
            )}
            <div className="flex flex-1 flex-col gap-4 overflow-y-auto">
              {id && hasAISummary && (
                <div className="px-4">
                  <ContactAISummary contactId={id} />
                </div>
              )}
              {isLoadingContact ? (
                <div className="px-4">
                  <Divider />
                  <Skeleton borderRadius="lg" height="300px" w="100%" />
                </div>
              ) : (
                <div className="px-4">
                  <TraitsContainer
                    id={id}
                    traitLevel={TRAIT_LEVEL.USER}
                    traitKeyValues={contact?.traits as ITrait[]}
                    showError={Boolean(contactError)}
                  />
                </div>
              )}
              {isLoadingContact ? (
                <div className="px-4">
                  <Divider mb="4" />
                  <Skeleton borderRadius="lg" height="300px" w="100%" />
                </div>
              ) : (
                <div className="px-4">
                  <Divider mb="4" />
                  {contact && (
                    <EnrichmentCard email={getContactEmail(contact)} />
                  )}
                </div>
              )}
              {companyAppObject?.isEnabled && (
                <div className="px-4">
                  {isLoadingContactCompanies ? (
                    <Skeleton borderRadius="lg" height="200px" width="100%" />
                  ) : (
                    <>
                      {hasCompanies && (
                        <div>
                          <Divider mb="4" />
                          <Companies
                            groups={contactCompanies.companies}
                            name={companyAppObject?.pluralName || "Companies"}
                            slug={companyAppObject?.slug}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {isLoadingContactGroups ? (
                <div className="px-4">
                  <Skeleton borderRadius="lg" height="200px" width="100%" />
                </div>
              ) : (
                <div className="px-4">
                  {hasGroups && groupAppObject?.isEnabled && (
                    <div>
                      <Divider mb="4" />
                      <Companies
                        groups={contactGroups.groups}
                        name={groupAppObject?.pluralName || "Groups"}
                        slug={groupAppObject?.slug}
                      />
                    </div>
                  )}
                </div>
              )}
              {contact && (
                <div className="px-4 pb-8">
                  <Divider mb="4" />
                  <Milestones contactId={contact.id} />
                </div>
              )}
            </div>
          </div>
        </Box>
        <div className="flex max-w-full flex-1 overflow-y-auto rounded-lg border border-gray-100 bg-white px-4 pb-16 pt-8 shadow-sm">
          <div className="mx-auto">
            <StyledTabs isLazy defaultIndex={0} w="980px" maxW="full">
              <StyledTabList boxShadow="sm" w="100%">
                <StyledTab>
                  <div className="flex items-center gap-2">
                    <BuildingOfficeIcon className="h-4 text-gray-600" />
                    Overview
                  </div>
                </StyledTab>
                <StyledTab>
                  <div className="flex items-center gap-2">
                    <ListBulletIcon className="h-4 text-gray-600" />
                    Feed
                  </div>
                </StyledTab>
              </StyledTabList>
              <TabPanels maxW="full">
                <StyledTabPanel>
                  <ContactActivity />
                  <ContactMostTriggeredEvents />
                  <ContactPages mt={6} />
                </StyledTabPanel>
                <StyledTabPanel>
                  {contact && (
                    <ContactEventLog
                      appId={Number(appId)}
                      contactId={contact.id}
                    />
                  )}
                </StyledTabPanel>
              </TabPanels>
            </StyledTabs>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default Contact;
