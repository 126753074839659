import { cx } from "helpers/cx";
import { Size } from "core/types/ViewInsight";
import { IBarItem } from "core/types/BarChart";
import { thousandsToK } from "core/helpers/thousands";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { FunnelTooltip } from "core/design-system/charts/FunnelTooltip";
import { Box, HStack, Progress, Text, Tooltip } from "@chakra-ui/react";

export interface IHomeFunnelStep {
  name: string;
  convertedCount: number;
  percentage: number;
  isPlaceholder?: boolean;
}

export const FunnelStep: React.FC<{
  item: IBarItem;
  color?: IColorPalette;
  size?: Size;
}> = ({ item, color, size = Size.Small }) => {
  const convertedCount = thousandsToK(item.conversion.count);
  const percentage = `${Math.round(item.conversion.percentage || 0)}%`;

  return (
    <>
      <Box data-testid="milestone" w="100%">
        <Tooltip
          label={<FunnelTooltip item={item} index={item.index} />}
          hasArrow
          placement="bottom"
          isDisabled={item.conversion.hideTooltip}
        >
          <HStack
            align="center"
            spacing={0.5}
            direction="row"
            color="gray.600"
            _hover={{ color: colors.gray[800] }}
            py={1}
            borderRadius="lg"
            w="full"
            pl={6}
          >
            <div className="flex w-full items-center justify-between">
              <div className={`flex w-${size === Size.Small ? "1/2" : "full"}`}>
                <Text
                  data-testid={`milestone-name-${item.label}`}
                  as="div"
                  ml={0}
                  fontSize="sm"
                  w="full"
                  flexGrow="1"
                  maxW={size === Size.Small ? "160px" : "250px"}
                  noOfLines={1}
                  color="gray.600"
                >
                  {item.label}
                </Text>
              </div>
              <div
                className={cx(
                  "flex",
                  size === Size.Small ? "w-[30%]" : "w-full",
                )}
              >
                <Progress
                  borderRadius="lg"
                  size="sm"
                  w={"full"}
                  colorScheme={color?.scheme || "purple"}
                  value={Number(item.conversion.percentage)}
                  data-testid={`milestone-convertedCount-${convertedCount}`}
                />
              </div>
              <div className="flex w-1/5 justify-end">
                <Text
                  justifySelf="flex-end"
                  fontWeight="semibold"
                  color={color?.[500] || "purple.500"}
                  textAlign="right"
                  fontSize="xs"
                  w="40px"
                  data-testid={`milestone-percentage-${percentage}`}
                >
                  {percentage}
                </Text>
              </div>
            </div>
          </HStack>
        </Tooltip>
      </Box>
    </>
  );
};
