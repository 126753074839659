import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

interface Page {
  name: string;
  path: string;
  current: boolean;
}

interface BreadcrumbProps {
  pages: Page[];
}

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ pages }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              {index > 0 && (
                <ChevronRightIcon
                  className="mx-2 size-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <Link
                to={page.path}
                className="line-clamp-1 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
